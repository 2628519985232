import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs, { Dayjs } from "dayjs";
import { format } from "date-fns";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    IconButton,
    Divider,
    FormControlLabel,
    Link,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { config, styles } from "../../style";

import { describe } from "../../services/salesforce";
import "./style.css";

import { getCanadaStates, getUSStates } from "./utils";
import GoogleAutocomplete from "../../components/GoogleAutocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const CalendarEntry = forwardRef((props, ref) => {
    const { accountId, opportunityId, speakerId, record, opportunity, accountName } = props;
    const [initialLoading, setInitialLoading] = useState(false);
    const [name, setName] = useState("");
    const [venueName, setVenueName] = useState(opportunity?.venueName ? opportunity?.venueName : "");
    const [city, setCity] = useState(opportunity?.venueCity);
    const [state, setState] = useState(opportunity?.venueState);
    const [country, setCountry] = useState(
        opportunity?.venueCountryGlobal ? opportunity?.venueCountryGlobal : "United States"
    );
    const [note, setNote] = useState("");
    const [eventType, setEventType] = useState(opportunity?.venueName ? "In-Person" : "");

    const [countryPicklist, setCountryPicklist] = useState([]);
    const [endDateTimePicklist, setEndDateTimePicklist] = useState([]);
    const [startDateTimePicklist, setStartDateTimePicklist] = useState([]);
    const formatTimeWithZone = (dateToFormat) => {
        // console.log('TIME ZONE')
        // console.log(dateToFormat)
        let num = new Date().getTimezoneOffset();

        if (num < 0) {
            num = Math.abs(num);
            const hours = String(Math.floor(num / 60)).padStart(2, "0");
            const minutes = String(Math.floor(num % 60)).padStart(2, "0");
            let timeZone = `${hours}:${minutes}`;
            return format(new Date(dateToFormat + "T05:00:00+" + timeZone), "yyyy-MM-dd");
        }
        const hours = String(Math.floor(num / 60)).padStart(2, "0");
        const minutes = String(Math.floor(num % 60)).padStart(2, "0");
        let timeZone = `${hours}:${minutes}`;
        // console.log(dateToFormat + 'T05:00:00-' + timeZone)
        return format(new Date(dateToFormat + "T05:00:00-" + timeZone), "yyyy-MM-dd");
    };
    const newCalenderEntry = {
        startDate: formatTimeWithZone(opportunity?.startPlayDate),
        endDate: formatTimeWithZone(opportunity?.endPlayDate),
        endTime: opportunity?.endPlayDateTime,
        startTime: opportunity?.startPlayDateTime,
    };

    const [calenderEntries, setCalenderEntries] = useState([newCalenderEntry]);

    useEffect(() => {
        setInitialLoading(true);
        getCalendarEntryDescribe();
        setName(record?.speakerName + " - AVAILABILITY REQUEST - " + accountName);
    }, []);

    const getDateValue = (value) => {
        if (typeof value === "object") {
            let formattedDate = dayjs(value.$d).format("YYYY-MM-DD");
            return formatTimeWithZone(formattedDate);
        }
        return formatTimeWithZone(value);
    };

    useImperativeHandle(ref, () => ({
        getData() {
            let records = [];
            for (let item of calenderEntries) {
                if (
                    name &&
                    (item.flexibleDates || (item.endDate && item.startDate)) &&
                    (item.flexibleHours || (item.endTime && item.startTime)) &&
                    eventType &&
                    (eventType === "Virtual" ||
                        (city &&
                            venueName &&
                            (!(country === "United States" || country === "Canada") || state) &&
                            country))
                ) {
                    records.push({
                        name: name,
                        endDate: item.endDate ? getDateValue(item.endDate) : "",
                        endDateTime: item.endTime,
                        startDate: item.startDate ? getDateValue(item.startDate) : "",
                        startDateTime: item.startTime,
                        flexibleDates: item.flexibleDates,
                        flexibleHours: item.flexibleHours,
                        locationCity: city,
                        locationState: state,
                        locationCountry: country,
                        note: note,
                        eventType: eventType,
                        venue: venueName,
                    });
                } else {
                    const detailEvent = new CustomEvent("openToster", {
                        detail: { variant: "error", message: "All fields are required, please fill the details" },
                    });
                    document.dispatchEvent(detailEvent);
                    return "";
                }
            }
            return records;
        },

        //getData() {
        // if (name && endDate && startDate && endTime && startTime && eventType && (eventType === 'Virtual' || (city && state && country))  && note) {
        //     return {
        //         name: name,
        //         endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        //         endDateTime: endTime,
        //         startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        //         startDateTime: startTime,
        //         locationCity: city,
        //         locationState: state,
        //         locationCountry: country,
        //         note: note
        //     }
        // } else {
        //     const detailEvent = new CustomEvent('openToster', { detail: { variant: 'error', message: 'All fields are required, please fill the details' } })
        //     document.dispatchEvent(detailEvent)
        //     return ''
        // }
        //}
    }));

    const handleChange = (rowIndex, field, value) => {
        setCalenderEntries(
            calenderEntries.map((item, index) => {
                if (index === rowIndex) {
                    item[field] = value;
                }

                return item;
            })
        );
    };

    const getCalendarEntryDescribe = async () => {
        const result = await describe("calendarEntry", { cacheable: true });
        if (result.isSuccess) {
            console.log(result);
            for (let field of result?.data?.fields) {
                if (field.name === "Location_Country_Global__c") {
                    setCountryPicklist(
                        field.picklistValues.filter((item) => item.label !== "Virtual / Remote Broadcast")
                    );
                } else if (field.name === "End_Date_Time__c") {
                    setEndDateTimePicklist(field.picklistValues);
                } else if (field.name === "Start_Date_Time__c") {
                    setStartDateTimePicklist(field.picklistValues);
                }
            }
        }
        setInitialLoading(false);
    };
    const addNew = () => {
        setCalenderEntries([...calenderEntries, newCalenderEntry]);
    };

    const removeItem = (rowIndex) => {
        if (calenderEntries.length > 1) {
            setCalenderEntries(calenderEntries.filter((item, index) => index !== rowIndex));
        }
    };

    const handleVenueChange = (value) => {
        setVenueName(value.label?.substring(0, 254));
        setCountry(value.country);
        setCity(value.city);
        setState(value.stateShort);
    };

    const openLocation = async (locationName) => {
        const url = "https://www.google.com/maps?q=" + locationName;
        window.open(url, "_blank");
    };

    return (
        <>
            <Box sx={{ mb: "1rem" }}>
                Please enter all the event dates you'd like us to check for the speaker's availability.
            </Box>
            <Grid container alignItems="center" sx={{ display: "flex" }} justifyContent="space-between">
                <Grid item>
                    <b>Event details</b>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => addNew()}
                        sx={{ ...styles.outlinedButton }}
                        startIcon={<AddIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                        variant="outlined">
                        Add availability check
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Grid className="table-header" alignItems="center" container spacing={1}>
                    <Grid lg={2} item>
                        Start date
                    </Grid>
                    <Grid lg={2} item>
                        End date
                    </Grid>
                    <Grid lg={2} item>
                        Start time
                    </Grid>
                    <Grid lg={2} item>
                        End time
                    </Grid>
                    <Grid lg={2} item>
                        Flexible dates
                    </Grid>
                    <Grid lg={1} item>
                        Flexible/TBD Hours
                    </Grid>
                    <Grid lg={1} item></Grid>
                </Grid>
            </Box>

            {calenderEntries.map((item, index) => (
                <Box>
                    <Box sx={{ display: { xs: "none", lg: "block" } }}>
                        <Grid container spacing={1} alignItems="center" sx={{ mt: "10px" }}>
                            <Grid lg={2} item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label=""
                                        value={item.startDate}
                                        onChange={(newValue) => handleChange(index, "startDate", newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid lg={2} item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label=""
                                        value={item.endDate}
                                        onChange={(newValue) => handleChange(index, "endDate", newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid lg={2} item>
                                <FormControl fullWidth>
                                    <InputLabel id="start-time-label"></InputLabel>
                                    <Select
                                        labelId="start-time-label"
                                        id="start-time-select"
                                        label=""
                                        value={item.startTime}
                                        onChange={(event) => handleChange(index, "startTime", event.target.value)}>
                                        <MenuItem disabled value="">
                                            <em>Select Value</em>
                                        </MenuItem>
                                        {startDateTimePicklist.map((item) => (
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={2} item>
                                <FormControl fullWidth>
                                    <InputLabel id="end-time-label"></InputLabel>
                                    <Select
                                        labelId="end-time-label"
                                        id="end-time-select"
                                        label=""
                                        placeholder="Select time"
                                        value={item.endTime}
                                        onChange={(event) => handleChange(index, "endTime", event.target.value)}>
                                        <MenuItem disabled value="">
                                            <em>Select Value</em>
                                        </MenuItem>
                                        {endDateTimePicklist.map((item) => (
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={2} item>
                                <Checkbox
                                    onChange={(event) => handleChange(index, "flexibleDates", event.target.checked)}
                                />
                            </Grid>
                            <Grid lg={1} item>
                                <Checkbox
                                    onChange={(event) => handleChange(index, "flexibleHours", event.target.checked)}
                                />
                            </Grid>
                            <Grid lg={1} item>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => removeItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: { xs: "block", lg: "none" } }}>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            sx={{
                                mt: "10px",
                                width: "100%",
                                p: "10px",
                                border: "1px solid #e7e8f0",
                                borderRadius: "8px",
                            }}>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <LocalizationProvider sx={{ width: "100%" }} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={item.startDate}
                                        onChange={(newValue) => handleChange(index, "startDate", newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <LocalizationProvider sx={{ width: "100%" }} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={item.endDate}
                                        onChange={(newValue) => handleChange(index, "endDate", newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <FormControl fullWidth sx={{ width: "100%" }}>
                                    <InputLabel id="start-time-label">Start Time</InputLabel>
                                    <Select
                                        labelId="start-time-label"
                                        id="start-time-select"
                                        label="Start Time"
                                        value={item.startTime}
                                        onChange={(event) => handleChange(index, "startTime", event.target.value)}>
                                        <MenuItem disabled value="">
                                            <em>Select Value</em>
                                        </MenuItem>
                                        {startDateTimePicklist.map((item) => (
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <FormControl fullWidth sx={{ width: "100%" }}>
                                    <InputLabel id="end-time-label">End Time</InputLabel>
                                    <Select
                                        labelId="end-time-label"
                                        id="end-time-select"
                                        label="End Time"
                                        placeholder="Select time"
                                        value={item.endTime}
                                        onChange={(event) => handleChange(index, "endTime", event.target.value)}>
                                        <MenuItem disabled value="">
                                            <em>Select Value</em>
                                        </MenuItem>
                                        {endDateTimePicklist.map((item) => (
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            lable="Flexible Dates"
                                            onChange={(event) =>
                                                handleChange(index, "flexibleDates", event.target.checked)
                                            }
                                        />
                                    }
                                    label="Flexible Dates"
                                />
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            lable="Flexible/TBD Hours"
                                            onChange={(event) =>
                                                handleChange(index, "flexibleHours", event.target.checked)
                                            }
                                        />
                                    }
                                    label="Flexible/TBD Hours"
                                />
                            </Grid>
                            <Grid lg={12} item sx={{ width: "100%" }}>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => removeItem(index)}>
                                    <DeleteIcon />
                                    <span style={{ fontSize: "12px" }}>Delete</span>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            ))}
            <Divider sx={{ mt: "15px" }}></Divider>
            <Grid sx={{ mt: "10px" }} container spacing={1}>
                <Grid xs={12} item>
                    <FormControl fullWidth>
                        <InputLabel id="event-type-label">Event type</InputLabel>
                        <Select
                            labelId="event-type-label"
                            id="event-type-select"
                            label="Event type"
                            placeholder="Select event type"
                            value={eventType}
                            onChange={(event) => setEventType(event.target.value)}>
                            <MenuItem value="In-Person">In-Person</MenuItem>
                            <MenuItem value="Virtual">Virtual</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {eventType === "In-Person" && (
                <Box>
                    <Box sx={{ mt: "14px", mb: "14px" }}>
                        <FormControl fullWidth>
                            <GoogleAutocomplete
                                label="Venue Name"
                                value={venueName}
                                onChangeValue={(value) => handleVenueChange(value)}></GoogleAutocomplete>
                        </FormControl>
                        {venueName && (
                            <FormControl
                                fullWidth
                                sx={{ display: "flex", flexFlow: "row", marginTop: "14px", marginBottom: "20px" }}>
                                <LocationOnIcon sx={{ color: "#b8b8b8", mr: ".4rem" }}></LocationOnIcon>
                                <b>Venue:</b>{" "}
                                <Link
                                    onClick={() => openLocation(venueName)}
                                    title="Open location in map"
                                    sx={{ color: "#f56856", ml: ".7rem" }}
                                    href="#">
                                    {venueName}
                                </Link>
                            </FormControl>
                        )}
                    </Box>
                    <Box sx={{ mt: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="country-label">Venue Country</InputLabel>
                            <Select
                                disabled={eventType === "Virtual"}
                                labelId="country-label"
                                id="country-select"
                                label="Venue Country"
                                InputLabelProps={{ shrink: country ? true : false }}
                                value={country}
                                onChange={(event) => setCountry(event.target.value)}>
                                {countryPicklist.map((item) => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Grid sx={{ mt: "10px" }} container spacing={1}>
                        <Grid xs={6} item>
                            <TextField
                                disabled={eventType === "Virtual"}
                                onChange={(event) => setCity(event.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: city ? true : false }}
                                value={city}
                                label="Venue City"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid xs={6} item>
                            {country === "United States" || country === "Canada" ? (
                                <FormControl fullWidth InputLabelProps={{ shrink: state ? true : false }}>
                                    <InputLabel shrink={state ? true : false} id="state-label">
                                        Venue State
                                    </InputLabel>
                                    <Select
                                        disabled={eventType === "Virtual"}
                                        labelId="state-label"
                                        id="state-select"
                                        label="Venue State"
                                        value={state}
                                        onChange={(event) => setState(event.target.value)}>
                                        {country === "United States" &&
                                            getUSStates().map((item) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        {country === "Canada" &&
                                            getCanadaStates().map((item) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField
                                    disabled={eventType === "Virtual"}
                                    onChange={(event) => setState(event.target.value)}
                                    fullWidth
                                    InputLabelProps={{ shrink: state ? true : false }}
                                    value={state}
                                    label="Venue State"
                                    variant="outlined"
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box sx={{ mt: "10px" }}>
                <TextField
                    onChange={(event) => setNote(event.target.value)}
                    fullWidth
                    value={note}
                    label="Please share your thoughts on speech format, length, audience, and any pertinent details or information the speaker should know about your event."
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRows={5}
                />
            </Box>
        </>
    );
});
