import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Card,
    CardContent,
    Paper
  } from '@mui/material';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';


const type = 'event'


export const skeleton = (
    <div>
        <Box>
            <Paper sx={{ p: '20px', display: 'flex', flexDirection: 'column' }} elevation={3}>
                <Box sx={{ fontSize: '12px', color: 'gray' }}>
                    Event
                </Box>
                <Box sx={{ textTransform: 'uppercase', fontWeight: '800', fontSize: '21px', mt: '10px' }}>
                    <Skeleton variant="rounded" width={300} height={30} />
                </Box>
            </Paper>
        </Box>
            <Box sx={{ marginTop: '1rem', mb: '20px' }}>
                <Paper sx={{ p: '20px' }} elevation={3}>
                    <Box sx={{ mb: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box>
                            <Skeleton variant="rounded" width={300} height={30} />
                        </Box>
                    </Box>
                    <Grid container spacing={3} alignItems="stretch">
                        {Array.from(Array(10).keys()).map(item => (
                            <Grid item key={item} lg={4} md={4} xs={12}>
                                <Card sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', border: '1px solid #E9E9E9', minHeight: '200px' }}>
                                    <CardContent sx={{ p: 0, paddingBottom: '0px !important' }}>
                                        <Box sx={{ p: '10px', background: styles.card.cardHeaderColor, display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}>

                                            {type === 'proposal' && <Box sx={{ display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}><HistoryEduIcon fontSize='small' sx={{ mr: '10px' }}></HistoryEduIcon> Proposal</Box>}
                                            {type === 'event' && <Box sx={{ display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}><LocalActivityIcon fontSize='small' sx={{ mr: '10px' }}></LocalActivityIcon> Event</Box>}
                                        </Box>
                                        <Box sx={{ p: '10px' }}>

                                            <Skeleton variant="rounded" width={200} height={20} />
                                            <Grid sx={{ my: '5px', pl: '0px' }} container wrap="wrap" spacing={0} justifyContent="start" direction="row">
                                                <Grid item>
                                                    <Skeleton variant="rounded" width={100} height={10} />
                                                </Grid>
                                                <Grid sx={{ml: '1rem'}} item>
                                                    <Skeleton variant="rounded" width={100} height={10} />
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{pl: '0px'}}>
                                                <Skeleton variant="rounded" width={100} height={25} />
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Box>
    </div>
)

