import { createSlice } from "@reduxjs/toolkit";
import { query, describe } from "../../services/salesforce";

export const calendarSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "calendar",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = "succeeded";
            state.error = null;
        },
        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
});

export default calendarSlice.reducer;

// Export all the reducers actions here
export const { initialize, error } = calendarSlice.actions;

// Public Selectors
export const selectCalendarData = (state) => state.calendar.data;
export const selectCalendarStatus = (state) => state.calendar.status;
export const selectCalendarError = (state) => state.calendar.error;

export const initializeAsync = (params) => async (dispatch) => {
    // dispatch(initialLoading(true))
    const userDetails = await params.getUserDetails();
    const result = await query("calendarEntry", "getClientEvents", { accountId: userDetails?.contact?.accountId });
    if (result.isSuccess) {
        console.log("Calendar Initialize Async", result);
        dispatch(initialize({ records: result.data, userDetails: userDetails }));
    } else {
        dispatch(error(result.errorMessage));
    }
};
