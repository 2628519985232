import { createSlice } from '@reduxjs/toolkit';
import {query, describe} from '../../services/salesforce';
import {get} from '../../api';

export const speakerSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'speaker',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data[action.payload.key] = [...action.payload.data ];
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
    },
})

export default speakerSlice.reducer

// Export all the reducers actions here
export const { initialize, error, loading, initialLoading } = speakerSlice.actions;


// Public Selectors
export const selectSpeakerData = (state) => state.speaker.data;
export const selectSpeakerStatus = (state) => state.speaker.status;
export const selectSpeakerError = (state) => state.speaker.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true))
    let result = await get(`speaker/getSpeakerDetails`, props);
    if(result.isSuccess){
        dispatch(initialize({ data: result.data, key: props.opportunityId + props.speakerId + props.speakerAccountId }));
    }
    else{
        dispatch(error(result.errorMessage))
    }
}

