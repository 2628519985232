import { createSlice } from '@reduxjs/toolkit';
import {get, post} from '../../api/';

export const loginSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'login',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.data = {};
            state.status = 'failed';
            state.error = action.payload;
        },
    },
})

// Export all the reducers actions here
export const { initialize, error } = loginSlice.actions;

export const selectLoginData = (state) => state.login.data;
export const selectLoginStatus = (state) => state.login.status;
export const selectLoginError = (state) => state.login.error;

export const initializeAsync = (params) => async (dispatch) => {
    const result = await get('master/getAuthenticationMode');
    if(result.isSuccess){
        dispatch(initialize({authenticationMode: result.data.value}));
    }
    else{
        dispatch(error(result.errorMessage))
    }
    
}

export default loginSlice.reducer