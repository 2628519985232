import { createSlice } from '@reduxjs/toolkit';
import {publicQuery} from '../../services/salesforce'

export const sharedProposalSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'sharedProposal',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
    },
})

export default sharedProposalSlice.reducer

// Export all the reducers actions here
export const { initialize, error, loading, initialLoading } = sharedProposalSlice.actions;


// Public Selectors
export const selectSharedProposalData = (state) => state.sharedProposal.data;
export const selectSharedProposalStatus = (state) => state.sharedProposal.status;
export const selectSharedProposalError = (state) => state.sharedProposal.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true))
    const resultOpportunity = await publicQuery('opportunity', 'getRecordById', {recordId: props.recordId, queryFields: 'getDetailQueryFields'});
    const result = await publicQuery('proposedSpeaker', 'getProposedSpeaker', {recordId: props.recordId, orderByField: 'Proposal_Group__r.Order__c, Order__c' });
    if(result.isSuccess && resultOpportunity.isSuccess && resultOpportunity.data?.length > 0){
        let payload = {}
        payload[props.recordId] = {records: result.data, opportunity: resultOpportunity.data[0]}
        dispatch(initialize(payload));
    }
    else{
        dispatch(error(result.errorMessage))
    }
}

