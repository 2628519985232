import * as React from 'react';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography
} from '@mui/material';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PersonIcon from '@mui/icons-material/Person';

import { config, styles } from '../../style';

import { format } from 'date-fns';
import { Badge } from '../Badge';


const EventCard = ({ event, type }) => {

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', border: '1px solid #E9E9E9', minHeight: '200px', height: '100%' }}>
            <CardContent sx={{ p: 0, paddingBottom: '0px !important' }}>
                <Box sx={{ p: '10px', background: styles.card.cardHeaderColor, display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}>

                    {type === 'proposal' && <Box sx={{ display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}><HistoryEduIcon fontSize='small' sx={{ mr: '10px' }}></HistoryEduIcon> Proposal</Box>}
                    {type === 'event' && <Box sx={{ display: 'flex', flexDirection: 'row', color: '#ffffff', alignItems: 'center' }}><LocalActivityIcon fontSize='small' sx={{ mr: '10px' }}></LocalActivityIcon> Event</Box>}
                </Box>
                <Box sx={{ p: '10px', display: 'flex', flexDirection: 'column' }}>

                    <h3 sx={{}}>{event.eventName}</h3>
                    <Grid sx={{ my: '5px' }} container wrap="wrap" spacing={0} justifyContent="start" direction="column">
                        <Grid item>
                            {
                                event?.speakerName &&
                                <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'left', alignItems: 'center', fontSize: '12px' }}>
                                    <PersonIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                    <span style={{ marginLeft: '5px' }}>{event?.speakerName}</span>
                                </Box>
                            }
                        </Grid>
                        <Grid item>
                            {
                                event?.startPlayDate ? (
                                    <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'flex-start', mr: '20px', fontSize: '12px' }}>
                                        <CalendarMonthIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                        <span style={{ marginLeft: '5px' }}>{format(new Date(event?.startPlayDate), 'MMMM d, yyyy')}</span>
                                        {event?.endPlayDate && event?.startPlayDate !== event?.endPlayDate &&  <span style={{ marginLeft: '5px' }}> - {format(new Date(event?.endPlayDate), 'MMMM d, yyyy')}</span>}
                                    </Box>
                                ) : ''
                            }
                        </Grid>
                        <Grid item>
                            {
                                event?.venueName &&
                                <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'left', alignItems: 'flex-start', fontSize: '12px' }}>
                                    <LocationOnIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                    <Typography variant="body" style={{ marginLeft: '5px' }}>{event?.venueName}</Typography>
                                </Box>
                            }
                        </Grid>

                        <Grid item>
                            <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'left', alignItems: 'flex-start', fontSize: '12px' }}>
                                <PersonIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                <Typography variant="body" sx={{ marginLeft: '5px' }}>{event.opportunityContactName}</Typography>
                            </Box>
                        </Grid>



                    </Grid>

                    {/* <Badge sx={{ mt: 'auto' }} subject={formatStageName(event.stageName)}></Badge> */}

                </Box>
            </CardContent>
            {/* <Grid sx={{ p: 0 }} container wrap="nowrap" spacing={2}>
                        <Grid item sx={{ background: '#dfdfdf', paddingTop: '15px', paddingLeft: '15px' }}>
                            
                        </Grid>
                        <Grid item xs zeroMinWidth sx={{ padding: '15px' }}>
                            <Box>
                                <Typography noWrap>{record?.eventName}</Typography>
                            </Box>
                            <Box>
                                <span style={{ fontSize: '12px' }}>{record?.stageName}</span>
                            </Box>
                        </Grid>
                    </Grid> */}
        </Card>

    );
}

const formatStageName = (name) => {
    if(name === 'Proposal') {
        return 'Open'
    }
    return 'Planned'
}

export { EventCard }
