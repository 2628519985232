import { 
    faFile,
    faFolder,
    faBrain,
    faPaperclip,
    faFileAudio,
    faFileCsv,
    faFileExcel,
    faSquare,
    faFileWord,
    faFileCode,
    faFileImage,
    faFilePowerpoint,
    faVideo,
    faPager,
    faFilePdf,
    faFileLines,
    faFileZipper
} from '@fortawesome/free-solid-svg-icons'

export const iconMapping = {
    'folder': {icon: faFolder},
    'ai': {icon: faBrain},
    'attachment': {icon: faPaperclip},
    'audio': {icon: faFileAudio},
    'csv': {icon: faFileCsv, color: 'ogange'},
    'eps': {icon: faFile},
    'excel': {icon: faFileExcel},
    'exe': {icon: faFile},
    'flash': {icon: faSquare},
    'gdoc': {icon: faFileWord},
    'gdocs': {icon: faFileWord},
    'gform': {icon: faFile},
    'gpres': {icon: faFile},
    'gsheet': {icon: faFileExcel},
    'html': {icon: faFileCode},
    'image': {icon: faFileImage},
    'jpg': {icon: faFileImage},
    'jpeg': {icon: faFileImage},
    'gif': {icon: faFileImage},
    'png': {icon: faFileImage},
    'keynote': {icon: faFilePowerpoint},
    'link': {icon: faFile},
    'mp4': {icon: faVideo, color: 'rgb(255, 0, 0)'},
    'overlay': {icon: faFile},
    'pack': {icon: faFile},
    'pages': {icon: faPager},
    'pdf': {icon: faFilePdf, color: '#9f0c0c'},
    'ppt': {icon: faFilePowerpoint},
    'pptx': {icon: faFilePowerpoint},
    'psd': {icon: faFileImage},
    'quip_doc': {icon: faFileWord},
    'quip_sheet': {icon: faFileExcel},
    'quip_slide': {icon: faFilePowerpoint},
    'rtf': {icon: faFile},
    'slide': {icon: faFilePowerpoint},
    'stypi': {icon: faFile},
    'txt': {icon: faFileLines},
    'video': {icon: faVideo},
    'visio': {icon: faFile},
    'webex': {icon: faVideo},
    'word': {icon: faFileWord},
    'xml': {icon: faFileCode},
    'xlsx': {icon: faFileExcel, color: 'green'},
    'xls': {icon: faFileExcel, color: 'green'},
    'zip': {icon: faFileZipper}
} 