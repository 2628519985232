import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Card,
    CardContent,
    Divider
  } from '@mui/material';


import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Card sx={styles.card}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                    <Grid item>
                        <Skeleton variant="rounded" width={400} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                </Grid>
                <Grid sx={{my: '15px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                    </Grid>
                </Grid>
                <Divider sx={{mx: '-15px'}}/>  
                <Grid sx={{my: '15px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                    <Grid item>
                        <Skeleton sx={{mr: '10px'}} variant="circular" width={20} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={150} height={20} />
                    </Grid>
                </Grid>
                <Box>
                    <Skeleton variant="rounded" width="100%" height={40} />
                </Box>
                <Divider sx={{mx: '-15px', mt: '15px'}}/>  

                <Grid container wrap="nowrap" spacing={3} sx={{ flexGrow: 1, my: '3px' }}>
                    <Grid xs={6} xsOffset={3} md={2} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={4} md={2} mdOffset="auto">
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={4} xsOffset={4} md={2} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
                
            </CardContent>
        </Card>
        
        <Box sx={{my: '15px'}}>
            <Skeleton variant="rounded" width={200} height={25} />
        </Box>
        <Box>
            <Skeleton variant="rounded" width={600} height={12} />
        </Box>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1, m: '-15px'}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={110} height={110} />
                            </Grid>
                            <Grid sx={{width: '100%', marginLeft: '30px'}} item>
                                <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <Skeleton variant="rounded" width={210} height={25} />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton variant="rounded" width={100} height={25} />
                                    </Grid>
                                </Grid>
                                <Box sx={{my: '10px'}}>
                                    <Skeleton variant="rounded" width='100%' height={70} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={220} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1, m: '-15px'}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={110} height={110} />
                            </Grid>
                            <Grid sx={{width: '100%', marginLeft: '30px'}} item>
                                <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <Skeleton variant="rounded" width={210} height={25} />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton variant="rounded" width={100} height={25} />
                                    </Grid>
                                </Grid>
                                <Box sx={{my: '10px'}}>
                                    <Skeleton variant="rounded" width='100%' height={70} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={220} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1, m: '-15px'}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={110} height={110} />
                            </Grid>
                            <Grid sx={{width: '100%', marginLeft: '30px'}} item>
                                <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <Skeleton variant="rounded" width={210} height={25} />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton variant="rounded" width={100} height={25} />
                                    </Grid>
                                </Grid>
                                <Box sx={{my: '10px'}}>
                                    <Skeleton variant="rounded" width='100%' height={70} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={220} height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={150} height={30} />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={8} item>
                                <Skeleton variant="rounded" width='90%' height={15} />
                            </Grid>
                            <Grid xs={4} item>
                                <Skeleton variant="rounded" width='100%' height={15} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
    </div>
)

