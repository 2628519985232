import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import {
    Box,
    Button,
    Typography,
    Divider,
    IconButton,
    Paper,
    FormControl,
    OutlinedInput,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FileUpload } from '../FileUpload';

export const CustomInput = forwardRef((props, ref) => {
    let { label, value, type, required, helpText, picklistOption, placeholder } = props;
    const [inputValue, setInputValue] = useState(value ? value : '');
    const [errorMessage, setErrorMessage] = useState('');

    let input;
    type = type ? type?.toLowerCase() : 'text';
    let id = label.replaceAll(' ', '')
    const [age, setAge] = useState('');
    let contactValue = {Salutation: ''}
    if (type === 'contact' && inputValue && typeof inputValue === 'string') {
        contactValue = inputValue !== '[object Object]' ?  JSON.parse(inputValue) : {};
        delete contactValue.attributes
    }

    const handleChange = (event) => {
        setErrorMessage('')
        setInputValue(event.target.value);
    }

    const handleContactChange = (event, field) => {
        setErrorMessage('')
        contactValue[field] = event.target.value
        setInputValue(JSON.stringify(contactValue))
    }

    useImperativeHandle(ref, () => ({

        cancel() {
            setInputValue(value ? value : '')
        },

        validate() {
            if(type === 'number') {
                if(required) {
                    if(!isNaN(inputValue) && inputValue > 0) {
                        return true;
                    } else {
                        setErrorMessage('This field is required.')
                        return false

                    }
                } else {
                    if(!isNaN(inputValue)) {
                        return true;
                    } else {
                        setErrorMessage('This is not a number')
                        return false
                    }
                }
            }
            else if (type === 'contact') {
                if (inputValue) {
                    let data = JSON.parse(inputValue);
                    if (!data.FirstName || !data.LastName || !data.Email) {
                        let errorData = {}
                        if (!data.FirstName) {
                            errorData['firstName'] = 'First Name is required'
                        }
                        if (!data.LastName) {
                            errorData['lastName'] = 'Last Name is required'
                        }
                        if (!data.LastName) {
                            errorData['email'] = 'Email is required'
                        }
                        setErrorMessage(errorData)
                        return false
                    }
                    else {
                        return true
                    }
                }
                if (required) { 
                    if(inputValue) {
                        return true;
                    }
                    else {
                        setErrorMessage('This field is required.')
                        return false
                    }
                }
            }
            else {
                if(required) {
                    if(inputValue) {
                        return true;
                    }
                    else {
                        setErrorMessage('This field is required.')
                        return false
                    }
                }
                else {
                    return true
                }
            }
        },
        getInputValue() {
            if (type === 'contact' && inputValue) {
                contactValue = JSON.parse(inputValue);
                delete contactValue.attributes
                return JSON.stringify(contactValue)
            }
            return inputValue
        }
    }));


    if(type === 'text' || type === 'number') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <p style={{}} >{label}</p>
                <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                        size="small"
                        id="input"
                        type={type}
                        aria-describedby="helper-text"
                        required
                        value={inputValue}
                        ref={ref}
                        onChange={handleChange}
                        placeholder={placeholder ? placeholder : "Enter value"}
                    />
                    <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage ? errorMessage : helpText}</FormHelperText>
                </FormControl>
            </Box>
        )
    }
    else if(type === 'picklist') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <p style={{}} >{label}</p>
                <FormControl fullWidth variant="outlined">
                    <Select
                        id={id + "demo-simple-select"}
                        displayEmpty
                        value={inputValue}
                        onChange={handleChange}
                        ref={ref}
                        size="small"
                        renderValue={(selected) => {
                            if(selected.length === 0) {
                                return <span style={{ color: '#9c9ea6' }} >{placeholder ? placeholder : 'Select value'}</span>;
                            }

                            return selected;
                        }}
                    >
                        {picklistOption.split(';').map(item => (
                            <MenuItem value={item.trim()}>{item.trim()}</MenuItem>
                        ))}

                    </Select>
                    <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage ? errorMessage : helpText}</FormHelperText>
                </FormControl>
            </Box>

        )
    }

    else if(type === 'section') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem', p: '5px 10px', border: '1px solid #e7e8f0', borderRadius: '8px', background: '#f1f1ff', fontWeight: '500', color: '#5048e7' }}>
                <p style={{}} >{label}</p>
            </Box>
        )
    }

    else if(type === 'date') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <p style={{}} >{label}</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            id="input"
                            type='date'
                            aria-describedby="helper-text"
                            required
                            value={inputValue}
                            ref={ref}
                            onChange={handleChange}
                            placeholder={placeholder ? placeholder : "Enter value"}
                        />
                        <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage ? errorMessage : helpText}</FormHelperText>
                    </FormControl>
                </LocalizationProvider>
            </Box>
        )
    }

    else if(type === 'file upload') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <p style={{}} >{label}</p>
                <FileUpload></FileUpload>
            </Box>
        )
    }

    else if(type === 'contact') {
        input = (
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <Box sx={{ width: '100%', mb: '1rem', p: '5px 10px', border: '1px solid #e7e8f0', borderRadius: '8px', background: '#f1f1ff', fontWeight: '500', color: '#5048e7' }}>
                    <p style={{}} >{label}</p>
                </Box>

                <Box sx={{ width: '100%', mb: '1rem' }}>
                    <p style={{}} >Salutation</p>
                    <FormControl fullWidth variant="outlined">
                        <Select
                            id={id + "demo-simple-select"}
                            displayEmpty
                            value={contactValue.Salutation}
                            onChange={(event) => handleContactChange(event, 'Salutation')}
                            ref={ref}
                            size="small"
                            renderValue={(selected) => {
                                if(selected?.length === 0) {
                                    return <span style={{ color: '#9c9ea6' }} >{placeholder ? placeholder : 'Select value'}</span>;
                                }

                                return selected;
                            }}
                        >
                            <MenuItem value="">--None--</MenuItem>
                            <MenuItem value="Ms.">Ms.</MenuItem>
                            <MenuItem value="Mr.">Mr.</MenuItem>
                            <MenuItem value="Mrs.">Mrs.</MenuItem>
                            <MenuItem value="Dr.">Dr.</MenuItem>
                            <MenuItem value="Prof.">Prof.</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', mb: '1rem' }}>
                    <p style={{}} >First Name</p>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            size="small"
                            id="input"
                            type='text'
                            aria-describedby="helper-text"
                            required
                            value={contactValue.FirstName}
                            ref={ref}
                            onChange={(event) => handleContactChange(event, 'FirstName')}
                            placeholder={placeholder ? placeholder : "Enter value"}
                        />
                        <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage?.firstName}</FormHelperText>
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', mb: '1rem' }}>
                    <p style={{}} >Middle Name</p>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            size="small"
                            id="input"
                            type='text'
                            aria-describedby="helper-text"
                            required
                            value={contactValue.MiddleName}
                            ref={ref}
                            onChange={(event) => handleContactChange(event, 'MiddleName')}
                            placeholder={placeholder ? placeholder : "Enter value"}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', mb: '1rem' }}>
                    <p style={{}} >Last Name</p>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            size="small"
                            id="input"
                            type='text'
                            aria-describedby="helper-text"
                            required
                            value={contactValue.LastName}
                            ref={ref}
                            onChange={(event) => handleContactChange(event, 'LastName')}
                            placeholder={placeholder ? placeholder : "Enter value"}
                        />
                        <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage?.lastName}</FormHelperText>
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', mb: '1rem' }}>
                    <p style={{}} >Email</p>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            size="small"
                            id="input"
                            type="email"
                            aria-describedby="helper-text"
                            required
                            value={contactValue.Email}
                            ref={ref}
                            onChange={(event) => handleContactChange(event, 'Email')}
                            placeholder={placeholder ? placeholder : "Enter value"}
                        />
                        <FormHelperText id="helper-text" error={errorMessage ? true : false}>{errorMessage?.email}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>
        )
    }
    return (
        <>
            {input}
        </>
    );
})