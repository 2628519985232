import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Divider
  } from '@mui/material';


import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Box sx={{p: '1rem'}}>
            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
                <Grid item sx={{flex: 'auto', ml: '5px', fontWeight: '600', fontSize: '15px', color: '#232323'}}>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
            </Grid>
            <Box sx={{color: '#232323', fontSize: '12px', mt: '10px'}}>
                <Skeleton variant="rounded" width='100%' height={70} />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: '#e5e5e5',
              mt: '4px'
            }}
          />
          <Box sx={{p: '1rem'}}>
            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
                <Grid item sx={{flex: 'auto', ml: '5px', fontWeight: '600', fontSize: '15px', color: '#232323'}}>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
            </Grid>
            <Box sx={{color: '#232323', fontSize: '12px', mt: '10px'}}>
                <Skeleton variant="rounded" width='100%' height={70} />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: '#e5e5e5',
              mt: '4px'
            }}
          />
          <Box sx={{p: '1rem'}}>
            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
                <Grid item sx={{flex: 'auto', ml: '5px', fontWeight: '600', fontSize: '15px', color: '#232323'}}>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
            </Grid>
            <Box sx={{color: '#232323', fontSize: '12px', mt: '10px'}}>
                <Skeleton variant="rounded" width='100%' height={70} />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: '#e5e5e5',
              mt: '4px'
            }}
          />
          <Box sx={{p: '1rem'}}>
            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
                <Grid item sx={{flex: 'auto', ml: '5px', fontWeight: '600', fontSize: '15px', color: '#232323'}}>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rounded" width={100} height={18} />
                </Grid>
            </Grid>
            <Box sx={{color: '#232323', fontSize: '12px', mt: '10px'}}>
                <Skeleton variant="rounded" width='100%' height={70} />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: '#e5e5e5',
              mt: '4px'
            }}
          />
    </div>
)