import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Button,
    Typography,
    MenuItem,
    Menu,
    Chip,
    IconButton,
    Divider,
    Skeleton
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { config, styles } from '../../style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const Filter = (props) => {
    const { label, children, icon } = props
    const [isOpen, setIsOpen] = useState(false)
    const handleHeaderClick = () => {
        setIsOpen(!isOpen)
    } 
    return (
        <>
            <Box sx={{color: '#344054'}}>
                <Grid container justifyContent="space-between" onClick={handleHeaderClick} sx={{pl: '10px', pr: '15px'}}>
                    <Grid item sx={{ mt: '3px', fontSize: '16px', fontWeight: '500', color: isOpen ? '#444CE7' : '' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                            {icon}
                            <span style={{marginLeft: '8px'}}>{label}</span>
                        </div>
                    </Grid>
                    <Grid item sx={{ mt: '3px' }}>
                        {!isOpen && <ExpandMoreIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                        { isOpen && <ExpandLessIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                    </Grid>
                </Grid>
                <Box sx={{padding: '0 .75rem 0.5rem 0.75rem', display: isOpen ? 'Block' : 'none'}}>
                    {children}
                </Box>
            </Box>
            <Divider></Divider>
        </>
        
    )
}