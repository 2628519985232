import { createSlice } from '@reduxjs/toolkit';
import {query, describe} from '../../services/salesforce'

export const contactSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'contact',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        }
    },
})

export default contactSlice.reducer

// Export all the reducers actions here
export const { initialize, error, loading } = contactSlice.actions;


// Public Selectors
export const selectContactData = (state) => state.contact.data;
export const selectContactStatus = (state) => state.contact.status;
export const selectContactError = (state) => state.contact.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const userDetails = await props.getUserDetails()
    const result = await describe('case', {cacheable: true})
    if(result.isSuccess){
        dispatch(initialize({describe: result.data, userDetails: userDetails}));
    }else{
        dispatch(error(result.errorMessage))
    }
}

