import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent, getTimeMe } from "../../utils";
import ReactPlayer from "react-player";
import {
    selectSpeakerData,
    selectSpeakerStatus,
    selectSpeakerError,
    initializeAsync,
    initialLoading,
} from "./speakerSlice";

import {
    Box,
    Button,
    TextField,
    Typography,
    Divider,
    Tab,
    Paper,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Tooltip,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import AddCommentIcon from "@mui/icons-material/AddComment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { AttachMoney, Bookmark, Info, Mic } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import { useNavigate } from "react-router-dom";
import { skeleton } from "./skeleton";
import Skeleton from "@mui/material/Skeleton";
import { config, styles } from "../../style";

import { styled } from "@mui/material/styles";
import { Badge } from "../../components/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTracking } from "react-tracking";
import { Newspaper, OndemandVideo, RecordVoiceOver } from "@mui/icons-material";
import WorksPlaceholder from "../../assets/images/placeholders/works_placeholder.png";
import "./style.css";
import { Image } from "@aws-amplify/ui-react";
import { format } from "date-fns";
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
        color: config.primaryColor,
        opacity: 1,
    },
    "&.Mui-selected": {
        color: config.primaryColor,
        fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
        backgroundColor: config.primaryColor,
    },
}));

// Add custom imports here

const Speaker = (props) => {
    let {
        opportunityId,
        speakerId,
        opportunityName,
        speakerRecord,
        shared,
        onRequestAvailabilityClick,
        userDetails,
        disableTracking,
        defaultSpeakerDetail,
        actionButtons,
        feeDetails,
        opportunityCreatedDate,
        opportunity,
    } = props;

    //let record = { ...speakerRecord }
    const data = useSelector(selectSpeakerData);
    const status = useSelector(selectSpeakerStatus);
    const error = useSelector(selectSpeakerError);
    const [selectedTab, setSelectedTab] = useState("");
    const dispatch = useDispatch();
    let record = useSelector((state) => {
        let data = state.speaker.data[opportunityId + speakerId + speakerRecord.id];
        return { ...speakerRecord, speakerDetails: data ? data : speakerRecord.speakerDetails };
    });

    let setType = [];
    let sortedTypes = ["Biographies", "Fee", "Works", "Testimonials", "Speech Topics", "Videos", "News"];
    if (record?.speakerDetails?.length > 0) {
        for (let detail of record.speakerDetails) {
            if (detail.type !== "Agent Notes") {
                if (setType.indexOf(detail.type) === -1) {
                    setType.push(detail.type);
                }
            }
        }
        if (setType.length > 0) {
            setType = sortedTypes.filter((item) => setType.indexOf(item) > -1);
        }
    } else {
        setType.push("Biographies");
    }

    const { trackEvent } = useTracking();

    let speakerTabTimeme = getTimeMe();
    speakerTabTimeme.initialize({ currentPageName: "SpeakerTab" });
    let speakerTimeme = getTimeMe();
    speakerTimeme.initialize({ currentPageName: "Speaker" });

    useEffect(() => {
        if (!data[opportunityId + speakerId + speakerRecord.id]) {
            if (status !== "initalLoading") {
                dispatch(initializeAsync({ opportunityId, speakerId, speakerAccountId: speakerRecord.id }));
            }
        } else {
            if (setType.length > 0 && !selectedTab) {
                if (setType.indexOf("Fee") > -1) {
                    setSelectedTab("Fee");
                } else {
                    setSelectedTab(setType[0]);
                }

                if (!disableTracking) {
                    trackEvent(
                        getTrackEventData(
                            "click",
                            "speaker-sidebar",
                            `Viewed ${record?.speakerName}'s ${setType.indexOf("Fee") > -1 ? "Fee" : setType[0]}`,
                            record
                        )
                    );
                }
            }
        }
    }, [setType]);

    const getTrackEventData = (action, type, description, record, duration, page) => {
        return {
            action: action,
            type: type,
            description: description + (shared || userDetails?.userType === "anonymous" ? " from Share Link" : ""),
            parentId: opportunityId,
            parantName: opportunityName,
            speakerId: record?.speakerId,
            speakerName: record?.speakerName,
            userDetails: userDetails,
            duration: duration,
            page: page,
            shared: shared,
        };
    };

    //const data = useSelector(selectSpeakerData);

    //const navigate = useNavigate();

    // const speakerContent = useSelector((state) => {
    //     let data = state.speaker?.data[opportunityId + speakerId]
    //     if(data?.tabContent && !selectedTab) {
    //         setSelectedTab(!data?.tabContent ? '' : Object.keys(data?.tabContent)[0])
    //     }
    //     console.log(data);
    //     return data
    // })
    // const dispatch = useDispatch();

    // Add Variables

    // useEffect(() => {
    //     if(!data[opportunityId + speakerId]) {
    //         dispatch(initializeAsync({ opportunityId, speakerId, shared }));
    //     } else {
    //         dispatch(initialLoading(false))
    //     }
    // }, [status, dispatch]);

    const handleTabChange = (event, newValue) => {
        // trackEvent(getTrackEventData('duration', 'tab', `User spend ${speakerTabTimeme.getTimeOnPageInSeconds('SpeakerTab')} seconds on the ${selectedTab} tab`, record, speakerTabTimeme.getTimeOnPageInSeconds('SpeakerTab'), 'speaker'));
        speakerTabTimeme = getTimeMe();
        speakerTabTimeme.initialize({ currentPageName: "SpeakerTab" });
        if (!disableTracking) {
            trackEvent(
                getTrackEventData("click", "speaker-sidebar", `Viewed ${record.speakerName}'s ${newValue}`, record)
            );
        }

        setSelectedTab(newValue);

        setTimeout(() => {
            var element = document.querySelector(".right-side");
            element?.scrollTo({ top: 0, left: 0, behavior: "instant" });
        }, 10);
    };

    const createMarkup = (text) => {
        return { __html: text };
    };

    const handleCloseDrawer = () => {
        const event = new CustomEvent("closeSpeakerDrawer");
        document.dispatchEvent(event);
        // trackEvent(getTrackEventData('click', 'speaker-sidebar', `Clicked to close the speaker sidebar for ${record?.speakerName}`, record));
        // trackEvent(getTrackEventData('duration', 'page', `User spent ${speakerTimeme.getTimeOnPageInSeconds('Speaker')} seconds on the speaker page`, record, speakerTimeme.getTimeOnPageInSeconds('Speaker'), 'speaker'));
        speakerTimeme.stopAllTimers();
    };

    const handleSpeakerAction = (actionName) => {
        const event = new CustomEvent("speakerAction", { detail: { value: record, action: actionName } });
        document.dispatchEvent(event);
    };

    const hanldeRequestAvailability = (event) => {
        onRequestAvailabilityClick(event, record.speakerId, record);
    };

    const getSocialMediaValue = (socialName) => {
        if (socialName === "Website") {
            return record.speakerWebsite;
        } else if (socialName === "Twitter") {
            return record.speakerTwitter;
        } else if (socialName === "Instagram") {
            return record.speakerInstagram;
        } else if (socialName === "LinkedIn") {
            return record.speakerLinkedIn;
        } else if (socialName === "YouTube") {
            return record.speakerYouTube;
        } else if (socialName === "Facebook") {
            return record.speakerFacebook;
        }
    };
    const getAgentNotes = (record) => {
        if (record?.speakerDetails?.filter((item) => item.type === "Agent Notes")?.length > 0) {
            return record?.speakerDetails?.filter((item) => item.type === "Agent Notes")[0].agentNotes;
        }
        return "";
    };

    const getBioDetails = () => {
        if (record?.speakerDetails?.length > 0) {
            let detials = record?.speakerDetails?.filter((item) => item.type === "Biographies");
            if (detials.length > 0) {
                if (detials[0].includeAbout || detials[0].includeShortBio || detials[0].includeSubheading) {
                    return (
                        <Box>
                            {detials[0].includeSubheading && (
                                <Box>
                                    <b>
                                        <div dangerouslySetInnerHTML={createMarkup(detials[0].subHeading)}></div>
                                    </b>
                                </Box>
                            )}
                            {detials[0].includeShortBio && (
                                <Box sx={{ mt: "15px" }}>
                                    <i>
                                        <div dangerouslySetInnerHTML={createMarkup(detials[0].biography)}></div>
                                    </i>
                                </Box>
                            )}

                            {detials[0].includeAbout && (
                                <Box sx={{ mt: "15px" }}>
                                    <div dangerouslySetInnerHTML={createMarkup(detials[0].about)}></div>
                                </Box>
                            )}
                        </Box>
                    );
                } else {
                    <div
                        dangerouslySetInnerHTML={createMarkup(
                            record.speakerShortBio ? record.speakerShortBio : record.speakerPromotionalTitle
                        )}></div>;
                }
            } else {
                return "";
            }
        }

        return (
            <div
                dangerouslySetInnerHTML={createMarkup(
                    record.speakerShortBio ? record.speakerShortBio : record.speakerPromotionalTitle
                )}></div>
        );
    };

    const isDateMoreThan90DaysOld = (inputDate) => {
        const currentDate = new Date();
        const givenDate = new Date(inputDate);
        const differenceInMilliseconds = currentDate - givenDate;
        const daysDifference = differenceInMilliseconds / (1000 * 3600 * 24);
        return daysDifference > 90;
    };

    const getFeeDetail = (item) => {
        let amount = item.feeAmount ? item.feeAmount : 0;
        let tooltip = "";
        let description = "";
        if (!item.feeTBD) {
            if (item.flatTravel && item.flatTravel > 0 && item.flatTravelDisplay === "Display Flat Travel Amount") {
                amount = item.feeAmount + (item.flatTravel ? parseFloat(item.flatTravel) : 0);
                tooltip = (
                    <Tooltip
                        title={`This fee is inclusive of a ${new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                        }).format(item.flatTravel ? item.flatTravel : 0)} flat fee to cover expenses.`}
                        sx={{ mt: "-2px" }}>
                        <IconButton size="small">
                            <Info fontSize="inherit"></Info>
                        </IconButton>
                    </Tooltip>
                );
                description = item.feeCustomClientExtentWithFlatTravel;
            } else if (
                item.flatTravel &&
                item.flatTravel > 0 &&
                item.flatTravelDisplay === "Display Flat Travel Option Language (No Amount)"
            ) {
                amount = item.feeAmount;
                tooltip = (
                    <Tooltip title={`A fixed flat fee is available to cover travel expenses.`} sx={{ mt: "-2px" }}>
                        <IconButton size="small">
                            <Info fontSize="inherit"></Info>
                        </IconButton>
                    </Tooltip>
                );
                description = item.feeClientExtent;
            } else if (item.flatTravel && item.flatTravel > 0 && item.flatTravelDisplay === "Hide Flat Travel") {
                amount = item.feeAmount;
                description = item.feeClientExtent;
            } else {
                amount = item.feeAmount;
                description = item.feeClientExtent;
            }
        } else {
            description = "Fee to be confirmed upon interest.";
        }

        return (
            <>
                <Grid sx={{ mb: "5px" }} container wrap="nowrap" spacing={0} justifyContent="space-between">
                    <Grid xs={8} item>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                            <AttachMoney
                                sx={{
                                    color: "#adadad",
                                    fontSize: "1rem",
                                    mr: "0.5rem",
                                }}></AttachMoney>

                            <b>
                                {item.feeName?.split(/-(.*)/s)?.length > 1
                                    ? item.feeName?.split(/-(.*)/s)[1]
                                    : item.feeName?.split(/-(.*)/s)[0]}
                            </b>
                            <>
                                {!item.feeTBD && ` - `}
                                <b>
                                    {!item.feeTBD &&
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                        }).format(amount)}
                                </b>
                            </>
                            {tooltip}
                        </div>
                    </Grid>
                </Grid>
                {description && !item.feeTBD && <Box>{"Client to provide " + description}</Box>}
                {description && item.feeTBD && <Box>{description}</Box>}
            </>
        );
    };

    const getPromotionalTitle = (record) => {
        if (record?.speakerDetails?.filter((item) => item.type === "Biographies")?.length > 0) {
            let detail = record?.speakerDetails?.filter((item) => item.type === "Biographies")[0];
            if (detail.includeSubheading) {
                return (
                    <Box>
                        <div dangerouslySetInnerHTML={createMarkup(detail.subHeading)}></div>
                    </Box>
                );
            }
        }
        return "";
    };

    let body = (
        <>
            <Box
                sx={{
                    width: {
                        xs: "300px",
                        md: "590px",
                        lg: "1142px",
                    },
                    m: {
                        lg: "-19px",
                    },
                    minWidth: {
                        lg: "1142px",
                    },
                    mt: {
                        xs: "-20px",
                        lg: "-19px",
                    },
                    ml: {
                        xs: "-16px",
                        lg: "-19px",
                    },
                }}>
                {status === "initalLoading" ? (
                    skeleton
                ) : (
                    <div style={styles.container}>
                        <Grid container wrap="nowrap" spacing={0} sx={{ position: "relative" }}>
                            <Grid
                                xs={4}
                                sx={{
                                    height: "calc(100vh - 114px)",
                                    background: "#FAFAF9",
                                    p: "1.2rem",
                                    display: { xs: "none", lg: "block" },
                                    width: "330px",
                                    position: "absolute",
                                    top: "2px",
                                    overflow: "scroll",
                                }}
                                item>
                                <Box sx={{}}>
                                    {record.speakerImageURL && (
                                        <Box
                                            component="img"
                                            sx={{
                                                objectFit: "cover",
                                                objectPosition: "top",
                                                width: "294px",
                                                height: "347px",
                                                borderRadius: "4px",
                                            }}
                                            src={`${record.speakerImageURL}`}></Box>
                                    )}
                                    {!record.speakerImageURL && <Skeleton variant="rounded" width={294} height={294} />}
                                    <Typography sx={{ ...styles.pageHeading, mt: "1rem" }}>
                                        {record?.speakerName}
                                    </Typography>

                                    {getPromotionalTitle(record) && (
                                        <Box sx={{ my: ".5rem" }}>{getPromotionalTitle(record)}</Box>
                                    )}

                                    {getAgentNotes(record) && (
                                        <Box sx={{ mb: "40px" }}>
                                            <div className="speaker-modal-note notes note-sent">
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography variant="h3" sx={{ fontSize: "18px" }}>
                                                        Agent notes
                                                    </Typography>
                                                </Box>

                                                <Grid
                                                    container
                                                    wrap="nowrap"
                                                    spacing={0}
                                                    justifyContent="space-between">
                                                    <Grid item sx={{ height: "100%" }}>
                                                        <Typography sx={{ fontSize: "16px" }}>
                                                            {getAgentNotes(record)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Box>
                                    )}

                                    <Box sx={{ mt: "1rem" }}>
                                        <Box sx={{ display: "flex" }}>
                                            <h3>Fees</h3>
                                            <Tooltip
                                                title="View fee details on the Speaking Fees tab"
                                                sx={{ mt: "-2px" }}>
                                                <IconButton size="small">
                                                    <Info fontSize="inherit"></Info>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        {opportunity?.isPortalFeesHistorical ||
                                        isDateMoreThan90DaysOld(opportunityCreatedDate) ? (
                                            <Box>
                                                <Alert severity="info" sx={{ mb: "10px" }}>
                                                    Fees in your proposal are over 90 days old. Please reach out to your
                                                    WSB point of contact for updated fees.
                                                </Alert>
                                            </Box>
                                        ) : (
                                            <Box sx={{ margin: "10px" }}>{feeDetails}</Box>
                                        )}
                                    </Box>

                                    <Box sx={{ my: "1rem", pb: "1rem" }}>{actionButtons}</Box>
                                </Box>
                            </Grid>
                            <Grid
                                lg={8}
                                xs={12}
                                item
                                sx={{
                                    minWidth: "calc(100% - 330px - 1.5rem)",
                                    position: "absolute",
                                    overflow: "scroll",
                                    height: "calc(100vh - 114px)",
                                    // left: "calc(330px + 1rem)",
                                    left: { xs: "1rem", lg: "calc(330px + 1rem)" },
                                    mt: { xs: "1px", lg: "0px" },
                                }}
                                className="right-side">
                                <Box sx={{ width: "100%" }}>
                                    <TabContext value={selectedTab}>
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                                position: { xs: "fixed", lg: "fixed" },
                                                zIndex: "1",
                                                background: "white",
                                                width: {
                                                    xs: "300px",
                                                    md: "590px",
                                                    lg: "calc(1110px - 333px)",
                                                },
                                                marginTop: "3px",
                                            }}>
                                            <TabList
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                onChange={handleTabChange}
                                                aria-label="lab API tabs example">
                                                {!setType.length > 0
                                                    ? ""
                                                    : setType.map((item) => (
                                                          <AntTab
                                                              label={
                                                                  item === "Fee"
                                                                      ? "Speaking Fees"
                                                                      : item === "Biographies"
                                                                      ? "Biography"
                                                                      : item
                                                              }
                                                              value={item}
                                                          />
                                                      ))}
                                            </TabList>
                                        </Box>
                                        <TabPanel
                                            value="Biographies"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            {getBioDetails()}
                                        </TabPanel>
                                        <TabPanel
                                            value="Videos"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                {record?.speakerDetails?.filter((item) => item.type === "Videos")
                                                    ?.length > 0 &&
                                                    record?.speakerDetails
                                                        ?.filter((item) => item.type === "Videos")
                                                        ?.map((item, index) => (
                                                            <Grid key={item.id} xs={12}>
                                                                <Accordion
                                                                    defaultExpanded={true}
                                                                    sx={{
                                                                        "&:hover": {
                                                                            boxShadow: 8,
                                                                        },
                                                                    }}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content">
                                                                        <OndemandVideo
                                                                            sx={{ mr: "5px" }}></OndemandVideo>
                                                                        <Typography>{item.videoName}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <div className="player-wrapper">
                                                                            <ReactPlayer
                                                                                width="100%"
                                                                                height="100%"
                                                                                className="react-player"
                                                                                url={item.videoURL}
                                                                                controls={true}
                                                                            />
                                                                        </div>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        ))}
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value="News"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            {record?.speakerDetails?.filter((item) => item.type === "News")?.length >
                                                0 &&
                                                record?.speakerDetails
                                                    ?.filter((item) => item.type === "News")
                                                    ?.map((item, index) => (
                                                        <Link href={item.newsLink} target="_blank">
                                                            <Paper
                                                                sx={{
                                                                    mb: "1rem",
                                                                    p: "1rem",
                                                                    "&:hover": {
                                                                        boxShadow: 8,
                                                                    },
                                                                }}>
                                                                {/* <Newspaper fontSize='large' color='disabled' sx={{ ml: 'auto' }}></Newspaper> */}

                                                                <Box
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        mb: "10px",
                                                                        fontStyle: "italic",
                                                                        display: "flex",
                                                                    }}>
                                                                    {item?.newsSource && <Box>{item.newsSource}</Box>}{" "}
                                                                    {item?.newsSource && item?.releaseDate && (
                                                                        <Box sx={{ color: "lightgray" }}>
                                                                            <span>&nbsp;&#8226;&nbsp;</span>
                                                                        </Box>
                                                                    )}{" "}
                                                                    {item?.releaseDate && (
                                                                        <Box>
                                                                            {format(
                                                                                new Date(item.releaseDate),
                                                                                "MM/dd/yyyy"
                                                                            )}
                                                                        </Box>
                                                                    )}
                                                                </Box>

                                                                {/* <FormatQuoteIcon fontSize='large' color='disabled' sx={{ ml: 'auto' }}></FormatQuoteIcon> */}
                                                                <Box sx={{ fontSize: "18px", mb: "20px" }}>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: "20px",
                                                                            color: "#20335B",
                                                                            fontWeight: 500,
                                                                            mb: "10px",
                                                                        }}>
                                                                        {item.newsTitle}
                                                                    </Typography>

                                                                    {item.newsContent && (
                                                                        <Typography>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item.newsContent,
                                                                                }}
                                                                            />
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Paper>
                                                        </Link>
                                                    ))}
                                        </TabPanel>
                                        <TabPanel
                                            value="Social Media"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                {record?.speakerDetails?.filter((item) => item.type === "Social Media")
                                                    ?.length > 0 &&
                                                    record?.speakerDetails
                                                        ?.filter((item) => item.type === "Social Media")
                                                        ?.map((item) => (
                                                            <Grid key={item.id} lg={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label={item.socialMediaValue}
                                                                    defaultValue=" "
                                                                    value={getSocialMediaValue(item.socialMediaValue)}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ))}
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value="Works"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            <Grid
                                                container
                                                rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="stretch">
                                                {record?.speakerDetails?.filter((item) => item.type === "Works")
                                                    ?.length > 0 &&
                                                    record?.speakerDetails
                                                        ?.filter((item) => item.type === "Works")
                                                        ?.map((item) => (
                                                            <Grid key={item.id} xs={12} md={4}>
                                                                <Link
                                                                    href={item.workURL}
                                                                    target="_blank"
                                                                    sx={{ color: "#1A2847" }}>
                                                                    <Box
                                                                        sx={{
                                                                            boxShadow: 2,
                                                                            p: "10px",
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            alignItems: "center",
                                                                            textAlign: "center",
                                                                            height: "100%",
                                                                            "&:hover": {
                                                                                boxShadow: 8,
                                                                            },
                                                                        }}>
                                                                        {!item.worksImagePublicUrl && (
                                                                            <img
                                                                                src={WorksPlaceholder}
                                                                                width={120}
                                                                                height={180}
                                                                            />
                                                                        )}
                                                                        {item.worksImagePublicUrl && (
                                                                            <img
                                                                                style={{
                                                                                    height: "225px",
                                                                                    width: "150px",
                                                                                    objectFit: "contain",
                                                                                }}
                                                                                src={item.worksImagePublicUrl}
                                                                            />
                                                                        )}
                                                                        <Box sx={{ mt: "10px" }}>
                                                                            {/* <Bookmark /> */}
                                                                            <Typography sx={{ fontSize: "16px" }}>
                                                                                {item.workName}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box>{item.workType}</Box>
                                                                    </Box>
                                                                </Link>
                                                            </Grid>
                                                        ))}
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value="Speech Topics"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            {record?.speakerDetails?.filter((item) => item.type === "Speech Topics")
                                                ?.length > 0 &&
                                                record?.speakerDetails
                                                    ?.filter((item) => item.type === "Speech Topics")
                                                    ?.map((item, index) => (
                                                        <>
                                                            <Accordion
                                                                defaultExpanded={true}
                                                                sx={{
                                                                    "&:hover": {
                                                                        boxShadow: 8,
                                                                    },
                                                                }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header">
                                                                    <Mic sx={{ mr: "5px" }} />
                                                                    <Typography sx={{ fontWeight: "600" }}>
                                                                        {item.topicName}
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <div
                                                                        dangerouslySetInnerHTML={createMarkup(
                                                                            item.topicDescription
                                                                        )}></div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            {/* <Box sx={{ color: '#ef6b19' }}>
                                                            {item.topicName}
                                                        </Box>
                                                        <Box sx={{ fontSize: '12px' }}>
                                                            <div dangerouslySetInnerHTML={createMarkup(item.topicDescription)}></div>
                                                        </Box>
                                                        <Divider sx={{ my: '.5rem' }}></Divider> */}
                                                        </>
                                                    ))}
                                        </TabPanel>
                                        <TabPanel
                                            value="Fee"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            {opportunity?.isPortalFeesHistorical ||
                                            isDateMoreThan90DaysOld(opportunityCreatedDate) ? (
                                                <Box>
                                                    <Alert severity="info" sx={{ mb: "10px" }}>
                                                        Fees in your proposal are over 90 days old. Please reach out to
                                                        your WSB point of contact for updated fees.
                                                    </Alert>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    {record?.speakerDetails?.filter((item) => item.type === "Fee")
                                                        ?.length > 0 &&
                                                        record?.speakerDetails
                                                            ?.filter((item) => item.type === "Fee")
                                                            ?.map((item) => (
                                                                <>
                                                                    {getFeeDetail(item)}
                                                                    <Divider sx={{ my: ".5rem" }}></Divider>
                                                                </>
                                                            ))}
                                                </Box>
                                            )}
                                        </TabPanel>
                                        <TabPanel
                                            value="Testimonials"
                                            sx={{
                                                padding: { xs: "0px", lg: "0px" },
                                                paddingTop: { xs: "70px", lg: "70px" },
                                                paddingBottom: { xs: "24px", lg: "24px" },
                                            }}>
                                            {record?.speakerDetails?.filter((item) => item.type === "Testimonials")
                                                ?.length > 0 &&
                                                record?.speakerDetails
                                                    ?.filter(
                                                        (item) =>
                                                            item.type === "Testimonials" &&
                                                            (item.testimonialsDescription || item.testimonialsIndustry)
                                                    )
                                                    ?.map((item) => (
                                                        <Paper
                                                            sx={{
                                                                mb: "1rem",
                                                                p: "1rem",
                                                                "&:hover": {
                                                                    boxShadow: 8,
                                                                },
                                                            }}>
                                                            {item.testimonialsDescription && (
                                                                <>
                                                                    <FormatQuoteIcon
                                                                        fontSize="large"
                                                                        color="disabled"
                                                                        sx={{ ml: "auto" }}></FormatQuoteIcon>
                                                                    <Box
                                                                        sx={{
                                                                            fontSize: "18px",
                                                                            mb: "20px",
                                                                            fontStyle: "italic",
                                                                        }}>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.testimonialsDescription,
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {item.testimonialsIndustry && (
                                                                <Box sx={{ fontSize: "14px" }}>
                                                                    <b>Industry:</b> {item.testimonialsIndustry}
                                                                </Box>
                                                            )}
                                                        </Paper>
                                                    ))}
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Box>
        </>
    );

    return getPageContent(body, status, error);
};

export { Speaker };
