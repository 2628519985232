import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { Router } from "./router";
import { Amplify, Analytics } from "aws-amplify";
import awsconfig from "./aws-exports";
import "./App.css";
import { useTracking } from "react-tracking";
import { save } from "./services/object";

Amplify.configure(awsconfig);

function App(props) {
    const { Track } = useTracking(
        {},
        {
            dispatch: (data) => {
                if (data.description) {
                    if (!data.userDetails?.loggedInAsUser) {
                        data = getTrackingData(data);
                        save("trackEvent", data).then((result) => {
                            if (result.isSuccess) {
                            } else {
                                console.log(result.errorMessage);
                            }
                        });
                    }
                }
            },
        }
    );

    const getTrackingData = (data) => {
        return {
            ...data,
            createdById: data.userDetails?.contact?.id,
            createdByName: data.userDetails?.contact?.name,
            createdByEmail: data.userDetails?.username,
            accountId: data.userDetails?.contact?.accountId,
            accountName: data.userDetails?.contact?.accountName,
            userDetails: "",
        };
    };

    Analytics.autoTrack("session", {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        provider: "AWSPinpoint",
    });
    Analytics.autoTrack("pageView", {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the event name, by default is 'pageView'
        eventName: "pageView",
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        // attributes: {
        //   attr: 'attr'
        // },
        // when using function
        // attributes: () => {
        //    const attr = somewhere();
        //    return {
        //        myAttr: attr
        //    }
        // },
        // OPTIONAL, by default is 'multiPageApp'
        // you need to change it to 'SPA' if your app is a single-page app like React
        type: "multiPageApp",
        // OPTIONAL, the service provider, by default is the Amazon Pinpoint
        provider: "AWSPinpoint",
        // OPTIONAL, to get the current page url
        getUrl: () => {
            // the default function
            return window.location.origin + window.location.pathname;
        },
    });
    Analytics.autoTrack("event", {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, events you want to track, by default is 'click'
        events: ["click"],
        // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
        // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
        // always put 'data' as the first prefix
        selectorPrefix: "data-amplify-analytics-",
        // OPTIONAL, the service provider, by default is the Amazon Pinpoint
        provider: "AWSPinpoint",
        // OPTIONAL, the default attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
            attr: "attr",
        },
        // when using function
        // attributes: () => {
        //    const attr = somewhere();
        //    return {
        //        myAttr: attr
        //    }
        // }
    });

    return (
        <>
            <Track>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router application={props.application}></Router>
                </ThemeProvider>
            </Track>
        </>
    );
}

export default App;
