import { createSlice } from '@reduxjs/toolkit';
import {query, describe} from '../../services/salesforce'

export const formContainerSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'formContainer',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
})

export default formContainerSlice.reducer

// Export all the reducers actions here
export const { initialize, error } = formContainerSlice.actions;


// Public Selectors
export const selectFormContainerData = (state) => state.formContainer.data;
export const selectFormContainerStatus = (state) => state.formContainer.status;
export const selectFormContainerError = (state) => state.formContainer.error;

export const initializeAsync = (props) => async (dispatch) => {
    // const result = await query('account');
    // if(result.isSuccess){
    //     dispatch(initialize({records: result.data}));
    // }
    // else{
    //     dispatch(error(result.errorMessage))
    // }

    dispatch(initialize({}));
}

