import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { Logo } from "../../components/Logo";

import {
    selectContactDetailsData,
    selectContactDetailsStatus,
    selectContactDetailsError,
    initializeAsync,
} from "./contactDetailsSlice";

import {
    Button,
    CssBaseline,
    TextField,
    Box,
    Typography,
    Container,
    Paper,
    Divider,
    Link,
    Snackbar,
    Alert,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import { get, post } from "../../api";
import { query } from "../../services/salesforce";
import { useNavigate } from "react-router-dom";

const ContactDetails = (props) => {
    const data = useSelector(selectContactDetailsData);
    const status = useSelector(selectContactDetailsStatus);
    const error = useSelector(selectContactDetailsError);
    const dispatch = useDispatch();
    const [openToster, setOpenToster] = useState(false);
    const [tosterVariant, setTosterVariant] = useState("error");
    const [tosterMessage, setTosterMessage] = useState("");
    const [loading, setLoading] = useState(false);

    let { recordId, proposalId } = useParams();
    const { getUserDetailsNotAsync, setUserDetailsNotAsync } = useAuth();
    let userDetails = getUserDetailsNotAsync();
    const navigate = useNavigate();

    const { application } = props;

    const { passwordLessLogin, login } = useAuth();

    useEffect(() => {
        if (status === "idle") {
            dispatch(initializeAsync());
        }
    }, [status, dispatch]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        if (formData.get("firstName") && formData.get("lastName")) {
            post("opportunity/updateContact", {
                email: userDetails?.username,
                firstName: formData.get("firstName"),
                lastName: formData.get("lastName"),
                title: formData.get("title"),
                opportunityId: recordId,
                userType: userDetails["userType"],
            }).then(async (result) => {
                if (result.isSuccess && result.data) {
                    result = await query("contact", "getContactByEmail", {
                        email: encodeURIComponent(userDetails?.username),
                    });
                    if (result.isSuccess && result.data?.length > 0) {
                        userDetails["contact"] = result.data[0];
                        setUserDetailsNotAsync(userDetails);
                        navigate("/shared/proposal/" + recordId + (proposalId ? "/" + proposalId : ""), {
                            replace: true,
                        });
                    } else {
                        setTosterVariant("error");
                        setTosterMessage(`Some error occur while create the contact. Please try again`);
                        setOpenToster(true);
                        setLoading(false);
                    }
                } else {
                    setTosterVariant("error");
                    setTosterMessage(`Some error occur while create the contact. Please try again`);
                    setOpenToster(true);
                    setLoading(false);
                }
            });
        } else {
            setTosterVariant("error");
            setTosterMessage(`All fields are required`);
            setOpenToster(true);
            setLoading(false);
        }
    };

    const handleTosterClose = () => {
        setOpenToster(false);
    };

    let body = (
        <Box
            component="main"
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(249, 250, 252)",
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexGrow: 1,
                    minHeight: "100%",
                }}
            >
                <Container maxWidth="sm">
                    <CssBaseline />
                    <Paper
                        elevation={16}
                        sx={{
                            backgroundColor: "rgb(255, 255, 255)",
                            color: "rgb(18, 24, 40)",
                            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            borderRadius: "8px",
                            boxShadow: "rgb(100 116 139 / 12%) 0px 10px 15px",
                            backgroundImage: "none",
                            overflow: "hidden",
                            padding: "32px",
                        }}
                    >
                        <Box sx={{ textAlign: "center" }}>
                            <Box>
                                <a>
                                    <Logo
                                        sx={{
                                            height: 40,
                                            width: 40,
                                        }}
                                    />
                                </a>
                            </Box>
                            <Typography component="h1" variant="h4">
                                Sign Up
                            </Typography>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="body1"
                                margin="1rem"
                                fontSize="0.875rem"
                            >
                                Let's create an account for you!
                            </Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} validate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="firstName"
                                label="First name"
                                name="firstName"
                                autoFocus
                                sx={{ borderRadius: "8px" }}
                                variant="outlined"
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="lastName"
                                label="Last name"
                                name="lastName"
                                sx={{ borderRadius: "8px" }}
                                variant="outlined"
                            />

                            <TextField
                                margin="normal"
                                fullWidth
                                id="title"
                                label="Title"
                                name="title"
                                sx={{ borderRadius: "8px" }}
                                variant="outlined"
                            />
                            <LoadingButton
                                size="small"
                                type="submit"
                                color="primary"
                                fullWidth
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, height: "45px" }}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openToster}
                autoHideDuration={6000}
                onClose={handleTosterClose}
            >
                <Alert onClose={handleTosterClose} severity={tosterVariant} variant="filled">
                    {tosterMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

    let content;
    if (status === "loading") {
        content = <p>Loading...</p>;
    } else if (status === "succeeded") {
        content = body;
    } else if (status === "failed") {
        content = <p>Error : {error}</p>;
    }

    return content;
};

export { ContactDetails };
