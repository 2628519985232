import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Typography, MenuItem, Menu, Chip, IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaceIcon from "@mui/icons-material/Place";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import EventIcon from "@mui/icons-material/Event";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AirlinesIcon from "@mui/icons-material/Airlines";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import FlightIcon from "@mui/icons-material/Flight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsIcon from "@mui/icons-material/Directions";
import { uniqueString } from "../../utils";

import { config, styles } from "../../style";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export const Itinerary = (props) => {
    const { recordId, activities, shared, recordName } = props;

    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const activityFilterOpen = Boolean(activityAnchorEl);
    const [dayAnchorEl, setDayAnchorEl] = useState(null);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState(null);
    const [selectedDayFilter, setSelectedDayFilter] = useState(null);
    const dayFilterOpen = Boolean(dayAnchorEl);

    const handleActivityFilterClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };
    const handleActivityFilterClose = () => {
        setActivityAnchorEl(null);
    };
    const handleDayFilterClick = (event) => {
        setDayAnchorEl(event.currentTarget);
    };
    const handleDayFilterClose = () => {
        setDayAnchorEl(null);
    };

    const getActivities = () => {
        let displayActivity = [...activities];

        if (selectedActivityFilter) {
            displayActivity = [...displayActivity.filter((item) => item.recordTypeName === selectedActivityFilter)];
        }

        if (selectedDayFilter) {
            displayActivity = [
                ...displayActivity.filter(
                    (item) =>
                        item.departureStart &&
                        format(new Date(item.departureStart), "MMM dd, yyyy") === selectedDayFilter
                ),
            ];
        }

        return displayActivity;
    };

    const getActivityFilterMenu = () => {
        let filters = {};

        for (let activity of activities) {
            filters[activity.recordTypeName] = activity;
        }

        return [
            { label: "All", value: "", id: uniqueString(10) },
            ...Object.keys(filters).map((item) => {
                return { label: item, value: item, id: uniqueString(10) };
            }),
        ];
    };

    const getDayFilterMenu = () => {
        let filters = {};

        for (let activity of activities) {
            const dateValue = format(new Date(activity.departureStart), "MMM dd, yyyy");
            filters[dateValue] = activity;
        }

        return [
            { label: "All", value: "", id: uniqueString(10) },
            ...Object.keys(filters).map((item) => {
                return { label: item, value: item, id: uniqueString(10) };
            }),
        ];
    };

    const handleActivitySelect = (value) => {
        setSelectedActivityFilter(value);
        handleActivityFilterClose();
    };

    const handleDaySelect = (value) => {
        setSelectedDayFilter(value);
        handleDayFilterClose();
    };

    const handleOpenComment = (subject) => {
        const event = new CustomEvent("openDrawer", {
            detail: { parentId: recordId, subject: subject, parentName: recordName },
        });
        document.dispatchEvent(event);
    };

    return (
        <>
            <Grid container>
                <Grid item>
                    Filter By:
                    <Button
                        id="activity-filter-button"
                        aria-controls={activityFilterOpen ? "activity-filter-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={activityFilterOpen ? "true" : undefined}
                        variant="outlined"
                        disableElevation
                        onClick={handleActivityFilterClick}
                        endIcon={<KeyboardArrowDownIcon sx={{ color: config.primaryColor }} />}
                        startIcon={<TimeToLeaveIcon sx={{ color: config.primaryColor }} />}
                        sx={{ ...styles.outlinedButton, marginLeft: "1rem" }}>
                        Activities {selectedActivityFilter && <span>: {selectedActivityFilter}</span>}
                    </Button>
                    <StyledMenu
                        id="activity-filter-menu"
                        MenuListProps={{
                            "aria-labelledby": "activity-filter-button",
                        }}
                        anchorEl={activityAnchorEl}
                        open={activityFilterOpen}
                        onClose={handleActivityFilterClose}>
                        {getActivityFilterMenu().map((item) => (
                            <MenuItem key={item.id} onClick={() => handleActivitySelect(item.value)} disableRipple>
                                {item.label}
                            </MenuItem>
                        ))}
                    </StyledMenu>
                    <Button
                        id="day-filter-button"
                        aria-controls={dayFilterOpen ? "day-filter-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={dayFilterOpen ? "true" : undefined}
                        variant="outlined"
                        disableElevation
                        onClick={handleDayFilterClick}
                        endIcon={<KeyboardArrowDownIcon sx={{ color: config.primaryColor }} />}
                        startIcon={<CalendarMonthIcon sx={{ color: config.primaryColor }} />}
                        sx={{ ...styles.outlinedButton, marginLeft: "1rem" }}>
                        Day {selectedDayFilter && <span>: {selectedDayFilter}</span>}
                    </Button>
                    <StyledMenu
                        id="day-filter-menu"
                        MenuListProps={{
                            "aria-labelledby": "day-filter-button",
                        }}
                        anchorEl={dayAnchorEl}
                        open={dayFilterOpen}
                        onClose={handleDayFilterClose}>
                        {getDayFilterMenu().map((item) => (
                            <MenuItem key={item.id} onClick={() => handleDaySelect(item.value)} disableRipple>
                                {item.label}
                            </MenuItem>
                        ))}
                    </StyledMenu>
                </Grid>

                {!shared && (
                    <Grid item xsOffset="auto">
                        <Button
                            id="change-schedule"
                            onClick={() => handleOpenComment("Request a schedule change")}
                            sx={{ ...styles.outlinedButton, width: "100%" }}
                            startIcon={<AddCircleOutlineIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                            variant="outlined">
                            Request a schedule change
                        </Button>
                    </Grid>
                )}
            </Grid>

            {getActivities().map((item) => (
                <div>
                    {item.recordTypeName === "Flight" && (
                        <ActivityCard
                            label="Flight"
                            icon={<FlightIcon fontSize="large" sx={{ color: config.primaryColor }}></FlightIcon>}
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Departure"
                                            type="FlightDeparture"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}
                                            location={item.departureAirportCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="Arrival"
                                            type="FlightArrival"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}
                                            location={item.arrivalAirportCode}></DisplayField>
                                    </Box>
                                </>
                            }
                            rightElement={
                                <>
                                    {/* <Typography variant='h6'>Flight Details</Typography> */}
                                    {item.flightNumber && (
                                        <Box sx={{ mb: "10px" }}>
                                            {item.carrier && <Typography variant="overline">{item.carrier}</Typography>}
                                            <Typography variant="h6">
                                                {item.carrierCode} {item.flightNumber}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.seat && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="subtitle2">Seats</Typography>
                                            <Box>{item.seat}</Box>
                                        </Box>
                                    )}

                                    {item.confirmation && (
                                        <Box>
                                            <b>Confirmation #</b>

                                            <Box>{item.confirmation}</Box>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                    {item.recordTypeName === "Ground" && (
                        <ActivityCard
                            label="Ground Transport"
                            icon={
                                <DirectionsCarIcon
                                    fontSize="large"
                                    sx={{ color: config.primaryColor }}></DirectionsCarIcon>
                            }
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Departure"
                                            type="DateTime"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="Arrival"
                                            type="DateTime"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}></DisplayField>
                                    </Box>
                                    {item.locationNotes && (
                                        <Box>
                                            <DisplayField
                                                label="Location"
                                                type="Location"
                                                location={item.locationNotes}></DisplayField>
                                        </Box>
                                    )}
                                </>
                            }
                            rightElement={
                                <>
                                    {item.vendorName && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Vendor</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.vendorName}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.arrangedBy && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Arranged By</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.arrangedBy}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.driverInformation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Driver Information</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.driverInformation}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.vehicleInformation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Vehicle Information</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.vehicleInformation}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.confirmation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Confirmation #</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.confirmation}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                    {item.recordTypeName === "Location" && (
                        <ActivityCard
                            label="Location"
                            icon={<PlaceIcon fontSize="large" sx={{ color: config.primaryColor }}></PlaceIcon>}
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Start"
                                            type="DateTime"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="End"
                                            type="DateTime"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}></DisplayField>
                                    </Box>
                                    {item.venue && (
                                        <Box>
                                            <DisplayField
                                                label="Venue"
                                                type="Location"
                                                location={item.venue}></DisplayField>
                                        </Box>
                                    )}
                                    {item.hotel && (
                                        <Box>
                                            <DisplayField
                                                label="Hotel"
                                                type="Location"
                                                location={item.hotel}></DisplayField>
                                        </Box>
                                    )}
                                </>
                            }
                            rightElement={
                                <>
                                    {item.confirmation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Confirmation #</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.confirmation}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.venue && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Venue Address</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.venueStreet && <span>{item.venueStreet}</span>}
                                                <br />
                                                {item.venueStreet2 && (
                                                    <Box>
                                                        <span>{item.venueStreet2}</span>
                                                        <br />
                                                    </Box>
                                                )}
                                                {item.venueCity && (
                                                    <Box>
                                                        <span>
                                                            {item.venueCity}, {item.venueState} {item.venueZipCode}
                                                        </span>
                                                        <br />
                                                    </Box>
                                                )}
                                                {item.venueCountry && (
                                                    <Box>
                                                        <span>{item.venueCountry}</span>
                                                        <br />
                                                    </Box>
                                                )}
                                            </Typography>

                                            <Button
                                                sx={{ mt: "10px" }}
                                                startIcon={<DirectionsIcon fontSize="small" />}
                                                href={`https://www.google.com/maps/dir/?api=1&destination=${urlEncodeAddress(
                                                    item,
                                                    "venue"
                                                )}`}
                                                size="small"
                                                variant="outlined">
                                                Get Directions
                                            </Button>
                                        </Box>
                                    )}

                                    {item.hotel && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Hotel Address</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.hotelStreet && <span>{item.hotelStreet}</span>}
                                                <br />
                                                {item.hotelStreet2 && (
                                                    <Box>
                                                        <span>{item.hotelStreet2}</span>
                                                        <br />
                                                    </Box>
                                                )}
                                                {item.hotelCity && (
                                                    <Box>
                                                        <span>
                                                            {item.hotelCity}, {item.hotelState} {item.hotelZipCode}
                                                        </span>
                                                        <br />
                                                    </Box>
                                                )}
                                                {item.hotelCountry && (
                                                    <Box>
                                                        <span>{item.hotelCountry}</span>
                                                        <br />
                                                    </Box>
                                                )}
                                            </Typography>
                                            <Button
                                                sx={{ mt: "10px" }}
                                                startIcon={<DirectionsIcon fontSize="small" />}
                                                href={`https://www.google.com/maps/dir/?api=1&destination=${urlEncodeAddress(
                                                    item,
                                                    "hotel"
                                                )}`}
                                                size="small"
                                                variant="outlined">
                                                Get Directions
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                    {item.recordTypeName === "Private Flight" && (
                        <ActivityCard
                            label="Private Flight"
                            icon={<FlightIcon fontSize="large" sx={{ color: config.primaryColor }}></FlightIcon>}
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Departure"
                                            type="DateTime"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="Arrival"
                                            type="DateTime"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField label="Drive time" type="Text"></DisplayField>
                                    </Box>
                                </>
                            }
                            rightElement={
                                <>
                                    {item.confirmation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Confirmation #</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.confirmation}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                    {item.recordTypeName === "Rail" && (
                        <ActivityCard
                            label="Rail"
                            icon={
                                <DirectionsRailwayIcon
                                    fontSize="large"
                                    sx={{ color: config.primaryColor }}></DirectionsRailwayIcon>
                            }
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Departure"
                                            type="DateTime"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="Arrival"
                                            type="DateTime"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField label="Drive time" type="Text"></DisplayField>
                                    </Box>
                                </>
                            }
                            rightElement={
                                <>
                                    {item.confirmation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Confirmation #</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.confirmation}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                    {item.recordTypeName === "Event Activity" && (
                        <ActivityCard
                            label="Event Activity"
                            icon={<EventIcon fontSize="large" sx={{ color: config.primaryColor }}></EventIcon>}
                            subject={item.subject}
                            detail={
                                <>
                                    <Box>
                                        <DisplayField
                                            label="Start"
                                            type="DateTime"
                                            date={item.departureStart}
                                            timezone={item.departureTimeZoneCode}
                                            location={item.locationNotes}></DisplayField>
                                    </Box>
                                    <Box>
                                        <DisplayField
                                            label="End"
                                            type="DateTime"
                                            date={item.arrivalEnd}
                                            timezone={item.arrivalTimeZoneCode}></DisplayField>
                                    </Box>
                                    {item.locationNotes && (
                                        <Box>
                                            <DisplayField
                                                label="Location"
                                                type="Location"
                                                location={item.locationNotes}></DisplayField>
                                        </Box>
                                    )}
                                </>
                            }
                            rightElement={
                                <>
                                    {item.introducer && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Introducer</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.introducer}
                                            </Typography>
                                        </Box>
                                    )}
                                    {item.moderator && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Moderator</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.moderator}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.format && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Format</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.format}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.attendee && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Attendee</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.attendee}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.metBy && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Met By</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.metBy}
                                            </Typography>
                                        </Box>
                                    )}

                                    {item.confirmation && (
                                        <Box sx={{ mb: "10px" }}>
                                            <Typography variant="overline">Confirmation #</Typography>
                                            <Typography variant="body" sx={{ display: "block" }}>
                                                {item.confirmation}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            }></ActivityCard>
                    )}
                </div>
            ))}
        </>
    );
};

const DisplayField = (props) => {
    const { label, type, text, date, timezone, location, seat } = props;
    const boxStyle = {
        display: "inline-flex",
        VerticalAlign: "text-bottom",
        BoxSizing: "inherit",
        textAlign: "left",
        AlignItems: "center",
        fontSize: "14px",
    };

    return (
        <Box sx={{ marginTop: "5px", background: "#FFFFFF", border: "1px solid #E9E9E9", borderRadius: "6px" }}>
            <Grid container>
                <Grid item xs={2} sx={{ borderRight: "1px solid #E9E9E9", padding: "0.5rem", paddingBottom: "3px" }}>
                    <Typography variant="overline" sx={{ fontSize: "14px" }}>
                        {label}
                    </Typography>
                </Grid>

                {type === "Location" && (
                    <Grid item xs={9} sx={{ padding: "0.5rem", paddingBottom: "3px" }}>
                        <Box sx={boxStyle}>
                            {location && (
                                <Box sx={{ ...boxStyle, mr: "15px", minWidth: "50px" }}>
                                    <LocationOnIcon
                                        fontSize="small"
                                        sx={{
                                            color: config.primaryColor,
                                            width: "15px",
                                            mt: "-1px",
                                            mr: "3px",
                                        }}></LocationOnIcon>
                                    <span>{location}</span>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                )}

                {type === "FlightDeparture" && (
                    <Grid item xs={9} sx={{ padding: "0.5rem", paddingBottom: "3px" }}>
                        <Box sx={boxStyle}>
                            {location && (
                                <Box sx={{ ...boxStyle, mr: "15px", minWidth: "50px" }}>
                                    <FlightTakeoffIcon
                                        fontSize="small"
                                        sx={{
                                            color: config.primaryColor,
                                            width: "15px",
                                            mt: "-1px",
                                            mr: "3px",
                                        }}></FlightTakeoffIcon>
                                    <span>{location}</span>
                                </Box>
                            )}

                            <CalendarMonthIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></CalendarMonthIcon>
                            {date && <span>{format(new Date(date), "MMM dd, yyyy")}</span>}

                            <AccessTimeIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    ml: "1rem",
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></AccessTimeIcon>
                            {date && <span>{format(new Date(date), "h:mm a")}</span>}

                            <span style={{ marginLeft: "5px" }}>{timezone}</span>
                        </Box>
                    </Grid>
                )}
                {type === "FlightArrival" && (
                    <Grid item xs={9} sx={{ padding: "0.5rem", paddingBottom: "3px" }}>
                        <Box sx={boxStyle}>
                            {location && (
                                <Box sx={{ ...boxStyle, mr: "15px", minWidth: "50px" }}>
                                    <FlightLandIcon
                                        fontSize="small"
                                        sx={{
                                            color: config.primaryColor,
                                            width: "15px",
                                            mt: "-1px",
                                            mr: "3px",
                                        }}></FlightLandIcon>
                                    <span>{location}</span>
                                </Box>
                            )}

                            <CalendarMonthIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></CalendarMonthIcon>
                            {date && <span>{format(new Date(date), "MMM dd, yyyy")}</span>}

                            <AccessTimeIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    ml: "1rem",
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></AccessTimeIcon>
                            {date && <span>{format(new Date(date), "h:mm a")}</span>}

                            <span style={{ marginLeft: "5px" }}>{timezone}</span>
                        </Box>
                    </Grid>
                )}

                {type === "DateTime" && (
                    <Grid item xs={9} sx={{ padding: "0.5rem", paddingBottom: "3px" }}>
                        <Box sx={boxStyle}>
                            {/* {location && <Box sx={{ mr: '10px' }}>
                                <LocationOnIcon fontSize='small' sx={{ color: config.primaryColor, width: '15px', mt: '-1px', mr: '3px' }}></LocationOnIcon>
                                <span>{location}</span></Box>} */}

                            <CalendarMonthIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></CalendarMonthIcon>
                            {date && <span>{format(new Date(date), "MMM dd, yyyy")}</span>}

                            <AccessTimeIcon
                                fontSize="small"
                                sx={{
                                    color: config.primaryColor,
                                    ml: "1rem",
                                    width: "15px",
                                    mt: "-1px",
                                    mr: "3px",
                                }}></AccessTimeIcon>
                            {date && <span>{format(new Date(date), "h:mm a")}</span>}

                            <span style={{ marginLeft: "5px" }}>{timezone}</span>
                        </Box>
                    </Grid>
                )}
                {type === "Text" && (
                    <Grid item xs={9} sx={{ padding: "0.5rem" }}>
                        <Box sx={boxStyle}>
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

function urlEncodeAddress(item, type) {
    if (type === "venue") {
        return encodeURI(`${item.venueStreet},${item.venueCity},${item.venueState} ${item.venueCountry}`);
    } else if (type === "hotel") {
        return encodeURI(`${item.hotelStreet},${item.hotelCity},${item.hotelState} ${item.hotelCountry}`);
    }
}

const ActivityCard = (props) => {
    const { label, icon, subject, detail, rightElement } = props;

    return (
        <Box sx={{ marginTop: "1rem", background: "#FAFAF9", border: "1px solid #E9E9E9", borderRadius: "10px" }}>
            <Grid container>
                <Grid sx={{ p: ".75rem", margin: "auto" }} xs={12} md={2}>
                    <Box sx={{ textAlign: "center" }}>{icon}</Box>
                    <Box sx={{ textAlign: "center" }}>{label}</Box>
                </Grid>
                <Grid
                    sx={{ p: ".75rem", borderRight: "1px solid #E9E9E9", borderLeft: "1px solid #E9E9E9" }}
                    xs={12}
                    md={7}>
                    <Typography sx={styles.cardHeading}>{subject}</Typography>
                    <Box sx={{ mt: "10px" }}>{detail}</Box>
                </Grid>
                <Grid sx={{ p: ".75rem" }} xs={12} md={3}>
                    {rightElement}
                </Grid>
            </Grid>
        </Box>
    );
};
