import { DragDropFile } from "../DragDropFile";
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { getFileUrl } from "../../services/s3";
import { UploadFilesToS3 } from "../UploadFilesToS3";
import { query, save, deleteRecord } from "../../services/object";
import {
    Box,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Divider,
    Typography,
    Button,
    IconButton,
    Breadcrumbs,
    Link,
    Menu,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { config, styles } from "../../style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { iconMapping } from "./iconMapping";

import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { format } from "date-fns";
import { DocumentCard } from "./DocumentCard";
import { styled, alpha } from "@mui/material/styles";
import { AlertDialog } from "../AlertDialog";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export const DocumentUpload = forwardRef((props, ref) => {
    const {
        parentId,
        userDetails,
        onChangeFolder,
        disableIntialLoading,
        onFileChange,
        disabledFileDisplay,
        onFileStatusChange,
        onDropZoneChange,
    } = props;
    const [documents, setDocumentsData] = useState([]);
    const dragDropFileRef = useRef(null);
    const uploadFilesToS3Ref = useRef(null);
    const [initialLoading, setInitialLoading] = useState(false);
    const [debounce, setDebounce] = useState(null);
    const [displayDropZone, setDisplayDropZone] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [seletedItemToDelete, setSeletedItemToDelete] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const documentsRef = useRef(documents);

    useImperativeHandle(ref, () => ({
        uploadFile() {
            dragDropFileRef.current.uploadFile();
        },

        saveFolder(folderName) {
            handleSaveFolder(folderName);
        },

        setSearchTextValue(value) {
            setSearchText(value);
        },
    }));

    useEffect(() => {
        document.addEventListener("dragenter", handleDrag);
        document.addEventListener("dragleave", handleDrag);
        document.addEventListener("dragover", handleDrag);
        document.addEventListener("mouseup", handleDrag);
        document.addEventListener("drop", handleDrag);
        document.addEventListener("dragend", handleDrag);
        if (!disableIntialLoading) {
            getDocuments();
            console.log(documents);
        }

        return () => {
            document.removeEventListener("dragenter", handleDrag);
            document.removeEventListener("dragleave", handleDrag);
            document.removeEventListener("dragover", handleDrag);
            document.removeEventListener("mouseup", handleDrag);
            document.removeEventListener("drop", handleDrag);
            document.removeEventListener("dragend", handleDrag);
        };
    }, []);

    useEffect(() => {
        setDocumentReferance([]);
        console.log(parentId);
        if (!disableIntialLoading) {
            getDocuments();
        }
    }, [parentId]);

    const handleDrag = function (event) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event.type);
        let display = false;
        if (event.type === "dragenter" || event.type === "dragover") {
            display = true;
        } else if (event.type === "dragleave") {
            display = false;
        } else if (event.type === "mouseup") {
            display = false;
        } else if (event.type === "drop") {
            display = false;
        }

        if (onDropZoneChange) {
            onDropZoneChange(display);
        }
        setDisplayDropZone(display);

        // if (documentsRef.current.length === 0) {
        //     setDisplayDropZone(false);
        // }
    };

    const getDocuments = () => {
        const result = query("document", "getRelatedRecords", { parentId: parentId }).then((result) => {
            setInitialLoading(false);
            if (result.isSuccess) {
                let documentData = result.data;
                if (documentData.length === 0) {
                    setDisplayDropZone(true);
                }
                let sortedDocument = documentData.sort((p1, p2) =>
                    p1.createdDate < p2.createdDate ? 1 : p1.createdDate > p2.createdDate ? -1 : 0
                );
                setDocumentReferance([...sortedDocument]);
            }
        });
    };

    const getRecord = (file, documentType) => {
        return {
            parentId: file.parentId ? file.parentId : parentId,
            name: file.name,
            fileId: file.fileId,
            height: file.height,
            isMultipartUpload: file.isMultipartUpload,
            size: file.size,
            thumbnail: file.thumbnail,
            thumbnailContentType: file.thumbnailContentType,
            thumbnailVideo: file.thumbnailVideo,
            type: documentType === "folder" ? "Folder" : file.type,
            width: file.width,
            createdByName: userDetails?.contact?.name || userDetails?.username,
            createdById: userDetails?.contact?.id,
            documentType: documentType ? documentType : "document",
            fileName: documentType === "folder" ? file.name + ".folder" : file.fileName,
            status: file.status,
            percentage: file.percentage,
            localFileURL: file.localFileURL,
        };
    };

    const handleFileChange = async (files) => {
        setDisplayDropZone(false);
        if (onDropZoneChange) {
            onDropZoneChange(false);
        }
        if (onFileChange) {
            files = await onFileChange(files);
            setTimeout(() => {
                uploadFilesToS3Ref.current.startUpload(files);
            }, 100);
        } else {
            uploadFilesToS3Ref.current.startUpload(files);
        }
    };

    const saveRecord = (record) => {
        save("document", record).then((result) => {
            if (result.isSuccess) {
                let findRecord = false;
                let records = documentsRef.current.map((item) => {
                    if (!item.id && item.name === result.data?.name) {
                        findRecord = true;
                        return { ...record, ...result.data };
                    }
                    return item;
                });
                if (!findRecord) {
                    setDocumentReferance([{ ...record, ...result.data }, ...documentsRef.current]);
                } else {
                    setDocumentReferance([...records]);
                }
                if (onFileStatusChange) {
                    onFileStatusChange(documentsRef.current);
                }
            }
        });
    };

    const handleSaveFolder = (folderName) => {
        let file = getRecord({ name: folderName, fileName: folderName }, "folder");
        saveRecord(file);
    };

    const handleFileStatusChange = (file) => {
        setDisplayDropZone(false);
        if (onDropZoneChange) {
            onDropZoneChange(false);
        }
        file = getRecord(file, "document");
        if (file.status === "success") {
            saveRecord(file);
        } else {
            let findRecord = false;
            let data = documentsRef.current.map((item) => {
                if (!item.id && item.name === file?.name) {
                    findRecord = true;
                    return file;
                }
                return item;
            });
            if (!findRecord) {
                setDocumentReferance([{ ...file }, ...documentsRef.current]);
            } else {
                setDocumentReferance([...data]);
            }

            if (onFileStatusChange) {
                onFileStatusChange(documentsRef.current);
            }
        }
    };

    const setDocumentReferance = (value) => {
        setDocumentsData(value);
        documentsRef.current = value;
    };

    const getExtension = (name) => {
        if (name) {
            return name.substring(name.lastIndexOf(".") + 1);
        }
        return "";
    };

    const getIcon = (fileName) => {
        const extension = getExtension(fileName);
        const icon = iconMapping[extension]?.icon;
        return icon ? icon : faFile;
    };

    const getColor = (fileName) => {
        const extension = getExtension(fileName);
        const color = iconMapping[extension]?.color;
        return color ? color : "#413ab4";
    };

    const handleCardClick = (item) => {
        if (item.documentType === "folder") {
            onChangeFolder({ id: item.id, name: item.name });
        }
    };

    const handleDownload = async (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        const { isSuccess, data } = await getFileUrl({ fileId: item.fileId });
        if (isSuccess) {
            downloadFile(data, item.name);
        }
    };

    const downloadFile = (downloadUrl, name) => {
        const downloadContainer = document.querySelector(".download-container");
        const fileName = name;

        let a = document.createElement("a");
        a.href = downloadUrl;
        a.target = "_blank";
        // Use a.download if available, it prevents plugins from opening.
        a.download = fileName;
        // Add a to the doc for click to work.
        if (downloadContainer) {
            downloadContainer.appendChild(a);
        }
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        }
        // Delete the temporary link.
        downloadContainer.removeChild(a);
    };

    const handleDeleteClick = (item) => {
        setSeletedItemToDelete(item);
        setOpenConfirmModal(true);
    };

    const cardComponent = (item) => {
        return (
            <DocumentCard
                onDeleteClick={handleDeleteClick}
                getExtension={getExtension}
                item={item}
                onCardClick={handleCardClick}
                getIcon={getIcon}
                getColor={getColor}
                onDownload={handleDownload}
                CircularProgressWithLabel={CircularProgressWithLabel}
            ></DocumentCard>
        );
    };

    const getFiles = (documents, type) => {
        if (documents && documents.length > 0) {
            return documents?.filter(
                (item) =>
                    item.documentType === type &&
                    (!searchText ||
                        item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                        item.type.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            );
        }
        return [];
    };

    const handleDisagree = () => {
        setOpenConfirmModal(false);
        setSeletedItemToDelete(null);
    };
    const handleAgree = async () => {
        const { isSuccess, data } = await deleteRecord("document", seletedItemToDelete);
        if (isSuccess) {
            setOpenConfirmModal(false);

            let filteredData = documentsRef.current.filter((item) => item.id !== seletedItemToDelete.id);
            setDocumentReferance([...filteredData]);
            setSeletedItemToDelete(null);
        }
    };

    return (
        <div>
            <Box sx={{ display: displayDropZone ? "block" : "none" }}>
                <DragDropFile ref={dragDropFileRef} onFileChange={handleFileChange}></DragDropFile>
            </Box>

            <UploadFilesToS3
                ref={uploadFilesToS3Ref}
                parentId={parentId}
                onStatusChange={handleFileStatusChange}
            ></UploadFilesToS3>

            {!disabledFileDisplay && (
                <>
                    {getFiles(documents, "folder").length > 0 && (
                        <Box>
                            <Grid container spacing={2}>
                                {getFiles(documents, "folder")?.map((item) => cardComponent(item))}
                            </Grid>
                            <Divider sx={{ my: "15px" }}></Divider>
                        </Box>
                    )}

                    <Box>
                        <Grid container spacing={2}>
                            {getFiles(documents, "document")?.map((item) => cardComponent(item))}
                        </Grid>
                    </Box>
                    <div className="download-container"></div>
                </>
            )}

            <AlertDialog
                title="Confirm"
                message="Are you sure, you want to delete this document/folder"
                open={openConfirmModal}
                onDisagree={handleDisagree}
                onAgree={handleAgree}
            ></AlertDialog>
        </div>
    );
});
