import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { getUploadURL, getMultipartUpload, getUploadPart, completePartUpload, uploadToS3 } from "../../services/s3";

const CHUNK_SIZE = 5 * 1024 * 1024;

export const UploadFilesToS3 = forwardRef((props, ref) => {
    const { parentId, onStatusChange } = props;

    useImperativeHandle(ref, () => ({
        startUpload(files) {
            saveFile(files);
        },
    }));

    const getStatusDetail = (file, status, isMultipartUpload, percentage) => {
        return {
            name: file.name,
            size: file.size,
            type: file.type,
            status: status,
            isMultipartUpload: isMultipartUpload,
            percentage: percentage,
            parentId: file.parentId,
            localFileURL: URL.createObjectURL(file),
        };
    };

    const updateStatus = (file, status, message, exact) => {
        onStatusChange(
            exact
                ? { ...file, fileName: file.name, status: status, message: message }
                : {
                      name: file.name,
                      fileName: file.name,
                      size: file.size,
                      type: file.type,
                      status: status,
                      message: message,
                      parentId: file.parentId,
                      localFileURL: file.localFileURL ? file.localFileURL : URL.createObjectURL(file),
                  }
        );
    };

    const videoDimension = async (file) => {
        if (file && file.type?.startsWith("video")) {
            const promise = new Promise((resolve, reject) => {
                let blobURL = URL.createObjectURL(file);
                let videoTagRef = this.template.querySelector(".uploaded-video");
                videoTagRef.src = blobURL;

                videoTagRef.addEventListener("loadedmetadata", function (e) {
                    resolve({
                        width: videoTagRef.videoWidth,
                        height: videoTagRef.videoHeight,
                        duration: videoTagRef.duration,
                    });
                });
            });
            return promise;
        }
    };

    const imageDimension = async (file) => {
        if (file && file.type?.startsWith("image")) {
            var _URL = window.URL || window.webkitURL;
            let img = new Image();
            const promise = new Promise(async (resolve, reject) => {
                img.onload = function () {
                    resolve({ width: this.width, height: this.height });
                };
                img.onerror = function () {
                    alert("not a valid file: " + file.type);
                };
                img.src = await getImageURL(_URL, file);
            });

            return promise;
        }
    };

    const getImageURL = async (_URL, file) => {
        const promise = new Promise(async (resolve, reject) => {
            if (file.type === "image/svg+xml") {
                const reader = new FileReader();
                reader.onload = function (evt) {
                    // console.log("onload: ", evt.target.result);
                    // let blob = new Blob([evt.target.result], {type: 'image/svg+xml'});

                    let base64SVG = btoa(unescape(encodeURIComponent(evt.target.result)));
                    let url = "data:image/svg+xml;base64," + base64SVG;
                    //let url = _URL.createObjectURL(blob);
                    resolve(url);
                };
                reader.readAsText(file);
            } else {
                resolve(_URL.createObjectURL(file));
            }
        });
        return promise;
    };

    const uploadPart = async (file, multipart) => {
        let chunkCount = Math.floor(file.size / CHUNK_SIZE) + 1;
        console.log("chunkCount", chunkCount);

        let multiUploadArray = [];
        let percentageSize = 98 / chunkCount;
        for (let uploadCount = 1; uploadCount < chunkCount + 1; uploadCount++) {
            let start = (uploadCount - 1) * CHUNK_SIZE;
            let end = uploadCount * CHUNK_SIZE;
            let fileBlob = uploadCount < chunkCount ? file.slice(start, end) : file.slice(start);

            let getSignedUrlResult = await getUploadPart({
                fileName: multipart.Key,
                partNumber: uploadCount,
                uploadId: multipart.UploadId,
            });
            if (getSignedUrlResult.isSuccess) {
                let preSignedUrl = getSignedUrlResult.data;
                let uploadChunk = await fetch(preSignedUrl, {
                    method: "PUT",
                    body: fileBlob,
                });
                console.log(uploadChunk);
                let EtagHeader = uploadChunk.headers.get("ETag");
                console.log(EtagHeader);
                let uploadPartDetails = {
                    ETag: EtagHeader,
                    PartNumber: uploadCount,
                };
                onStatusChange({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    status: "started",
                    isMultipartUpload: true,
                    percentage: percentageSize * uploadCount,
                    parentId: file.parentId,
                    localFileURL: file.localFileURL ? file.localFileURL : URL.createObjectURL(file),
                });

                multiUploadArray.push(uploadPartDetails);
            }
        }
        console.log(multiUploadArray);
        const completeUploadData = await completePartUpload({
            fileName: multipart.Key,
            parts: multiUploadArray,
            uploadId: multipart.UploadId,
        });
        if (completeUploadData.isSuccess) {
            let fileProperties = {};
            // if (file && file.type?.startsWith('image')) {
            //     fileProperties = await imageDimension(file);
            // }
            // else if (file && file.type?.startsWith('video')) {
            //     fileProperties = await videoDimension(file);
            // }
            completeUpload(
                {
                    ...fileProperties,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    s3URL: completeUploadData.data.Location,
                    fileId: completeUploadData.data.Key,
                    isMultipartUpload: true,
                    parentId: file.parentId,
                    localFileURL: file.localFileURL ? file.localFileURL : URL.createObjectURL(file),
                },
                true
            );
        }
    };

    const uploadFile = (file) => {
        try {
            let isMultipartUpload = file.size > CHUNK_SIZE ? true : false;
            let param = { name: file.name, type: file.type };
            if (!isMultipartUpload) {
                getUploadURL(param).then((result) => {
                    if (result.isSuccess) {
                        uploadToS3(result.data, file).then((resultS3) => {
                            let s3URL = result.data.split("?")[0];
                            let splittedURL = s3URL.split("/");
                            let fileId = decodeURIComponent(splittedURL[splittedURL.length - 1]);
                            let fileProperties = {};
                            // if (file && file.type?.startsWith('image')) {
                            //     fileProperties = await imageDimension(file);
                            // }
                            // else if (file && file.type?.startsWith('video')) {
                            //     fileProperties = await videoDimension(file);
                            // }

                            completeUpload(
                                {
                                    ...fileProperties,
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    s3URL: s3URL,
                                    fileId: fileId,
                                    isMultipartUpload: isMultipartUpload,
                                    parentId: file.parentId,
                                    localFileURL: file.localFileURL ? file.localFileURL : URL.createObjectURL(file),
                                },
                                true
                            );
                        });
                    }
                });
            } else {
                getMultipartUpload(param).then((result) => {
                    if (result.isSuccess) {
                        uploadPart(file, result.data);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            completeUpload(file, false, error.message);
        }
    };

    const completeUpload = (file, isSuccess, message) => {
        updateStatus(file, isSuccess ? "success" : "failed", message, true);
    };

    const saveFile = (files) => {
        let maxSize = files.length;
        for (let index = 0; index < maxSize; index++) {
            const file = files[index];
            let isMultipartUpload = file.size > CHUNK_SIZE ? true : false;
            if (!isMultipartUpload) {
                updateStatus(file, "started");
            } else {
                onStatusChange(getStatusDetail(file, "started", isMultipartUpload, 2));
            }
            uploadFile(file);
        }
    };

    return (
        <div>
            <video style={{ display: "none" }} class="uploaded-video slds-hide"></video>
        </div>
    );
});
