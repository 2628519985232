import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import { useNavigate } from "react-router-dom";

export const NavItem = (props) => {
  const { href, icon, title, tab, id, ...others } = props;
  let active = tab === id;
  const navigate = useNavigate();

  const clickLink = () => {
    navigate(href, { replace: false });
  } 
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
      }}
      {...others}
    >
      <span
        onClick={clickLink}

        style={{ width: '100%' }}
      >
        <Button
          component="a"
          startIcon={icon}
          disableRipple
          sx={{
            backgroundColor: active && 'rgba(255,255,255, 0.08)',
            borderRadius: '4px',
            color: active ? 'white' : 'neutral.300',
            fontWeight: active && 'fontWeightBold',
            justifyContent: 'flex-start',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            px: 4,
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400'
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
        </Button>
      </span>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  tab: PropTypes.string,
  id: PropTypes.string
};
