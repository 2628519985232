import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Typography, MenuItem, Menu, Chip, IconButton, Divider, Skeleton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { config, styles } from "../../style";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentIcon from "@mui/icons-material/Comment";
import CheckIcon from "@mui/icons-material/Check";
import DvrIcon from "@mui/icons-material/Dvr";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../hooks/useAuth";
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export const SpeakerCard = (props) => {
    const { record, speakerLists, listBySpeaker, commentBySpeaker, noteBySpeaker, availabilityCheck } = props;
    const [saveAnchorEl, setSaveAnchorEl] = React.useState(null);
    const [otherAnchorEl, setOtherAnchorEl] = React.useState(null);
    const saveOption = Boolean(saveAnchorEl);
    const otherOption = Boolean(otherAnchorEl);
    const { getUserDetailsNotAsync } = useAuth();
    const userDetails = getUserDetailsNotAsync();

    const availabilityCheckGivenDateTimestamp = availabilityCheck ? new Date(availabilityCheck).getTime() : 0;

    const getImageURL = () => {
        return record?.speakerPortalImage ? record?.speakerPortalImage : record?.speakerImagePublicURL;
    };

    const handleSaveMenuClick = (event) => {
        setSaveAnchorEl(event.currentTarget);
    };

    const handleSaveMenuClose = (filterValue, value) => {
        if (filterValue) {
            if (filterValue === "create-list") {
                const createEvent = new CustomEvent("create", {
                    detail: {
                        value: record,
                    },
                });
                document.dispatchEvent(createEvent);
            }
            if (filterValue === "add-speaker") {
                if (alreadyExistInList(value)) {
                    const createEvent = new CustomEvent("removeSpeaker", {
                        detail: {
                            value: record,
                            listId: value,
                        },
                    });
                    document.dispatchEvent(createEvent);
                } else {
                    const createEvent = new CustomEvent("addSpeaker", {
                        detail: {
                            value: record,
                            listId: value,
                        },
                    });
                    document.dispatchEvent(createEvent);
                }
            }
        }
        setSaveAnchorEl(null);
    };

    const handleOtherMenuClick = (event) => {
        setOtherAnchorEl(event.currentTarget);
    };

    const handleOtherMenuClose = (filterValue) => {
        if (filterValue === "leave-comment") {
            handleOpenComment(record.Id);
        } else if (filterValue === "view-profile") {
            const createEvent = new CustomEvent("viewProfile", {
                detail: {
                    value: record,
                },
            });
            document.dispatchEvent(createEvent);
        } else if (filterValue === "leave-note") {
            const createEvent = new CustomEvent("leaveNote", {
                detail: {
                    value: record,
                    note: getNote(),
                },
            });
            document.dispatchEvent(createEvent);
        }
        setOtherAnchorEl(null);
    };

    const getCategory = () => {
        if (record?.mainCategory) {
            return record.mainCategory?.map((item, index) => (
                <>
                    <span style={{ color: "#444CE7" }}>{item}</span>
                    {index !== record.mainCategory.length - 1 && <span>, </span>}
                </>
            ));
        }
        return <></>;
    };

    const getListCount = (speakerId) => {
        return listBySpeaker && listBySpeaker[speakerId] ? listBySpeaker[speakerId].length : 0;
    };

    const alreadyExistInList = (listId) => {
        return listBySpeaker && listBySpeaker[record.Id] && listBySpeaker[record.Id].indexOf(listId) > -1
            ? true
            : false;
    };

    const handleOpenComment = (recordId) => {
        const event = new CustomEvent("openDrawer", {
            detail: { parentId: recordId, parentName: record.Name },
        });
        document.dispatchEvent(event);
    };

    const getCommentCount = () => {
        return commentBySpeaker && commentBySpeaker[record.Id] ? commentBySpeaker[record.Id].length : 0;
    };

    const getNote = () => {
        return noteBySpeaker && noteBySpeaker[record.Id] ? noteBySpeaker[record.Id] : undefined;
    };

    const handleEditNote = () => {
        const createEvent = new CustomEvent("leaveNote", {
            detail: {
                value: record,
                note: getNote(),
            },
        });
        document.dispatchEvent(createEvent);
    };

    const getAvailability = () => {
        if (availabilityCheckGivenDateTimestamp > 0 && record?.calendarEntries?.length > 0) {
            let find = record.calendarEntries.find((item) => {
                if (item.startDateTimestanp > 0 && item.endDateTimestanp > 0) {
                    return (
                        availabilityCheckGivenDateTimestamp >=
                            Math.min(item.startDateTimestanp, item.endDateTimestanp) &&
                        availabilityCheckGivenDateTimestamp <= Math.max(item.startDateTimestanp, item.endDateTimestanp)
                    );
                } else if (item.startDateTimestanp > 0) {
                    return availabilityCheckGivenDateTimestamp === item.startDateTimestanp;
                } else if (item.endDateTimestanp > 0) {
                    return availabilityCheckGivenDateTimestamp === item.endDateTimestanp;
                }
                return false;
            });
            if (find) {
                if (find.type === "BOOKED" || find.type === "PRE-RECORDING") return "BOOKED";
                return "HOLD";
            }
            return false;
        }
        return true;
    };

    return (
        <>
            <Box sx={{ padding: "15px" }}>
                <Box sx={{ display: "flex" }} justifyContent="start">
                    <Box sx={{ width: "90px" }}>
                        {getImageURL() && (
                            <Box
                                component="img"
                                sx={{
                                    objectFit: "cover",
                                    width: "70px",
                                    height: "70px",
                                    objectPosition: "top",
                                    borderRadius: "100px",
                                }}
                                src={`${getImageURL()}`}></Box>
                        )}
                        {!getImageURL() && <Skeleton variant="circular" width={70} height={70} />}
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Grid container justifyContent="space-between">
                            <Grid lg={8} item>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={styles.cardHeading}>{record.name}</Typography>
                                    {record.speakerStatus === "Exclusive" && (
                                        <Chip
                                            sx={{
                                                fontSize: "12px",
                                                height: "25px",
                                                ml: ".75rem",
                                                color: "#7A2E0E",
                                                background: "#FEF0C7",
                                            }}
                                            label="WSB Exclusive Speaker"
                                        />
                                    )}
                                </Box>
                                <Box sx={{ fontSize: "12px", mt: "5px", color: "#475467" }}>
                                    {record.promotionalTitle}
                                </Box>
                                <Box sx={{ fontSize: "12px", mt: "10px", color: "#475467" }}>{getCategory()}</Box>
                                {getAvailability() === "BOOKED" && (
                                    <Alert sx={{ mt: "20px" }} severity="error">
                                        Confirmed conflict. Reach out to your WSB agent for further clarification.
                                    </Alert>
                                )}

                                {getAvailability() === "HOLD" && (
                                    <Alert sx={{ mt: "20px" }} severity="warning">
                                        Potential conflict - reach out to your WSB agent for further clarification.
                                    </Alert>
                                )}

                                {getNote() && getNote().description && (
                                    <Box
                                        sx={{
                                            border: "1px solid #d0d5dd",
                                            borderRadius: "8px",
                                            position: "relative",
                                            mt: "20px",
                                            p: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleEditNote}>
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: "-10px",
                                                left: "10px",
                                                background: "white",
                                                width: "220px",
                                                paddingLeft: "8px",
                                                color: "#e86745",
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                            <b>Your notes about this speaker</b>
                                            <Tooltip title="This is a private note only visible to you and your WSB Agent.">
                                                <IconButton sx={{ padding: "0px", ml: "5px" }}>
                                                    <InfoIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Typography
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "2",
                                                WebkitBoxOrient: "vertical",
                                                fontSize: "12px",
                                                color: "#e86745",
                                                p: "10px",
                                            }}>
                                            {getNote().description}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                            <Grid lg={2} item sx={{ textAlign: "right" }}>
                                <Box sx={{ fontSize: "14px", color: "#1D2939" }}>
                                    {record.feeCode ? record.feeCode : "Contact WSB"}
                                </Box>
                            </Grid>
                            <Grid>
                                <Button
                                    id="filter-speakers"
                                    aria-controls={saveOption ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={saveOption ? "true" : undefined}
                                    onClick={handleSaveMenuClick}
                                    endIcon={<ExpandMoreIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                                    sx={{ ...styles.outlinedButton, height: "33.5px" }}
                                    variant="outlined">
                                    {"Save"}
                                </Button>
                                <Menu
                                    sx={{ width: "300px" }}
                                    id="basic-menu"
                                    anchorEl={saveAnchorEl}
                                    open={saveOption}
                                    onClose={() => handleSaveMenuClose("")}
                                    MenuListProps={{
                                        "aria-labelledby": "filter-speakers",
                                    }}>
                                    <MenuItem onClick={() => handleSaveMenuClose("create-list")} value="create-list">
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <PostAddIcon
                                                fontSize="small"
                                                sx={{ color: config.primaryColor, mr: "10px" }}></PostAddIcon>
                                            Create speaker list
                                        </Box>
                                    </MenuItem>
                                    {speakerLists?.length > 0 && (
                                        <>
                                            <Divider></Divider>
                                            {speakerLists.map((item) => (
                                                <MenuItem
                                                    onClick={() => handleSaveMenuClose("add-speaker", item.id)}
                                                    value={item.name}
                                                    className={alreadyExistInList(item.id) ? "selected-list" : ""}>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        sx={{ width: "100%" }}>
                                                        <Grid item sx={{ p: 0 }}>
                                                            {item.name}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                p: 0,
                                                                fontSize: "12px",
                                                                color: alreadyExistInList(item.id)
                                                                    ? "#444CE7"
                                                                    : "#667085",
                                                            }}>
                                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                <span>{item.members.length}</span>
                                                                {alreadyExistInList(item.id) && (
                                                                    <CheckIcon
                                                                        fontSize="16px"
                                                                        sx={{ ml: "8px", color: config.primaryColor }}
                                                                    />
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                    {/* <Divider></Divider>
                                    <MenuItem onClick={() => handleSaveMenuClose("Shortlisted")} value="Shortlisted">Shortlisted Speakers</MenuItem>
                                    <MenuItem onClick={() => handleSaveMenuClose("Rejected")} value="Rejected">Rejected Speakers</MenuItem> */}
                                </Menu>

                                <Button
                                    id="filter-speakers1"
                                    aria-controls={otherOption ? "basic-menu1" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={otherOption ? "true" : undefined}
                                    onClick={handleOtherMenuClick}
                                    startIcon={<MoreVertIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                                    sx={{
                                        ...styles.outlinedButton,
                                        height: "33.5px",
                                        padding: "0",
                                        minWidth: "30px",
                                        marginLeft: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    variant="outlined"></Button>
                                <Menu
                                    id="basic-menu1"
                                    anchorEl={otherAnchorEl}
                                    open={otherOption}
                                    onClose={() => handleOtherMenuClose("")}
                                    MenuListProps={{
                                        "aria-labelledby": "filter-speakers1",
                                    }}>
                                    <MenuItem onClick={() => handleOtherMenuClose("view-profile")} value="view-profile">
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <AccountCircleIcon
                                                fontSize="small"
                                                sx={{ color: config.primaryColor, mr: "10px" }}></AccountCircleIcon>
                                            View profile
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleOtherMenuClose("request-fee")} value="request-fee">
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <RequestQuoteIcon
                                                fontSize="small"
                                                sx={{ color: config.primaryColor, mr: "10px" }}></RequestQuoteIcon>
                                            Request fees
                                        </Box>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleOtherMenuClose("leave-comment")}
                                        value="leave-comment">
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <AddCommentIcon
                                                fontSize="small"
                                                sx={{ color: config.primaryColor, mr: "10px" }}></AddCommentIcon>
                                            Leave comment
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleOtherMenuClose("leave-note")} value="leave-note">
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <AddCommentIcon
                                                fontSize="small"
                                                sx={{ color: config.primaryColor, mr: "10px" }}></AddCommentIcon>
                                            {getNote() ? "Edit note" : "Leave a note"}
                                        </Box>
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>

                        {(getListCount(record.Id) > 0 || getCommentCount() > 0) && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    fontSize: "12px",
                                    pr: "8px",
                                    mt: getNote()
                                        ? "-45px"
                                        : record.promotionalTitle && record.mainCategory?.length > 0
                                        ? "-45px"
                                        : record.promotionalTitle
                                        ? "-26px"
                                        : "0px",
                                }}>
                                {getListCount(record.Id) > 0 && (
                                    <Chip
                                        icon={
                                            <DvrIcon sx={{ fontSize: "18px", p: "0px 0px 0px 4px", height: "26px" }} />
                                        }
                                        label={getListCount(record.Id)}
                                    />
                                )}
                                {getCommentCount() > 0 && (
                                    <Chip
                                        onClick={() => handleOpenComment(record.Id)}
                                        sx={{ ml: "10px" }}
                                        icon={
                                            <CommentIcon
                                                sx={{ fontSize: "18px", p: "0px 0px 0px 4px", height: "26px" }}
                                            />
                                        }
                                        label={getCommentCount()}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider></Divider>
        </>
    );
};
