import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import { Avatar, Box, Drawer, useMediaQuery } from "@mui/material";
import { NavItem } from "./NavItem";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import HelpIcon from "@mui/icons-material/Help";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import PausePresentationIcon from "@mui/icons-material/PausePresentation";
import { config } from "../../style";
import { ReactComponent as Logo } from "../../assets/images/logos/logo-wsb.svg";
import { get } from "../../api";

const clientApplicationItems = [
    {
        href: "/speaker-search",
        id: "speaker-search",
        icon: <SavedSearchIcon fontSize="medium" />,
        title: "Speaker Search",
    },
    {
        href: "/my-proposals",
        id: "my-proposals",
        icon: <CreditCardIcon fontSize="small" />,
        title: "My Proposals",
    },
    {
        href: "/events",
        id: "my-events",
        icon: <LocalActivityIcon fontSize="small" />,
        title: "My Events",
    },
    // {
    //     href: "/calendar",
    //     id: "calendar",
    //     icon: <CalendarMonthIcon fontSize="small" />,
    //     title: "Calendar",
    // },
    {
        href: "/documents",
        id: "documents",
        icon: <FindInPageIcon fontSize="small" />,
        title: "Documents",
    },
    {
        href: "/contact",
        id: "contact",
        icon: <ContactSupportOutlinedIcon fontSize="small" />,
        title: "Contact WSB",
    },
    {
        href: "/help",
        id: "help",
        icon: <HelpIcon fontSize="small" />,
        title: "Help",
    },
];

const speakerApplicationItems = [
    {
        href: "/",
        id: "my-events",
        icon: <LocalActivityIcon fontSize="small" />,
        title: "My Events",
    },
    {
        href: "/speaker-holds",
        id: "speaker-holds",
        icon: <PausePresentationIcon fontSize="small" />,
        title: "Hold Requests",
    },
    {
        href: "/help",
        id: "help",
        icon: <HelpIcon fontSize="small" />,
        title: "Help",
    },
];

export const DashboardSidebar = (props) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const { open, tab, userDetails, onClose, publicPage, application, opportunityData } = props;
    const [userImage, setUserImage] = useState(opportunityData?.opportunity?.ownerPhoto);
    const [userImageBase, setUserImageBase] = useState("");
    useEffect(() => {
        setIsImageLoaded(false);
        if (opportunityData?.opportunity?.ownerPhoto) {
            get("opportunity/getUserImage", {
                url: encodeURIComponent(opportunityData?.opportunity?.ownerPhoto),
            }).then(async (result) => {
                if (result.isSuccess) {
                    setUserImageBase(result.data);
                }
                setIsImageLoaded(true);
            });
        } else {
            setIsImageLoaded(true);
        }
    }, [userImage, opportunityData?.opportunity?.ownerPhoto]);

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
        defaultMatches: true,
        noSsr: false,
    });
    const getNavBarItems = () => {
        let pages = [];
        if (application === "speaker") {
            pages = publicPage ? speakerApplicationItems.filter((item) => item.id === tab) : speakerApplicationItems;
        } else {
            pages = publicPage ? clientApplicationItems.filter((item) => item.id === tab) : clientApplicationItems;
        }

        if (!userDetails?.contact?.eventsEnabled) {
            pages = pages.filter((item) => item.id !== "my-events");
        }

        if (!userDetails?.contact?.speakerSearchEnabled) {
            pages = pages.filter((item) => item.id !== "speaker-search");
        }

        return pages;
    };

    const content = (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}>
                <div>
                    <Box sx={{ p: 3, width: "200px", m: "auto" }}>
                        <Logo />
                    </Box>
                    <Box sx={{ px: 2 }}></Box>
                </div>

                <Box sx={{ flexGrow: 1, px: "20px" }}>
                    {getNavBarItems().map((item) => (
                        <NavItem
                            key={item.id}
                            tab={tab}
                            icon={item.icon}
                            href={item.href}
                            id={item.id}
                            title={item.title}
                            onClick={onClose}
                        />
                    ))}
                </Box>
                {opportunityData?.opportunity && (!publicPage || application === "preview") ? (
                    <Box sx={{ p: "20px" }}>
                        <Box
                            sx={{
                                textAlign: "center",
                                margin: "auto",
                                py: 2,
                                bgcolor: "rgba(250,250,250, .23)",
                                borderRadius: "4px",
                            }}>
                            {isImageLoaded && (
                                <Avatar
                                    src={userImageBase}
                                    sx={{
                                        height: 64,
                                        mb: 2,
                                        width: 64,
                                        margin: "auto",
                                    }}
                                />
                            )}
                            {!isImageLoaded && (
                                <Skeleton
                                    variant="circular"
                                    width={64}
                                    height={64}
                                    sx={{ margin: "0px auto", background: "rgba(255, 255, 255, 0.6)" }}
                                />
                            )}

                            <Box sx={{ marginTop: "0.5rem", fontSize: "16px", fontWeight: 500 }}>
                                {opportunityData?.opportunity?.ownerName}
                            </Box>
                            <Box sx={{ fontSize: "12px" }}>
                                {opportunityData?.opportunity?.ownerTitle
                                    ? opportunityData?.opportunity?.ownerTitle
                                    : "Proposal Manager"}
                            </Box>
                            <Box
                                sx={{
                                    marginTop: ".5rem",
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <MailOutlineIcon sx={{ mr: "10px" }} fontSize="small" />
                                {opportunityData?.opportunity?.ownerEmail}
                            </Box>
                            {opportunityData?.opportunity?.ownerPhone && (
                                <Box
                                    sx={{
                                        marginTop: ".5rem",
                                        fontSize: "14px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                    <PhoneIphoneOutlinedIcon sx={{ mr: "10px" }} fontSize="small" />
                                    {opportunityData?.opportunity?.ownerPhone}
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : (
                    ""
                )}
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        background: config.sidebarColor,
                        color: "#FFFFFF",
                        width: 280,
                    },
                }}
                variant="permanent">
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    background: config.sidebarColor,
                    color: "#FFFFFF",
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary">
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
