import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Box } from "@mui/material";
import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    HeadingLevel,
    ExternalHyperlink,
    AlignmentType,
    Table,
    WidthType,
    TableRow,
    TableCell,
    BorderStyle,
    Header,
    NumberFormat,
    SectionType,
    Footer,
} from "docx";

import blankImage from "../../assets/images/user.png";

const ProposalDocxGenerator = forwardRef((props, ref) => {
    const speakerDetailsRef = useRef();
    const allSpeakersRef = useRef();
    const opportunityRef = useRef();
    const groupRef = useRef();
    const mapGroupsRef = useRef({});
    const mapSpeakerDetailsRef = useRef({});
    const blankProfileImageRef = useRef();
    const mapSpeakerImage = useRef({});
    const callbackRef = useRef();

    useImperativeHandle(ref, () => ({
        generateDocument(speakerDetails, allSpeakers, opportunity, callback) {
            callbackRef.current = callback;
            console.log("speakerDetails", speakerDetails);
            console.log("allSpeakers", allSpeakers);
            console.log("opportunity", opportunity);

            speakerDetailsRef.current = speakerDetails;
            allSpeakersRef.current = allSpeakers;
            opportunityRef.current = opportunity;
            let mapRecords = {};

            for (let record of allSpeakers) {
                if (!mapRecords[record.proposalGroupId]) {
                    mapRecords[record.proposalGroupId] = {
                        id: record.proposalGroupId,
                        name: record.proposalGroupName,
                        description: record.proposalGroupDescription,
                        speakers: [],
                    };
                }

                mapRecords[record.proposalGroupId].speakers.push(record);
            }
            groupRef.current = Object.values(mapRecords);
            createDocument();
        },
    }));

    const createDocument = async () => {
        let mapGroups = {};

        for (let group of groupRef.current) {
            mapGroups[group.id] = group;
        }
        mapGroupsRef.current = mapGroups;

        let speakerURLs = [];
        let hasBlankSpeakerImage = false;
        for (let speaker of allSpeakersRef.current) {
            if (!speaker.speakerImageURL) {
                hasBlankSpeakerImage = true;
            } else {
                speakerURLs.push(speaker.speakerImageURL);
            }
        }

        if (hasBlankSpeakerImage) {
            //blankProfileImageRef.current = {};
            blankProfileImageRef.current = await displayProfileImage(blankImage);
        }

        if (speakerURLs?.length > 0) {
            let promises = speakerURLs.map((url) => {
                return displayProfileImage(getThumbnail(url));
            });
            let mapRecords = {};
            await Promise.all(promises).then((results) => {
                for (let i = 0; i < results.length; i++) {
                    mapRecords[speakerURLs[i]] = results[i];
                }
            });

            mapSpeakerImage.current = mapRecords;
        }

        let mapSpeakerDetails = {};
        for (let detail of speakerDetailsRef.current) {
            if (!mapSpeakerDetails[detail.speakerId]) {
                mapSpeakerDetails[detail.speakerId] = [];
            }
            mapSpeakerDetails[detail.speakerId].push(detail);
        }

        mapSpeakerDetailsRef.current = mapSpeakerDetails;

        let records = await getSpeakerDetails();

        const doc = new Document({
            sections: [
                {
                    headers: getHeader(),
                    properties: getDocumentProperty(),
                    footers: getFooter(),
                    children: [...getTitle(), ...records],
                },
            ],
            styles: {
                default: {
                    paragraph: {
                        run: {
                            size: 22,
                            color: "253452",
                            fontFamily: "Calibri",
                        },
                    },
                    listParagraph: {
                        run: {
                            size: 22,
                            color: "253452",
                            fontFamily: "Calibri",
                        },
                    },
                },
                paragraphStyles: [
                    {
                        name: "Normal",
                        run: {
                            size: 22,
                            color: "253452",
                            bold: true,
                            font: "Calibri",
                        },
                    },
                ],
            },
        });

        // Used to export the file into a .docx file
        Packer.toBase64String(doc).then((textBlob) => {
            const downloadContainer = document.querySelector(".download-container");
            const downloadUrl =
                "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + textBlob;
            const fileName = `${opportunityRef.current.clientName}_${opportunityRef.current.name}.docx`;

            let a = document.createElement("a");
            a.href = downloadUrl;
            a.target = "_blank";
            // Use a.download if available, it prevents plugins from opening.
            a.download = fileName;
            // Add a to the doc for click to work.
            if (downloadContainer) {
                downloadContainer.appendChild(a);
            }
            if (a.click) {
                a.click(); // The click method is supported by most browsers.
            }
            // Delete the temporary link.
            downloadContainer.removeChild(a);

            callbackRef.current();
        });
    };

    const getTitle = () => {
        return [
            new Paragraph({
                children: [
                    new TextRun({
                        children: [`WSB x ${opportunityRef.current.clientName} | ${opportunityRef.current.eventName}`],
                        font: "Calibri",
                        bold: false,
                        color: "1A2847",
                        size: 36,
                    }),
                ],
                heading: HeadingLevel.HEADING_3,
                alignment: AlignmentType.LEFT,
                spacing: {
                    after: 300,
                    before: 100,
                },
            }),
            ...displayRichtextArea(
                opportunityRef.current.proposalIntroduction,
                {
                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.LEFT,
                    thematicBreak: true,
                    spacing: {
                        after: 200,
                    },
                },
                { color: "0084D4", size: 26 }
            ),
        ];
    };

    const getFooter = () => {
        return {
            default: new Footer({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        heading: HeadingLevel.HEADING_3,
                        children: [
                            new TextRun({
                                children: [`833.972.8255  |  WWW.WSB.COM`],
                                size: 12,
                                color: "158CD7",
                            }),
                        ],
                    }),
                ],
            }),
        };
    };

    const getDocumentProperty = () => {
        return {
            page: {
                pageNumbers: {
                    start: 1,
                    formatType: NumberFormat.DECIMAL,
                },
            },
            properties: {
                type: SectionType.CONTINUOUS,
            },
        };
    };

    const getHeader = () => {
        return {
            default: new Header({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        thematicBreak: true,
                        heading: HeadingLevel.HEADING_3,
                        children: [
                            new TextRun({
                                children: ["wsb"],
                                font: "Tahoma",
                                bold: true,
                                color: "253452",
                                size: 100,
                            }),
                        ],
                        spacing: {
                            after: 100,
                        },
                    }),
                ],
            }),
        };
    };

    const getSpeakerDetails = async () => {
        let records = [];

        for (let group of groupRef.current) {
            if (group.name !== "Uncategorized") {
                let titleParagraph = new Paragraph({
                    children: [
                        new TextRun({
                            children: [group.name],
                            font: "Calibri",
                            bold: true,
                            color: "1A2847",
                            size: 36,
                        }),
                    ],
                    spacing: {
                        after: 0,
                    },
                });
                records.push(titleParagraph);
                records.push(
                    displayGroupDescription(group?.description ? convertHtmlToText(group?.description) : "", false)
                );
            } else if (group.name === "Uncategorized") {
                let titleParagraph = new Paragraph({
                    children: [
                        new TextRun({
                            children: [`More Speakers to Consider`],
                            font: "Calibri",
                            bold: true,
                            color: "1A2847",
                            size: 36,
                        }),
                    ],
                    spacing: {
                        after: 200,
                    },
                });
                records.push(titleParagraph);
            }

            for (let speaker of group.speakers) {
                // records.push(new docx.Paragraph({
                //     text: '',
                //     heading: docx.HeadingLevel.HEADING_3,
                //     alignment: docx.AlignmentType.LEFT,
                //     thematicBreak: false,
                // }))
                let speakerImage = [];
                if (speaker.speakerImageURL) {
                    if (mapSpeakerImage.current[speaker.speakerImageURL]) {
                        speakerImage.push(mapSpeakerImage.current[speaker.speakerImageURL]);
                    } else {
                        let imageRecord = await displayProfileImage(getThumbnail(speaker.speakerImageURL));
                        speakerImage.push(imageRecord);
                    }
                } else {
                    speakerImage.push(blankProfileImageRef.current);
                }

                let record;
                if (mapSpeakerDetailsRef.current[speaker.speakerId]) {
                    record = mapSpeakerDetailsRef.current[speaker.speakerId].find(
                        (item) => item.type === "Biographies"
                    );
                }
                let speakerInfo = [];

                if (speaker?.wsbWebProfileURL) {
                    speakerInfo.push(displayTitleLink(`${speaker.speakerName}`, speaker.wsbWebProfileURL));
                } else {
                    speakerInfo.push(displayTitle(`${speaker.speakerName}`));
                }
                console.log(
                    `Include subheading for: ${speaker.speakerName}: ${record?.includeSubheading} : ${record?.speakerSubheading}`
                );
                if (record?.includeSubheading) {
                    speakerInfo = [
                        ...speakerInfo,
                        ...displayRichtextArea(
                            record?.speakerSubheading,
                            {
                                heading: HeadingLevel.HEADING_3,
                                thematicBreak: false,
                            },
                            {
                                font: "Calibri",
                                allCaps: true,
                                bold: true,
                                color: "808080",
                                size: 22,
                            }
                        ),
                    ];
                    speakerInfo.push(displaySubHeading(""));
                }
                if (record?.includeShortBio) {
                    speakerInfo = [
                        ...speakerInfo,
                        ...displayRichtextArea(
                            record?.biography,
                            {
                                thematicBreak: false,
                            },
                            {
                                font: "Calibri",
                                color: "000000",
                                italics: true,
                                size: 22,
                                bold: false,
                            }
                        ),
                    ];
                }

                let table = new Table({
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [...speakerImage],
                                    width: {
                                        size: 30,
                                        type: WidthType.PERCENTAGE,
                                    },
                                    borders: {
                                        top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                    },
                                }),
                                new TableCell({
                                    children: [...speakerInfo],
                                    width: {
                                        size: 70,
                                        type: WidthType.PERCENTAGE,
                                    },
                                    borders: {
                                        top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                        right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                    },
                                }),
                            ],
                        }),
                    ],
                });

                // records.push(table);

                records.push(table);
                records.push(...displayTabDetails(speaker));
            }
        }

        return records;
    };

    const displayTabDetails = (speaker) => {
        return [
            ...displayAgentNotes(speaker.speakerId, speaker.speakerName),
            ...displayFeeDetails(speaker.speakerId),
            ...displayVideo(speaker.speakerId),
            ...displaySpeechTopics(speaker.speakerId),
        ];
    };

    const displaySpeechTopics = (speakerId) => {
        let topicRecords = getTabContent(speakerId, "Speech Topics");
        let records = [];

        if (topicRecords.length > 0) {
            records = [displayHeadingForSpeakerDetails("Speech Topics", false)];
            let index = 0;
            for (let topic of topicRecords) {
                records.push(displayParagraph(topic.topicName, true));
                records = [
                    ...records,
                    ...displayRichtextArea(
                        topic.topicDescription,
                        {
                            indent: {
                                left: 720,
                            },
                        },
                        {
                            font: "Calibri",
                            color: "000000",
                            size: 24,
                            bold: false,
                        }
                    ),
                ];
                records.push(
                    new Paragraph({
                        children: [], // Just newline without text
                    })
                );
            }
        } else {
            // records.push(displayParagraph('No speech topics found, please contact to agent for more information.'))
        }

        records.push(displayDividerEndOfSpeaker());

        return records;
    };

    const displayDividerEndOfSpeaker = () => {
        return new Paragraph({
            text: "",
            spacing: {
                after: 150,
            },
            thematicBreak: true,
        });
    };

    const displayParagraph = (text, bold = false) => {
        return new Paragraph({
            children: [
                new TextRun({
                    children: [`${text}`],
                    font: "Calibri",
                    color: "000000",
                    bold: bold,
                    size: 24,
                }),
            ],
            thematicBreak: false,
            spacing: {
                after: 30,
            },
            indent: {
                left: 720,
            },
        });
    };

    const displayVideo = (speakerId) => {
        let videoRecords = getTabContent(speakerId, "Videos");
        let records = [];
        if (videoRecords.length > 0) {
            records = [displayHeadingForSpeakerDetails("Videos", false)];
            for (let video of videoRecords) {
                records.push(displayParagraphURLWithBullet(video.videoName, video.videoURL, true));
            }
        } else {
            // records.push(displayParagraph('No videos found, please contact to agent for more information.'))
        }

        return records;
    };

    const displayParagraphURLWithBullet = (text, url) => {
        return new Paragraph({
            children: [
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            children: [`${text}`],
                            font: "Calibri",
                            color: "253452",
                            size: 22,
                            style: "Hyperlink",
                        }),
                    ],
                    link: url,
                }),
            ],
            heading: HeadingLevel.HEADING_3,
            bullet: {
                level: 0,
            },
            thematicBreak: false,
        });
    };

    const displayFeeDetails = (speakerId) => {
        let feeRecords = getTabContent(speakerId, "Fee");
        let records = [displayHeadingForSpeakerDetails("Fees & Expenses", false)];

        if (feeRecords.length > 0) {
            let index = 0;
            for (let fee of feeRecords) {
                console.log("THE FEE RECORD: ", JSON.parse(JSON.stringify(fee)));

                let amount = 0;
                let description = "";

                if (!fee.toBeConfirmedUponInterest) {
                    if (
                        fee.flatTravel &&
                        fee.flatTravel > 0 &&
                        fee.flatTravelDisplay === "Display Flat Travel Amount"
                    ) {
                        amount = fee.feeAmount + (fee.flatTravel ? parseFloat(fee.flatTravel) : 0);
                        description = "Client to provide ";
                        description +=
                            fee.feeClientExtentWithFlatTravel !== "Custom"
                                ? fee.feeClientExtentWithFlatTravel
                                : fee.feeCustomClientExtentWithFlatTravel;
                    } else if (
                        fee.flatTravel &&
                        fee.flatTravel > 0 &&
                        fee.flatTravelDisplay === "Display Flat Travel Option Language (No Amount)"
                    ) {
                        amount = fee.feeAmount;
                        description =
                            "Client to provide " +
                            (fee.clientExtent && fee.clientExtent.toLowerCase() === "custom"
                                ? fee.customClientExtent
                                : fee.clientExtent);
                    } else if (fee.flatTravel && fee.flatTravel > 0 && fee.flatTravelDisplay === "Hide Flat Travel") {
                        amount = fee.feeAmount;
                        description =
                            "Client to provide " +
                            (fee.clientExtent && fee.clientExtent.toLowerCase() === "custom"
                                ? fee.customClientExtent
                                : fee.clientExtent);
                    } else {
                        amount = fee.feeAmount;
                        description =
                            "Client to provide " +
                            (fee.clientExtent && fee.clientExtent.toLowerCase() === "custom"
                                ? fee.customClientExtent
                                : fee.clientExtent);
                    }
                } else {
                    description = "Fee to be confirmed upon interest.";
                }

                let flatTravelText = "";
                if (fee.flatTravel && fee.flatTravel > 0 && fee.flatTravelDisplay !== "Hide Flat Travel") {
                    flatTravelText = " (flat travel fee available)";
                }
                records.push(
                    displayParagraph(
                        (fee.feeName?.split(/-(.*)/s)?.length > 1
                            ? fee.feeName?.split(/-(.*)/s)[1].trim()
                            : fee.feeName?.split(/-(.*)/s)[0].trim()) +
                            (amount > 0
                                ? " - " + amount?.toLocaleString("en-US", { style: "currency", currency: "USD" })
                                : ""),
                        true
                    )
                );

                records.push(displayParagraph(description, false));

                if (index + 1 < feeRecords.length) {
                    records.push(
                        new Paragraph({
                            children: [], // Just newline without text
                        })
                    );
                }

                // records.push(displayFieldWithBullet('Created Date', fee.feeCreatedDate, 0))
                index++;
            }
        } else {
            records.push(displayParagraph("Speak to your WSB Agent for more information on fees."));
        }

        // records.push(displayDivider());

        return records;
    };

    const getTabContent = (speakerId, tab) => {
        let records = mapSpeakerDetailsRef.current[speakerId]?.filter((item) => item.type === tab);
        if (records) {
            records = records.map((item, index) => {
                return {
                    ...item,
                    isFirst: index === 0,
                    isLast: index === records.length - 1,
                    feeAmount:
                        item.type !== "Fee"
                            ? 0
                            : item.speakerNetFee + item.wsbCommission + item.serviceFee + item.buyout + item.flatTravel,
                    isClientExtentCustom: item.type === "Fee" && item.clientExtent?.toLowerCase() === "custom",
                    isSpeakerExtentCustom: item.type === "Fee" && item.speakerExtent?.toLowerCase() === "custom",
                    workAddToWebsiteText: item.workAddToWebsite ? "Yes" : "No",
                    displayServiceFeeChangeReason: item.changeServiceFeeReason ? true : false,
                    displayServiceFeeChangeReasonOther: item.changeServiceFeeReasonOther ? true : false,
                };
            });

            return records;
        }
        return [];
    };

    const displayAgentNotes = (speakerId, speakerName) => {
        let record;
        if (mapSpeakerDetailsRef.current[speakerId]) {
            record = mapSpeakerDetailsRef.current[speakerId].find((item) => item.type === "Agent Notes");
        }
        if (record?.agentNotes) {
            return [
                displayHeadingForSpeakerDetails(`Note on ${speakerName}`),
                ...displayTextArea(
                    record?.agentNotes,
                    {
                        indent: {
                            left: 720,
                        },
                    },
                    { bold: false },
                    "000000"
                ),
            ];
        }
        return [];
    };

    const displayHeadingForSpeakerDetails = (text, pageBreak) => {
        console.log("Displaying speaker detail heading");
        return new Paragraph({
            children: [
                new TextRun({
                    children: [`${text}`],
                    font: "Calibri",
                    color: "1A2847",
                    size: 28,
                    bold: true,
                }),
            ],
            heading: HeadingLevel.HEADING_3,
            spacing: {
                before: 400,
                after: 100,
            },
            thematicBreak: false,
            pageBreakBefore: pageBreak,
        });
    };

    const displayTextArea = (value, setting, textRunSetting, color) => {
        if (value) {
            setting = setting ? setting : {};
            textRunSetting = textRunSetting ? textRunSetting : {};
            let records = [];
            let allParas = value.split(/\r?\n/);
            for (let para of allParas) {
                let textRun = new TextRun({
                    children: [para],
                    font: "Calibri",
                    color: color ? color : "000000",
                    size: 24,
                    ...textRunSetting,
                });

                let record = new Paragraph({
                    children: [textRun],
                    ...setting,
                    thematicBreak: false,
                    spacing: {
                        after: 30,
                    },
                });
                records.push(record);
            }

            return records;
        }
        return [];
    };

    const displaySubHeading = (text, size = 32) => {
        return new Paragraph({
            children: [
                new TextRun({
                    children: [`${text}`],
                    font: "Calibri",
                    allCaps: true,
                    bold: true,
                    color: "808080",
                    size: size,
                }),
            ],
            heading: HeadingLevel.HEADING_3,
            thematicBreak: false,
            spacing: {
                after: 100,
            },
        });
    };

    const displayRichtextArea = (value, setting, textRunSetting) => {
        let records = [];
        if (value) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(value, "text/html");

            // console.log('The original doc element child nodes', doc.body.childNodes);
            if (doc.body.childNodes.length > 0) {
                for (let node of doc.body.childNodes) {
                    // console.log('-- The node: ', node.nodeName, node.textContent);
                    if (node.nodeName === "P") {
                        let textRun = new TextRun({
                            children: [node.textContent],
                            font: "Calibri",
                            ...textRunSetting,
                        });

                        let record = new Paragraph({
                            children: [textRun],
                            ...setting,
                            spacing: {
                                after: 30,
                            },
                        });
                        records.push(record);
                    } else if (node.nodeName === "UL") {
                        let lis = node.getElementsByTagName("li");
                        if (lis.length > 0) {
                            for (let li of lis) {
                                let textRun = new TextRun({
                                    children: [`${li.textContent}`],
                                    font: "Calibri",
                                    ...textRunSetting,
                                });
                                records.push(
                                    new Paragraph({
                                        children: [textRun],
                                        bullet: {
                                            level: 0,
                                        },
                                        indent: {
                                            left: 1080,
                                        },
                                    })
                                );
                            }
                        }
                    } else if (node.nodeName === "#text") {
                        // console.log('Adding a text element with value: ', node.textContent)
                        let textRun = new TextRun({
                            children: [node.textContent],
                            font: "Calibri",
                            ...textRunSetting,
                        });

                        let record = new Paragraph({
                            children: [textRun],
                            ...setting,
                            spacing: {
                                after: 30,
                            },
                        });
                        records.push(record);
                    }
                }
            }
            return records;
        }
        return [];
    };

    const displayTitle = (text) => {
        return new Paragraph({
            children: [
                new TextRun({
                    children: [`${text}`],
                    allCaps: true,
                    font: "Calibri",
                    size: 32,
                    color: "253452",
                    bold: true,
                }),
            ],
            // heading: docx.HeadingLevel.TITLE,
            alignment: AlignmentType.LEFT,
            thematicBreak: false,
            spacing: {
                after: 50,
            },
        });
    };

    const displayTitleLink = (text, url) => {
        return new Paragraph({
            children: [
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            children: [`${text}`],
                            allCaps: true,
                            font: "Calibri",
                            size: 36,
                            color: "253452",
                            bold: true,
                        }),
                    ],
                    link: `https://${url}`,
                }),
            ],
            // heading: docx.HeadingLevel.TITLE,
            alignment: AlignmentType.LEFT,
            thematicBreak: false,
            spacing: {
                after: 50,
            },
        });
    };

    const displayGroupDescription = (text, bold = false) => {
        return new Paragraph({
            children: [
                new TextRun({
                    children: [`${text}`],
                    font: "Calibri",
                    color: "1f4d78",
                    bold: bold,
                    size: 24,
                }),
            ],
            thematicBreak: false,
            spacing: {
                after: 300,
            },
        });
    };

    const convertHtmlToText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        // console.log(doc.body.textContent);
        return doc.body.textContent || "";
    };

    const getThumbnail = (url) => {
        // Define the prefix to insert
        const prefix = "H-170";

        // Find the position to insert the prefix
        const urlParts = url.split("/");
        const insertIndex = 3; // After the third slash

        // Insert the prefix into the URL
        urlParts.splice(insertIndex, 0, prefix);

        // Join the parts back together
        const newUrl = urlParts.join("/");

        return newUrl;
    };

    const displayProfileImage = async (url, width, height) => {
        const imageBuffer = await loadImageIntoBuffer(url);

        return new Paragraph({
            children: [
                new ImageRun({
                    data: imageBuffer,
                    transformation: {
                        width: width ? width : 140,
                        height: height ? height : 170,
                    },
                }),
            ],
            heading: HeadingLevel.HEADING_3,
            spacing: {
                after: 150,
            },
            thematicBreak: false,
        });
    };

    const loadImageIntoBuffer = (url) => {
        return new Promise(async (resolve, reject) => {
            const headers = {
                origin: "*", // Replace with your origin URL
            };
            await fetch(url, {
                headers: headers,
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        var data = reader.result;
                        const buffer = new Uint8Array(data);
                        resolve(buffer);
                    };
                    reader.readAsArrayBuffer(blob);
                })
                .catch((error) => {
                    console.log("Error loading image:", error);
                    reject(error);
                });
        });
    };

    return (
        <Box>
            <div className="download-container"></div>
        </Box>
    );
});

export { ProposalDocxGenerator };
