import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent } from "../../utils";
import { selectAnswerData, selectAnswerStatus, selectAnswerError, initializeAsync } from "./answerSlice";

import { useAuth } from "../../hooks/useAuth";
import { Logo } from "../../components/Logo";
import { Button, CssBaseline, TextField, Box, Typography, Container, Paper, Snackbar, Alert } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const Answer = () => {
    const data = useSelector(selectAnswerData);
    const status = useSelector(selectAnswerStatus);
    const error = useSelector(selectAnswerError);
    const dispatch = useDispatch();
    const { answer, user } = useAuth();
    const [openToster, setOpenToster] = useState(false);
    const [tosterVariant, setTosterVariant] = useState("error");
    const [tosterMessage, setTosterMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (status === "idle") {
            dispatch(initializeAsync());
        }
    }, [status, dispatch]);

    if (!user || !user.sendCustomChallengeAnswer) {
        navigate("/login", {
            replace: true,
        });
    }

    const handleTosterClose = () => {
        setOpenToster(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        answer(user, data.get("otp"), (message) => {
            message = message ? message : "Invalid otp";
            if (message) {
                setTosterVariant("error");
                setTosterMessage(message);
                setOpenToster(true);
            }
            setLoading(false);
        });
    };

    let body = (
        <Box
            component="main"
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(249, 250, 252)",
            }}>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexGrow: 1,
                    minHeight: "100%",
                }}>
                <Container maxWidth="sm">
                    <CssBaseline />
                    <Paper
                        elevation={16}
                        sx={{
                            backgroundColor: "rgb(255, 255, 255)",
                            color: "rgb(18, 24, 40)",
                            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            borderRadius: "8px",
                            boxShadow: "rgb(100 116 139 / 12%) 0px 10px 15px",
                            backgroundImage: "none",
                            overflow: "hidden",
                            padding: "32px",
                        }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box>
                                <a>
                                    <Logo
                                        sx={{
                                            height: 40,
                                            width: 40,
                                        }}
                                    />
                                </a>
                            </Box>
                            <Typography component="h1" variant="h4">
                                Check your email!
                            </Typography>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="body1"
                                margin="1rem"
                                fontSize="0.875rem">
                                We've sent a one-time-passcode to your email to confirm your identity. Enter it below to
                                log in.
                            </Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} validate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="otp"
                                label="One-time-passcode"
                                name="otp"
                                autoFocus
                                sx={{ borderRadius: "8px" }}
                                variant="outlined"
                            />

                            <LoadingButton
                                size="small"
                                type="submit"
                                color="primary"
                                fullWidth
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, height: "45px" }}>
                                Submit
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openToster}
                autoHideDuration={6000}
                onClose={handleTosterClose}>
                <Alert onClose={handleTosterClose} severity={tosterVariant} variant="filled">
                    {tosterMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

    return getPageContent(body, status, error);
};

export { Answer };
