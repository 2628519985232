import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import "./style.css";

export const DragDropFile = forwardRef((props, ref) => {
    const { onFileChange } = props;
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        uploadFile() {
            inputRef.current.click();
        },
    }));

    const handleDrag = function (event) {
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            onFileChange(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // at least one file has been selected so do something
            // handleFiles(e.target.files);
            onFileChange(e.target.files);
        }
    };

    return (
        <div id="form-file-upload" onDragEnter={handleDrag}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                <div>
                    <p>Drag and drop your file here</p>
                    <button className="upload-button">Drop file here</button>
                </div>
            </label>
            {dragActive && (
                <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </div>
    );
});
