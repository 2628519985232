import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import { useAuth } from '../../hooks/useAuth';

import {
    selectHomeData,
    selectHomeStatus,
    selectHomeError,
    initializeAsync
} from './homeSlice';

import {
    Button,
    Box,
    Paper,
    Menu,
    MenuItem
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { config, styles } from '../../style';
import { useNavigate } from "react-router-dom";

import { EventCard } from "../../components/EventCard"
import { skeleton } from './skeleton';

const Home = () => {
    const data = useSelector(selectHomeData);
    const status = useSelector(selectHomeStatus);
    const error = useSelector(selectHomeError);
    const dispatch = useDispatch();
    const { getUserDetails } = useAuth();
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);

    const [anchorSortEl, setAnchorSortEl] = React.useState(null);
    const sortOpen = Boolean(anchorSortEl);

    const handleFilterMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (filterValue) => {
        setProposalFilter(filterValue);
        setAnchorEl(null);
    }

    const [proposalFilter, setProposalFilter] = useState('All');
    const [proposalSortBy, setProposalSortBy] = useState('Newest proposals');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(initializeAsync({ getUserDetails }));
        }
    }, [status, dispatch, getUserDetails]);

    const handleCardClick = (recordId) => {
        navigate('/proposal/' + recordId)
    }

    const handleSortMenuClose = (sortBy) => {
        setProposalSortBy(sortBy)
        setAnchorSortEl(null)
    }

    const handleSortMenuClick = (event) => {
        setAnchorSortEl(event.currentTarget);
    }

    const getRecords = () => {
        if (data?.records?.length > 0) {
            let recordsToSort = [...data?.records];
            if (proposalSortBy === 'Newest proposals') {
                return recordsToSort.sort((a,b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : ((new Date(b.createdDate) > new Date(a.createdDate)) ? 1 : 0))
            }
            else if (proposalSortBy === 'Event date') {
                return recordsToSort.sort((a,b) => (new Date(a.startPlayDate) > new Date(b.startPlayDate)) ? 1 : ((new Date(b.startPlayDate) > new Date(a.startPlayDate)) ? -1 : 0))
            }
        }
        return []
    }

    let body = (
        <>
            {status === 'initalLoading' ? skeleton :
                <>
                    <Box>
                        <Paper sx={{ p: '20px', display: 'flex', flexDirection: 'column' }} elevation={3}>
                            <Box style={{ fontSize: '12px', color: 'gray' }}>
                                Proposals for
                            </Box>
                            <Box style={{ textTransform: 'uppercase', fontWeight: '800', fontSize: '21px' }}>
                                {data?.userDetails?.contact?.accountName}
                            </Box>
                        </Paper>
                    </Box>
                    <Box sx={{ marginTop: '1rem', mb: '20px' }}>
                        <Paper sx={{ p: '20px' }} elevation={3}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <Box sx={{ mb: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Box>
                                            <Button
                                                id="filter-speakers"
                                                aria-controls={filterOpen ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={filterOpen ? 'true' : undefined}
                                                onClick={handleFilterMenuClick}
                                                startIcon={<FilterListIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                                                sx={{ ...styles.outlinedButton }}
                                                variant="outlined"
                                            >
                                                {`Proposal Filter: ${proposalFilter}`}
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={filterOpen}
                                                onClose={() => handleFilterMenuClose("All")}
                                                MenuListProps={{
                                                    'aria-labelledby': 'filter-speakers',
                                                }}

                                            >
                                                <MenuItem onClick={() => handleFilterMenuClose("All")} value="All">All</MenuItem>
                                                <MenuItem onClick={() => handleFilterMenuClose("Open")} value="Open">Open Proposals</MenuItem>
                                                <MenuItem onClick={() => handleFilterMenuClose("Closed")} value="Closed">Closed Proposals</MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ mb: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Box>
                                            <Button
                                                id="sort-speakers"
                                                aria-controls={sortOpen ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={sortOpen ? 'true' : undefined}
                                                onClick={handleSortMenuClick}
                                                startIcon={<SortIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                                                sx={{ ...styles.outlinedButton }}
                                                variant="outlined"
                                            >
                                                {`Sort by: ${proposalSortBy}`}
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorSortEl}
                                                open={sortOpen}
                                                onClose={() => handleSortMenuClose('Newest proposals')}
                                                MenuListProps={{
                                                    'aria-labelledby': 'sort-speakers',
                                                }}

                                            >
                                                <MenuItem onClick={() => handleSortMenuClose("Newest proposals")} value="Newest proposals">Newest proposals</MenuItem>
                                                <MenuItem onClick={() => handleSortMenuClose("Event date")} value="Event date">Event date</MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={3} alignItems="stretch">
                                {getRecords().map((record) => (
                                    <Grid item key={record.id} lg={4} md={4} xs={12} onClick={() => handleCardClick(record.id)}>
                                        <EventCard event={record} type="proposal"></EventCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Box>
                </>
            }

        </>
    )

    return getPageContent(body, status, error);
}

export { Home }