import {get, post} from '../../api'

const query = async (objectName, conditionName, params) => {
    return new Promise(async(resolve, reject) => {
        get('sObject/query/' + objectName + '/' + (conditionName ? conditionName : 'q'), params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const publicQuery = async (objectName, conditionName, params) => {
    return new Promise(async(resolve, reject) => {
        get(objectName + '/' + conditionName, params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const create = async (objectName, body) => {
    return new Promise(async(resolve, reject) => {
        post('sObject/create/' + objectName, body)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const update = async (objectName, body) => {
    return new Promise(async(resolve, reject) => {
        post('sObject/update/' + objectName, body)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const describe = async (objectName, params) => {
    return new Promise(async(resolve, reject) => {
        get('sObject/describe/' + objectName, params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const apex = async (method, url, body) => {
    return new Promise(async(resolve, reject) => {
        post('sObject/apex', {method: method, url: url, body: body})
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

export {query, publicQuery, create, describe, update, apex}