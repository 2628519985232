import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import {
    selectSpeakerSearchData,
    selectSpeakerSearchStatus,
    selectSpeakerSearchError,
    initializeAsync,
    createSpeakerListAsync,
    addSpeakerToListAsync,
    removeSpeakerFromListAsync,
    updateSpeakerListAsync,
    saveNotesAsync,
    getCommentsAsync,
    getNotesAsync,
    getSpeakerDetailsAsync
} from './speakerSearchSlice';

import {
    Button,
    Box,
    Paper,
    Menu,
    MenuItem,
    TextField,
    IconButton,
    InputAdornment,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    FormControlLabel,
    Autocomplete,
    Switch,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Drawer
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { config, styles } from '../../style';
import { SpeakerCard } from './speakerCard';

import 'instantsearch.css/themes/reset.css';
// or include the full Satellite theme
import 'instantsearch.css/themes/satellite.css';
import './style.css';
import { describe } from '../../services/salesforce';
import { format } from 'date-fns';

// Add custom imports here

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, ClearRefinements, RefinementList, Pagination, useInfiniteHits, useStats, useRefinementList, Highlight, Configure, useMenu, useClearRefinements } from 'react-instantsearch-hooks-web';
import { Filter } from './filter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Modal } from '../../components/Modal';
import { useAuth } from '../../hooks/useAuth';
import { AlertDialog } from '../../components/AlertDialog';
import { Speaker } from '../Speaker';

const searchClient = algoliasearch('31H9GDBCPG', '9094f08bf75f5c9ba1e995c07e045659');

const InfiniteHits = (props) => {
    const { hits, isLastPage, showMore } = useInfiniteHits(props);

    const sentinelRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !isLastPage) {
                        showMore();
                    }
                });
            },
            { threshold: 1 }
        );

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
        };
    }, [isLastPage, showMore]);



    return (
      <div>
        {hits.map(hit => (
          <SpeakerCard key={hit.Id} record={hit} speakerLists={props.speakerLists} listBySpeaker={props.listBySpeaker} commentBySpeaker={props.commentBySpeaker} noteBySpeaker={props.noteBySpeaker} availabilityCheck={props.availabilityCheck}></SpeakerCard>
        ))}
        <div ref={sentinelRef}></div>
      </div>
    );
};

const Stats = (props) => {
    const {
        hitsPerPage,
        nbHits,
        areHitsSorted,
        nbSortedHits,
        nbPages,
        page,
        processingTimeMS,
        query,
    } = useStats(props);

    const handleRequestClick = () => {
        const createEvent = new CustomEvent('requestProposal')
        document.dispatchEvent(createEvent);
    }

    return (
        <CardHeader sx={{ padding: '15px', borderBottom: '1px solid #e7e8f0' }}
            title={`${props.selectedSpeakerList && props.selectedSpeakerList?.name ? props.selectedSpeakerList?.name : 'All Speakers'} (${nbHits})`}
            action={props.selectedSpeakerList && <>
                {props.selectedSpeakerList?.status === 'Requested Proposal' ? <div><Button variant="text" color="success" sx={{ color: '#5048E5' }} startIcon={<CheckIcon fontSize='small' />}>Proposal requested</Button></div> : <Button variant="contained" onClick={handleRequestClick}>Request proposal</Button>}
            </>}
        />
    );
}

const CustomRefinementList = (props) => {
    const {
        items,
        hasExhaustiveItems,
        createURL,
        refine,
        sendEvent,
        searchForItems,
        isFromSearch,
        canRefine,
        canToggleShowMore,
        isShowingMore,
        toggleShowMore,
    } = useRefinementList(props);

    const [searchText, setSearchText] = useState('')
    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        toggleShowMore()
    }, [toggleShowMore])

    const getItems = () => {
        let records = items;
        if (searchText) {
            records = records.filter(item => item.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        }
        return showAll ? records : records.slice(0, 10);
    }
    const handleChange = (event, value) => {
        refine(value);
        const createEvent = new CustomEvent('filterChange', {
            detail: {
                value: event.target.checked
            }
        })
        document.dispatchEvent(createEvent);
    }

    return (
        <Box className='RefinementList'>
            {props.searchable && <TextField sx={{ mb: '10px' }} fullWidth variant="outlined"
                id="input-with-icon-textfield"
                label=""
                placeholder='Search by keyword'
                onChange={event => { setSearchText(event.currentTarget.value); }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />}
            {getItems().length > 0 && getItems().map(item => (
                <Box>

                    <FormControlLabel className={item.isRefined ? 'selected' : ''}
                        control={
                            <Checkbox value={item.value} checked={item.isRefined} onChange={event => {
                                handleChange(event, event.currentTarget.value)
                            }} />
                        }
                        label={item.label}
                    />
                    <Chip sx={{ fontSize: '12px', height: '25px' }} label={item.count} />
                </Box>
            ))}
            {getItems().length === 0 && <span>No filter</span>}
            {getItems().length === 10 && getItems().length < items.length && <Button variant="text" onClick={() => setShowAll(true)}>Show more</Button>}
            {getItems().length > 10 && getItems().length === items.length && <Button variant="text" onClick={() => setShowAll(false)}>Show less</Button>}
        </Box>
    );
}

const CustomMenu = (props) => {
    const {
        items,
        createURL,
        refine,
        canRefine,
        isShowingMore,
        toggleShowMore,
        canToggleShowMore,
        sendEvent,
    } = useMenu(props);

    const [searchText, setSearchText] = useState('')
    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        toggleShowMore()
    }, [toggleShowMore])

    const handleChange = (event, value) => {
        refine(value);
        const createEvent = new CustomEvent('filterChange', {
            detail: {
                value: value
            }
        })
        document.dispatchEvent(createEvent);
    }

    return (
        <Box className='CustomMenu'>
            <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-demo"
                sx={{ mt: '.5rem' }}
                options={items.map(item => item.label)}
                onChange={(event, value) => handleChange(event, value)}
                renderInput={(params) => <TextField fullWidth {...params} label={props.label} />}
            />
        </Box>
    );
}

const CustomClearRefinements = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({

        clearFilter() {
            handleClick()
        },
    }));

    const data = useClearRefinements({});

    const handleClick = () => {
        data.refine();
        const createEvent = new CustomEvent('clearFilter')
        document.dispatchEvent(createEvent);
    }

    return <>
        <Box sx={{ mt: '12px' }}>
            <Grid container justifyContent="space-between" sx={{ border: '1px solid #E0EAFF', mr: '10px', p: '5px 8px 0 8px', background: '#E0EAFF', borderRadius: '16px' }}>
                <Grid item sx={{ p: '0px', color: '#2D3282' }}>
                    {props.filterCount} selected
                </Grid>
                <Grid item sx={{ p: '0px', cursor: 'pointer' }}>
                    <CloseIcon fontSize='small' sx={{ ml: '10px', color: '#6172F3' }} onClick={handleClick}></CloseIcon>
                </Grid>
            </Grid>
        </Box>
    </>;
})



const SpeakerSearch = ({ application }) => {
    const data = useSelector(selectSpeakerSearchData);
    const status = useSelector(selectSpeakerSearchStatus);
    const error = useSelector(selectSpeakerSearchError);
    const dispatch = useDispatch();

    const [availabilityCheck, setAvailabilityCheck] = useState(undefined);
    const [openCreateListModal, setOpenCreateListModal] = useState(false);
    const [currentCreateRecord, setCurrentCreateRecord] = useState(undefined);
    const [currentListRecord, setCurrentListRecord] = useState(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [selectedSpeakerList, setSelectedSpeakerList] = useState(undefined);
    const [selectedMemberToDelete, setSelectedMemberToDelete] = useState(undefined);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [openLeaveNoteModal, setOpenLeaveNoteModal] = useState(false);
    const [currentNote, setCurrentNote] = useState(undefined);
    const [openRequestProposalModal, setOpenRequestProposalModal] = useState(false);
    const [countryPicklist, setCountryPicklist] = useState([]);
    const [endDateTimePicklist, setEndDateTimePicklist] = useState([]);
    const [startDateTimePicklist, setStartDateTimePicklist] = useState([]);
    const [openSpeakerScreen, setOpenSpeakerScreen] = useState(false)
    const [selectedSpeakerForViewProfile, setSelectedSpeakerForViewProfile] = useState({})
    


    const { getUserDetailsNotAsync } = useAuth();
    const userDetails = getUserDetailsNotAsync();
    const customClearRefinementsRef = useRef(null);

    

    useEffect(() => {
        document.addEventListener('create', handleCreate);
        document.addEventListener('addSpeaker', handleAddSpeaker);
        document.addEventListener('filterChange', handleFilterChange);
        document.addEventListener('clearFilter', handleClearFilter);
        document.addEventListener('removeSpeaker', handleRemoveFilter);
        document.addEventListener('leaveNote', handleLeaveNote);
        document.addEventListener('requestProposal', handleRequestProposal);
        document.addEventListener('viewProfile', handleViewProfile);
        document.addEventListener('closeSpeakerDrawer', handleCloseSpeakerDrawer);
        

        if (status === 'idle') {
            dispatch(initializeAsync({ contactId: userDetails?.contact?.id, application, accountId: userDetails?.contact?.accountId }));
            dispatch(getCommentsAsync({ contactId: userDetails?.contact?.id, application, accountId: userDetails?.contact?.accountId }));
            dispatch(getNotesAsync({ contactId: userDetails?.contact?.id, application, accountId: userDetails?.contact?.accountId }));

            getCalendarEntryDescribe();
        }
        return () => {
            document.removeEventListener("create", handleCreate);
            document.removeEventListener('filterChange', handleFilterChange);
            document.removeEventListener('clearFilter', handleClearFilter);
            document.removeEventListener('addSpeaker', handleAddSpeaker);
            document.removeEventListener('removeSpeaker', handleRemoveFilter);
            document.removeEventListener('leaveNote', handleLeaveNote);
            document.removeEventListener('requestProposal', handleRequestProposal);
            document.removeEventListener('viewProfile', handleViewProfile);
            document.addEventListener('closeSpeakerDrawer', handleCloseSpeakerDrawer);
        }
    }, [status, dispatch, userDetails]);

    const getCalendarEntryDescribe = async () => {
        const result = await describe('calendarEntry', { cacheable: true })
        if (result.isSuccess) {
            console.log(result)
            for (let field of result?.data?.fields) {
                if (field.name === 'Location_Country_Global__c') {
                    setCountryPicklist(field.picklistValues)
                }
                else if (field.name === 'End_Date_Time__c') {
                    setEndDateTimePicklist(field.picklistValues)
                }
                else if (field.name === 'Start_Date_Time__c') {
                    setStartDateTimePicklist(field.picklistValues)
                }
            }

        }
    }

    const handleCloseSpeakerDrawer = () => {
        setOpenSpeakerScreen(false)
    }

    const handleViewProfile = async (event) => {
        let speakerRecord = event.detail.value
        if (data?.speakerDetails && data?.speakerDetails[speakerRecord.Id]) {
            setSelectedSpeakerForViewProfile(data?.speakerDetails[speakerRecord.Id])
            setOpenSpeakerScreen(true);
        } else {
            dispatch(getSpeakerDetailsAsync({
                id: speakerRecord.Id, callback: (result) => {
                    if (result.isSuccess && result.data.length > 0) {
                        setSelectedSpeakerForViewProfile(result.data[0])
                        setOpenSpeakerScreen(true)
                    }
            } }));
        }
    }

    const handleCreate = (event) => {
        const detail = event.detail;
        setCurrentCreateRecord(detail.value)
        setCurrentListRecord({});
        setOpenCreateListModal(true);
    }

    const handleCreateListModalClose = () => {
        setOpenCreateListModal(false);
        //setSelectedSpeakerList(undefined)
        setCurrentListRecord(undefined);
        setCurrentCreateRecord(undefined)
    }

    const handleCreateListSave = () => {
        if (currentListRecord.name) {
            dispatch(createSpeakerListAsync({ list: { ...currentListRecord, contactId: userDetails?.contact?.id }, speakerId: currentCreateRecord?.Id }));
            setOpenCreateListModal(false);
            setSelectedSpeakerList(undefined)
            setCurrentListRecord(undefined);
            setCurrentCreateRecord(undefined)
        }

    }

    const handleUpdateListSave = () => {
        if (currentListRecord.name) {
            dispatch(updateSpeakerListAsync({ list: { ...currentListRecord, contactId: userDetails?.contact?.id }, speakerId: currentCreateRecord?.Id }));
            setSelectedSpeakerList(currentListRecord)
            setOpenCreateListModal(false);
            setCurrentListRecord(undefined);
            setCurrentCreateRecord(undefined)
        }


    }

    const handleDeleteSpeakerList = () => {
        dispatch(updateSpeakerListAsync({ list: { ...currentListRecord, contactId: userDetails?.contact?.id, isDeleted: true }, speakerId: currentCreateRecord?.Id }));
        setOpenCreateListModal(false);
        setSelectedSpeakerList(undefined);
        setCurrentListRecord(undefined);
        setCurrentCreateRecord(undefined)
    }

    const setListName = (name) => {
        setCurrentListRecord({ ...currentListRecord, name: name });
    }
    const handlePrivateChange = (event) => {
        setCurrentListRecord({ ...currentListRecord, type: event.target.checked ? 'Private' : 'Public' });
    }

    const handleFilterChange = (event) => {
        let value = event.detail.value;
        if (value) {
            setFilterCount(currCount => currCount + 1)
        } else {
            setFilterCount(currCount => currCount - 1)
        }
    }

    const handleClearFilter = () => {
        setFilterCount(0)
        setAvailabilityCheck(undefined);
    }

    const handleSelectListChange = (event, value) => {
        if (value) {
            setSelectedSpeakerList(data?.speakerLists.find(item => item.id === value.id))
        }
        else {
            setSelectedSpeakerList(undefined)
        }
        customClearRefinementsRef.current.clearFilter()
        searchClient.helper.setQuery('').search();

    }


    const handleAddSpeaker = (event) => {
        let speakerRecord = event.detail.value;
        let listId = event.detail.listId;
        dispatch(addSpeakerToListAsync({ listId: listId, speakerId: speakerRecord?.Id }));

    }

    const handleRemoveFilter = (event) => {
        let speakerRecord = event.detail.value;
        let listId = event.detail.listId;
        let listRecord = data?.speakerLists?.find(item => item.id === listId);
        let member = listRecord?.members?.find(item => item.speakerId === speakerRecord?.Id)
        if (member) {
            setSelectedMemberToDelete(member);
            setOpenDeleteConfirmationModal(true);
        }
    }

    const handleDeleteConfirmationClose = () => {
        setOpenDeleteConfirmationModal(false);
    }

    const handleDeleteConfirmationSave = () => {
        dispatch(removeSpeakerFromListAsync({ member: selectedMemberToDelete }));
        setSelectedMemberToDelete(undefined);
        setOpenDeleteConfirmationModal(false);
    }

    const handleManageList = () => {
        setOpenCreateListModal(true)
        setCurrentListRecord(selectedSpeakerList);
    }

    const getConfigureFilter = () => {
        let filters = ''
        // if (availabilityCheck) {
        //     const givenDate = new Date(availabilityCheck).getTime();
        //     const startDateFilter = `calendarEntries.startDateTimestanp > ${givenDate}`;
        //     const endDateFilter = `calendarEntries.endDateTimestanp < ${givenDate}`;
        //     filters = `(${startDateFilter} OR ${endDateFilter})`;
        // }
        if (selectedSpeakerList) {
            filters = filters + (filters ? ' AND ' : '') + selectedSpeakerList?.members?.map(item => `Id:${item.speakerId}`).join(' OR ')
        }
        return filters
    }

    const handleLeaveNote = (event) => {
        if (!event.detail.note) {
            let record = event.detail.value;
            setCurrentNote({ id: '', description: '', speakerId: record.Id, contactId: userDetails?.contact?.id })
        } else {
            setCurrentNote(event.detail.note);
        }
        setOpenLeaveNoteModal(true);
    }

    const handleNoteChange = (value) => {
        setCurrentNote({ ...currentNote, description: value })
    }

    const handleLeaveNoteModalClose = () => {
        setCurrentNote(undefined);
        setOpenLeaveNoteModal(false);
    }

    const handleLeaveNoteSave = () => {
        if (currentNote.description) {
            dispatch(saveNotesAsync({ note: currentNote }));
            setCurrentNote(undefined);
            setOpenLeaveNoteModal(false);
        }
    }

    const handleRequestProposal = () => {
        setOpenRequestProposalModal(true)
    }

    const handleRequestProposalModalClose = () => {
        // selectedSpeakerList.endDate = undefined;
        // selectedSpeakerList.startDate = undefined;
        // selectedSpeakerList.endTime = undefined;
        // selectedSpeakerList.startTime = undefined;
        // selectedSpeakerList.city = undefined;
        // selectedSpeakerList.state = undefined;
        // selectedSpeakerList.country = undefined;
        // selectedSpeakerList.note = undefined;
        // selectedSpeakerList.status = undefined;

        setOpenRequestProposalModal(false)
    }

    const handleRequestProposalSave = () => {
        if (selectedSpeakerList?.endDate && selectedSpeakerList?.startDate && selectedSpeakerList?.endTime && selectedSpeakerList?.startTime && selectedSpeakerList?.city && selectedSpeakerList?.state && selectedSpeakerList?.country && selectedSpeakerList?.note) {

            selectedSpeakerList.endDate = format(new Date(selectedSpeakerList.endDate), 'yyyy-MM-dd');
            selectedSpeakerList.startDate = format(new Date(selectedSpeakerList.startDate), 'yyyy-MM-dd');
            selectedSpeakerList.status = 'Requested Proposal'
            dispatch(updateSpeakerListAsync({ list: { ...selectedSpeakerList, contactId: userDetails?.contact?.id } }));
            setOpenRequestProposalModal(false)
        } else {
            const detailEvent = new CustomEvent('openToster', { detail: { variant: 'error', message: 'All fields are required, please fill the details' } })
            document.dispatchEvent(detailEvent)
            return ''
        }
    }

    const handleValueChange = (value, field) => {
        let data = JSON.parse(JSON.stringify(selectedSpeakerList));
        data[field] = value;
        setSelectedSpeakerList(data)
    }


    let body = (
        <div style={styles.container}>
            <InstantSearch searchClient={searchClient} indexName="Speakers">
                <Configure filters={getConfigureFilter()} />
                <Grid container spacing={2} justifyContent="start">
                    <Grid lg={9} item>
                        <Card sx={styles.card}>
                            <CardContent sx={{ pt: '15px', pb: '15px' }}>
                                <SearchBox
                                    placeholder='Search by keyword'
                                    searchAsYouType={true}
                                    autoFocus={true}
                                />
                            </CardContent>
                        </Card>
                        <Card sx={{ ...styles.card, mt: '15px' }}>
                            <Stats selectedSpeakerList={selectedSpeakerList}></Stats>
                            <CardContent sx={{ p: 0 }}>
                                <Box>
                                    <InfiniteHits
                                        speakerLists={data?.speakerLists}
                                        listBySpeaker={data?.listBySpeaker}
                                        commentBySpeaker={data?.commentBySpeaker}
                                        noteBySpeaker={data?.noteBySpeaker}
                                        availabilityCheck={availabilityCheck}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid lg={3} item>
                        <Card sx={{ ...styles.card }}>
                            <CardHeader sx={{ padding: '15px', borderBottom: '1px solid #e7e8f0' }}
                                title="Filters"
                                action={filterCount > 0 &&
                                    <><CustomClearRefinements ref={customClearRefinementsRef} filterCount={filterCount}></CustomClearRefinements></>
                                }
                            />
                            <CardContent sx={{ p: 0 }}>
                                {data?.speakerLists?.length > 0 && <Filter label="My lists" icon={<FilterListIcon fontSize='small' sx={{ color: config.primaryColor }}></FilterListIcon>}>
                                    {/* <Button onClick={() => customClearRefinementsRef.current.clearFilter()}>Test</Button> */}
                                    <Box className='CustomMenu'>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            id="combo-box-demo"
                                            sx={{ mt: '.5rem' }}
                                            value={!selectedSpeakerList ? { label: '', id: '' } : { label: `${selectedSpeakerList?.name} (${selectedSpeakerList?.members?.length})`, id: selectedSpeakerList?.id }}
                                            options={data?.speakerLists.map(item => { return { label: `${item.name} (${item.members.length})`, id: item.id } })}
                                            onChange={(event, value) => { handleSelectListChange(event, value) }}
                                            renderInput={(params) => <TextField value={selectedSpeakerList?.name} fullWidth {...params} label="" placeholder='Select list' />}
                                        />
                                        {selectedSpeakerList && <Button variant="text" sx={{ mt: '5px' }} onClick={handleManageList}>Manage list</Button>}
                                    </Box>
                                </Filter>}
                                <Filter label="Topics & Types" icon={<BusinessCenterIcon fontSize='small' sx={{ color: config.primaryColor }}></BusinessCenterIcon>}>
                                    <CustomRefinementList attribute="mainCategory" searchable={true} showMore={true} showMoreLimit={1000}></CustomRefinementList>
                                </Filter>
                                <Filter label="Budget" icon={<AttachMoneyIcon fontSize='small' sx={{ color: config.primaryColor }}></AttachMoneyIcon>}>
                                    <CustomRefinementList attribute="feeCode" searchable={false} showMore={true} showMoreLimit={1000}></CustomRefinementList>
                                </Filter>
                                <Filter label="Availability check" icon={<DateRangeIcon fontSize='small' sx={{ color: config.primaryColor }}></DateRangeIcon>}>
                                    <TextField fullWidth value={availabilityCheck} type="date" onChange={(event) => { setAvailabilityCheck(event.target.value); handleFilterChange({ detail: { value: event.target.value } }) }} />
                                </Filter>
                                <Filter label="Traveling from" icon={<LocationOnIcon fontSize='small' sx={{ color: config.primaryColor }}></LocationOnIcon>}>
                                    <CustomMenu attribute="speakerHomeCountry" label="Country" showMore={true} showMoreLimit={1000}></CustomMenu>
                                    <CustomMenu attribute="speakerHomeState" label="State" showMore={true} showMoreLimit={1000}></CustomMenu>
                                    <CustomMenu attribute="speakerHomeCity" label="City" showMore={true} showMoreLimit={1000}></CustomMenu>
                                </Filter>
                                {/* <Filter label="WSB Status" icon={<CheckCircleOutlineIcon fontSize='small' sx={{ color: config.primaryColor }}></CheckCircleOutlineIcon>}>
                                    <CustomRefinementList attribute="speakerStatus" searchable={false} showMore={true} showMoreLimit={1000}></CustomRefinementList>
                                </Filter> */}
                                
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </InstantSearch>

            <Modal open={openCreateListModal} title="Create list" onClose={handleCreateListModalClose} onSave={handleCreateListSave} maxWidth="md" actions={<div style={{ width: '100%' }}>
                <Grid container justifyContent="space-between" alignContent='center'>
                    <Grid item>
                        <Button color="error" onClick={handleDeleteSpeakerList}>
                            Delete
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleCreateListModalClose}>
                            Cancel
                        </Button>
                        <Button onClick={currentListRecord?.id ? handleUpdateListSave : handleCreateListSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </div>}>
                <Box>
                    <TextField value={currentListRecord?.name} onChange={(event) => setListName(event.target.value)} fullWidth id="outlined-basic" label="List name" variant="outlined" />
                    <Box sx={{ border: '1px solid #e7e8f0', borderRadius: '8px', mt: '10px' }}>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ padding: '8px 15px', color: '#a1a1a1' }}>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <VisibilityOffIcon fontSize='small' sx={{ mr: '10px' }}></VisibilityOffIcon>
                                    Make list private?
                                </Box>
                            </Grid>
                            <Grid item>
                                <Switch
                                    checked={currentListRecord?.type === 'Private'}
                                    onChange={handlePrivateChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openLeaveNoteModal} title={currentNote?.id ? 'Edit note' : 'Leave a note'} onClose={handleLeaveNoteModalClose} onSave={handleLeaveNoteSave} maxWidth="md">
                <Box>
                    <TextField onChange={(event) => handleNoteChange(event.target.value)} fullWidth value={currentNote?.description} label="Enter notes for the speaker" variant="outlined" multiline
                        rows={6}
                        maxRows={6} />
                </Box>
            </Modal>
            <AlertDialog title="Confirm" message="Are you sure, you want to remove this speaker for the list?" open={openDeleteConfirmationModal} onDisagree={handleDeleteConfirmationClose} onAgree={handleDeleteConfirmationSave}></AlertDialog>

            <Modal open={openRequestProposalModal} title={'Request proposal'} onClose={handleRequestProposalModalClose} onSave={handleRequestProposalSave} maxWidth="sm">
                <Box>
                    <Grid sx={{ mt: '10px' }} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography >Please provide as much detail about your event as possible. It will help us provide you the most accurate information about the requested speakers. Someone from our team will reach out soon with a proposal.</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start date"
                                    value={selectedSpeakerList?.startDate}
                                    onChange={(newValue) => handleValueChange(newValue, 'startDate')}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={6} item>
                            <FormControl fullWidth>
                                <InputLabel id="start-time-label">Start time</InputLabel>
                                <Select
                                    labelId="start-time-label"
                                    id="start-time-select"
                                    label="Start time"
                                    value={selectedSpeakerList?.startTime}
                                    onChange={(event) => handleValueChange(event.target.value, 'startTime')}
                                >
                                    {startDateTimePicklist.map(item => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: '10px' }} container spacing={1}>
                        <Grid xs={6} item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End date"
                                    value={selectedSpeakerList?.endDate}
                                    onChange={(newValue) => handleValueChange(newValue, 'endDate')}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={6} item>
                            <FormControl fullWidth>
                                <InputLabel id="end-time-label">End time</InputLabel>
                                <Select
                                    labelId="end-time-label"
                                    id="end-time-select"
                                    label="End time"
                                    value={selectedSpeakerList?.endTime}
                                    onChange={(event) => handleValueChange(event.target.value, 'endTime')}
                                >
                                    {endDateTimePicklist.map(item => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: '10px' }} container spacing={1}>
                        <Grid xs={6} item>
                            <TextField onChange={(event) => handleValueChange(event.target.value, 'city')} fullWidth value={selectedSpeakerList?.city} label="Venue City" variant="outlined" />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField onChange={(event) => handleValueChange(event.target.value, 'state')} fullWidth value={selectedSpeakerList?.state} label="Venue State" variant="outlined" />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="country-label">Venue Country</InputLabel>
                            <Select
                                labelId="country-label"
                                id="country-select"
                                label="Venue Country"
                                value={selectedSpeakerList?.country}
                                onChange={(event) => handleValueChange(event.target.value, 'country')}
                            >
                                {countryPicklist.map(item => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ mt: '10px' }}>
                        <TextField onChange={(event) => handleValueChange(event.target.value, 'note')} fullWidth value={selectedSpeakerList?.note} label='Event Information' placeholder="Please share any additional additional details about your event, including the audience or any other relevant information." variant="outlined" multiline
                            rows={3}
                            maxRows={5} />
                    </Box>
                </Box>
            </Modal>

            <Drawer
                    anchor='right'
                    open={openSpeakerScreen}
                >
                    <Speaker defaultSpeakerDetail width='700px' userDetails={userDetails} speakerId={selectedSpeakerForViewProfile?.id} record={selectedSpeakerForViewProfile} disableTracking></Speaker>
            </Drawer>
        </div>

    )

    return getPageContent(body, status, error);
}

export { SpeakerSearch }



