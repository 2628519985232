import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent } from "../../utils";
import {
    selectEventDetailData,
    selectEventDetailStatus,
    selectEventDetailError,
    initializeAsync,
} from "./eventDetailSlice";

import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { Box, Card, Button, TextField, Typography, CardContent, Divider, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ShareIcon from "@mui/icons-material/Share";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LayersIcon from "@mui/icons-material/Layers";
import FlightIcon from "@mui/icons-material/Flight";
import PlaceIcon from "@mui/icons-material/Place";
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";

import { encrypt } from "../../services/utils";

import { config, styles } from "../../style";
import { skeleton } from "./skeleton";
import Skeleton from "@mui/material/Skeleton";
import { useAuth } from "../../hooks/useAuth";
import { styled } from "@mui/material/styles";
import { Itinerary } from "./itinerary";
import { Section } from "../../components/Section";
import { CopyClipboardText } from "../../components/CopyClipboardText";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import LaunchIcon from "@mui/icons-material/Launch";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minHeight: "46px",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
        color: config.primaryColor,
        opacity: 1,
    },
    "&.Mui-selected": {
        color: config.primaryColor,
        fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
        backgroundColor: config.primaryColor,
    },
}));

const iconMapping = {
    "Default": <LayersIcon fontSize="smaller"></LayersIcon>,
    "Flight": <FlightIcon fontSize="smaller"></FlightIcon>,
    "Place": <PlaceIcon fontSize="smaller"></PlaceIcon>,
    "Moderate": <BroadcastOnPersonalIcon fontSize="smaller"></BroadcastOnPersonalIcon>,
    "Person": <PersonIcon fontSize="smaller"></PersonIcon>,
    "Phone": <PhoneIcon fontSize="smaller"></PhoneIcon>,
};

// Add custom imports here

const EventDetail = (props) => {
    const { shared } = props;
    const data = useSelector(selectEventDetailData);
    const status = useSelector(selectEventDetailStatus);
    const error = useSelector(selectEventDetailError);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { recordId } = useParams();
    const { getUserDetails } = useAuth();
    const [selectedTab, setSelectedTab] = useState("");
    const [openShareLinkModal, setOpenShareLinkModal] = useState(false);
    const [sharedUrl, setSharedUrl] = useState("");
    const [loginRequiredUrl, setLoginRequiredUrl] = useState("");
    const [linkCopied, setLinkCopied] = useState(false);
    const [currentStep, setCurrentStep] = useState(false);

    const opportunityData = useSelector((state) => {
        let data = state.eventDetail.data[recordId];
        if (data?.sections && data?.sections?.length > 0 && !selectedTab) {
            setSelectedTab(data.sections[0].sectionTabValue);
        }
        return data;
    });

    console.log("opportunityData", opportunityData);

    // Add Variables

    useEffect(() => {
        if (!data[recordId]) {
            dispatch(initializeAsync({ recordId, shared }));
        }
    }, [dispatch, data, recordId, shared, currentStep]);

    const backToEvents = () => {
        navigate(`/events`);
    };

    const navigateToForm = (formId) => {
        console.log("navigating!");
        navigate(`/formContainer/?formId=${formId}`);
    };

    const sharedEventClick = async () => {
        const expDays = 15;
        let date = new Date();
        date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
        const result = await encrypt({ recordId: recordId, expires: date.toUTCString() });
        if (result.isSuccess) {
            let url = `${window.location.origin}/share/event-detail/${result.data}`;
            let loginRequiredUrl = `${window.location.origin}/shared/event-detail/${recordId}`;
            console.log(url);
            setSharedUrl(url);
            setLoginRequiredUrl(loginRequiredUrl);
            setOpenShareLinkModal(true);
        }
    };

    const handleShareLinkModalClose = () => {
        setSharedUrl("");
        setLoginRequiredUrl("");
        setOpenShareLinkModal(false);
        setLinkCopied(false);
    };

    const handleOpenComment = (subject) => {
        const event = new CustomEvent("openDrawer", {
            detail: { parentId: recordId, subject: subject, parentName: opportunityData?.opportunity?.name },
        });
        document.dispatchEvent(event);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handlePreEventSurvayClick = () => {
        if (opportunityData?.opportunity?.preEventSurveyLink) {
            window.open(window.location.origin + "/" + opportunityData?.opportunity?.preEventSurveyLink, "_blank");
        }
    };

    const getLocationDetails = (opportunity) => {
        if (opportunity) {
            if (opportunity.venueCountryGlobal === "United States") {
                let details = [];
                if (opportunity.venueCity) {
                    details.push(opportunity.venueCity);
                }
                if (opportunity.venueState) {
                    details.push(opportunity.venueState);
                }
                return details.join(", ");
            } else {
                let details = [];
                if (opportunity.venueCity) {
                    details.push(opportunity.venueCity);
                }
                if (opportunity.venueCountryGlobal) {
                    details.push(opportunity.venueCountryGlobal);
                }
                return details.join(", ");
            }
        }
        return "";
    };

    let body = (
        <>
            {!shared && (
                <Box sx={{ mb: "1rem" }}>
                    <Button
                        id="back-to-events"
                        onClick={backToEvents}
                        variant="outlined"
                        sx={{
                            border: "none",
                            padding: "0",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#ef6b19",
                            mt: ".5rem",
                            "&:hover": {
                                background: "none !important",
                                padding: "0 !important",
                                border: "none !important",
                            },
                        }}
                        startIcon={<ArrowCircleLeftIcon />}>
                        Back to Events
                    </Button>
                </Box>
            )}

            <div>
                {status === "initalLoading" ? (
                    skeleton
                ) : (
                    <div style={styles.container}>
                        <Card sx={styles.card}>
                            <CardContent sx={{ pt: "15px", pb: "15px" }}>
                                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="overline" display="block" gutterBottom>
                                            EVENT BRIEFING
                                        </Typography>
                                        <Typography variant="h1" sx={{ fontSize: "1.5rem" }}>
                                            {opportunityData?.opportunity?.eventName}
                                        </Typography>
                                    </Grid>
                                    {!shared && (
                                        <Grid item>
                                            <Button
                                                onClick={sharedEventClick}
                                                startIcon={
                                                    <ShareIcon sx={{ color: config.primaryColor }} fontSize="small" />
                                                }
                                                variant="text"
                                                sx={styles.textButton}>
                                                Share event detail
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                {/* <Grid sx={{ my: '5px' }} container wrap="nowrap" spacing={0} justifyContent="start">
                                    <Grid item>
                                        {
                                            opportunityData?.opportunity?.startPlayDate ? (
                                                <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'center', mr: '20px', fontSize: '16px' }}>
                                                    <CalendarMonthIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                                    <span style={{ marginLeft: '5px' }}>{format(new Date(opportunityData?.opportunity?.startPlayDate), 'MMMM d, yyyy')}</span>
                                                </Box>
                                            ) : ''
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            opportunityData?.opportunity?.venueName &&
                                            <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'center', fontSize: '16px' }}>
                                                <LocationOnIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                                <span style={{ marginLeft: '5px' }}>{opportunityData?.opportunity?.venueName}</span>
                                            </Box>
                                        }
                                    </Grid>
                                </Grid> */}
                                {!shared && <Divider sx={{ mx: "-15px", mt: "10px", mb: "15px" }} />}

                                <Grid container wrap="nowrap" spacing={0} justifyContent="start">
                                    <Grid item xs={2} sx={{ borderRight: "1px solid lightgray", paddingRight: "20px" }}>
                                        {opportunityData?.opportunity?.speakerImageURL && (
                                            <Box
                                                component="img"
                                                sx={{
                                                    objectFit: "cover",
                                                    width: "140px",
                                                    height: "150px",
                                                    objectPosition: "top",
                                                    borderRadius: "4px",
                                                    mr: "20px",
                                                }}
                                                src={`${opportunityData?.opportunity?.speakerImageURL}`}></Box>
                                        )}
                                        {!opportunityData?.opportunity?.speakerImageURL && (
                                            <Skeleton sx={{ mr: "20px" }} variant="rounded" width={140} height={150} />
                                        )}
                                        <h2>{opportunityData?.opportunity?.speakerName}</h2>
                                        {/* <Typography>{opportunityData?.opportunity?.speakerTitle}</Typography> */}
                                    </Grid>
                                    <Grid item xs={10} sx={{ paddingLeft: "30px" }}>
                                        <Grid container wrap>
                                            <Grid item xs={7}>
                                                {opportunityData?.opportunity?.startPlayDate ? (
                                                    <Box sx={{ mb: "20px" }}>
                                                        <Typography
                                                            variant="h6"
                                                            display="block"
                                                            gutterBottom
                                                            sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                            Event Date
                                                        </Typography>

                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "start",
                                                                alignItems: "start",
                                                                mr: "20px",
                                                            }}>
                                                            <CalendarMonthIcon
                                                                fontSize="small"
                                                                sx={{ color: "gray", width: "15px", marginTop: "2px" }}
                                                            />
                                                            <Typography sx={{ marginLeft: "5px", fontSize: "1rem" }}>
                                                                {opportunityData?.opportunity?.formattedDate}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    ""
                                                )}
                                                {opportunityData?.opportunity?.venueName && (
                                                    <Box sx={{ mb: "20px" }}>
                                                        <Typography
                                                            variant="h6"
                                                            display="block"
                                                            gutterBottom
                                                            sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                            Location
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <LocationOnIcon
                                                                fontSize="small"
                                                                sx={{ color: "gray", width: "15px" }}
                                                            />
                                                            <Typography sx={{ marginLeft: "5px", fontSize: "1rem" }}>
                                                                {getLocationDetails(opportunityData?.opportunity)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {opportunityData?.opportunity?.primaryClientEM && (
                                                    <Box sx={{ mb: "20px" }}>
                                                        <Typography
                                                            variant="h6"
                                                            display="block"
                                                            gutterBottom
                                                            sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                            Event Managers
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <PersonIcon
                                                                fontSize="small"
                                                                sx={{ color: "gray", width: "15px" }}
                                                            />
                                                            <Typography sx={{ marginLeft: "5px", fontSize: "1rem" }}>
                                                                {opportunityData?.opportunity?.primaryClientEM}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Grid>
                                            <Grid item xs={5}>
                                                {opportunityData?.opportunity?.speakerAttire && (
                                                    <Box sx={{ mb: "20px" }}>
                                                        <Typography
                                                            variant="h6"
                                                            display="block"
                                                            gutterBottom
                                                            sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                            Attire
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            {/* <AccessibilityNewIcon fontSize="medium" sx={{ color: 'gray', width: '20px' }} /> */}
                                                            <Typography sx={{ fontSize: "1rem" }}>
                                                                {opportunityData?.opportunity?.speakerAttire}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {opportunityData?.weatherForecast?.playDateForecast && (
                                                    <Box sx={{ mb: "20px" }}>
                                                        <Typography
                                                            variant="h6"
                                                            display="block"
                                                            gutterBottom
                                                            sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                            Weather Forecast
                                                        </Typography>

                                                        {opportunityData?.weatherForecast?.playDateForecast && (
                                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            opportunityData?.weatherForecast
                                                                                .playDateForecast.conditionIcon
                                                                        }></img>{" "}
                                                                </Box>
                                                                <Box sx={{}}>
                                                                    <Box
                                                                        sx={{
                                                                            borderLeft: "1px solid lightgray",
                                                                            paddingLeft: "15px",
                                                                        }}>
                                                                        <Typography>
                                                                            {
                                                                                opportunityData?.weatherForecast
                                                                                    .playDateForecast.condition
                                                                            }
                                                                        </Typography>
                                                                        <Typography>
                                                                            High of{" "}
                                                                            {
                                                                                opportunityData?.weatherForecast
                                                                                    .playDateForecast.highF
                                                                            }
                                                                            &#8457;
                                                                        </Typography>
                                                                        <Typography>
                                                                            Low of{" "}
                                                                            {
                                                                                opportunityData?.weatherForecast
                                                                                    .playDateForecast.lowF
                                                                            }
                                                                            &#8457;
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                )}
                                                {opportunityData?.weatherForecast?.current &&
                                                    !opportunityData?.weatherForecast?.playDateForecast && (
                                                        <Box>
                                                            <Typography
                                                                variant="h6"
                                                                display="block"
                                                                gutterBottom
                                                                sx={{ color: config.primaryColor, fontSize: "16px" }}>
                                                                Current Weather
                                                            </Typography>

                                                            {opportunityData?.weatherForecast?.current && (
                                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            alignItems: "center",
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                opportunityData?.weatherForecast.current
                                                                                    .conditionIcon
                                                                            }></img>{" "}
                                                                    </Box>
                                                                    <Box sx={{}}>
                                                                        <Box
                                                                            sx={{
                                                                                borderLeft: "1px solid lightgray",
                                                                                paddingLeft: "15px",
                                                                            }}>
                                                                            <Typography>
                                                                                {
                                                                                    opportunityData?.weatherForecast
                                                                                        .current.condition
                                                                                }
                                                                            </Typography>
                                                                            <Typography>
                                                                                Feels Like{" "}
                                                                                {
                                                                                    opportunityData?.weatherForecast
                                                                                        .current.feelsLikeF
                                                                                }
                                                                                &#8457;
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider sx={{ mx: "-15px", mt: "15px" }} />
                                {!shared && (
                                    <Grid container spacing={3} sx={{ flexGrow: 1, my: "3px" }}>
                                        <Grid xs={6} md={3} sx={{ pb: "0px" }}>
                                            {/* <Button id="pre-event-survay" onClick={() => handlePreEventSurvayClick()} sx={{ ...styles.outlinedButton }} startIcon={<DataSaverOffIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Pre-Event Survey</Button> */}
                                        </Grid>
                                        <Grid
                                            xs={6}
                                            md={3}
                                            mdOffset="auto"
                                            sx={{ pb: "0px", display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                id="leave-comment"
                                                onClick={() => handleOpenComment("")}
                                                sx={{ ...styles.outlinedButton }}
                                                startIcon={
                                                    <AddCommentIcon
                                                        fontSize="small"
                                                        sx={{ color: config.primaryColor }}
                                                    />
                                                }
                                                variant="outlined">
                                                Comments
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>

                        {opportunityData?.steps && opportunityData?.currentStep && (
                            <Card sx={{ ...styles.card, mt: "15px", padding: "20px" }}>
                                <Box sx={{ width: "100%", mb: "20px" }}>
                                    <Typography
                                        variant="h3"
                                        sx={{ mb: "20px", fontSize: "30px", fontWeight: "300", textAlign: "center" }}>
                                        Next Steps
                                    </Typography>
                                    <Stepper
                                        alternativeLabel
                                        sx={{}}
                                        activeStep={opportunityData.currentStep.order - 1}>
                                        {opportunityData.steps.map((step) => (
                                            <Step key={step.id} expanded>
                                                <StepLabel>{step.name}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                {opportunityData.currentStep && opportunityData.currentStep.url && (
                                    <Alert
                                        severity="info"
                                        action={
                                            <Button
                                                onClick={() => navigateToForm(opportunityData.currentStep.url)}
                                                sx={{ ...styles.outlinedButton, width: "100%" }}
                                                startIcon={
                                                    <LaunchIcon fontSize="small" sx={{ color: config.primaryColor }} />
                                                }
                                                variant="outlined">
                                                {opportunityData.currentStep.urlLabel}
                                            </Button>
                                        }>
                                        <AlertTitle>
                                            {opportunityData.currentStep.name} - {opportunityData.currentStep.url}
                                        </AlertTitle>
                                        <Box
                                            sx={{ maxWidth: "80%" }}
                                            dangerouslySetInnerHTML={{
                                                __html: opportunityData.currentStep.description,
                                            }}
                                        />
                                    </Alert>
                                )}
                                {opportunityData.currentStep && !opportunityData.currentStep.url && (
                                    <Alert severity="info">
                                        <AlertTitle>{opportunityData.currentStep.name}</AlertTitle>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: opportunityData.currentStep.description,
                                            }}
                                        />
                                    </Alert>
                                )}
                            </Card>
                        )}

                        {/*                         
                        <Card sx={{ ...styles.card, mt: '15px', padding: '0px' }}>
                            <CardContent sx={{ paddingTop: '15px', p: 0 }}>
                                <Box sx={{ width: '100%' }}>
                                    <TabContext value={selectedTab}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList variant="scrollable" scrollButtons="auto" onChange={handleTabChange} sx={{ paddingLeft: '1rem' }} aria-label="lab API tabs example">
                                                {!opportunityData?.sections ? '' :
                                                    opportunityData?.sections.map(item => (
                                                        <AntTab icon={iconMapping[item.iconName] ? iconMapping[item.iconName] : iconMapping['Default']} iconPosition="start" key={item.id} label={item.name} value={item.sectionTabValue} />
                                                    ))
                                                }
                                            </TabList>
                                        </Box>
                                        {!opportunityData?.sections ? '' :
                                            opportunityData?.sections.map(item => (
                                                <TabPanel key={item.id} value={item.sectionTabValue}>
                                                    <Box >
                                                        {item.sectionTabValue === "Itinerary" ?
                                                            <>
                                                                <Itinerary shared={shared} recordId={recordId} activities={JSON.parse(item.data)}></Itinerary>
                                                            </> :
                                                            <>
                                                                <Section section={item} recordId={recordId}></Section>
                                                            </>
                                                        }
                                                    </Box>
                                                </TabPanel>
                                            ))
                                        }

                                    </TabContext>
                                </Box>
                            </CardContent>
                        </Card> */}
                    </div>
                )}

                <Modal
                    open={openShareLinkModal}
                    title="Shared Link"
                    onClose={handleShareLinkModalClose}
                    hideActions
                    maxWidth="md">
                    <Box>
                        <div>Share the proposal with your team.</div>

                        <CopyClipboardText label="View Only Link" value={sharedUrl}></CopyClipboardText>

                        <div>Share your proposal with </div>
                        <CopyClipboardText label="Collaboration Link" value={loginRequiredUrl}></CopyClipboardText>
                    </Box>
                </Modal>
            </div>
        </>
    );

    return getPageContent(body, status, error);
};

export { EventDetail };
