import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Box, Typography } from "@mui/material";

import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import { query, save, deleteRecord } from "../../services/object";
import WarningIcon from "@mui/icons-material/Warning";
import { get, post } from "../../api";
import { iconMapping } from "../DocumentUpload/iconMapping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { randomNumber } from "../../utils";

import { getFileUrl } from "../../services/s3";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Document = ({ documentRecord, parentId }) => {
    const [loading, setLoading] = useState(documentRecord ? false : true);
    const [record, setRecord] = useState(documentRecord ? { ...documentRecord } : undefined);
    useEffect(() => {
        if (!record) {
            getDocuments();
        }
    }, []);

    useEffect(() => {
        setRecord(documentRecord ? { ...documentRecord } : undefined);
    }, [documentRecord]);

    const handleDownload = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (record) {
            const { isSuccess, data } = await getFileUrl({ fileId: record.fileId });
            if (isSuccess) {
                downloadFile(data, record.name);
            }
        }
    };

    const downloadFile = (downloadUrl, name) => {
        const downloadContainer = document.querySelector(".download-container");
        const fileName = name;

        let a = document.createElement("a");
        a.href = downloadUrl;
        a.target = "_blank";
        // Use a.download if available, it prevents plugins from opening.
        a.download = fileName;
        // Add a to the doc for click to work.
        if (downloadContainer) {
            downloadContainer.appendChild(a);
        }
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        }
    };

    const getDocuments = () => {
        setLoading(true);

        const result = get("document/getRelatedRecordWithURL", { parentId: parentId }).then((result) => {
            if (result.isSuccess) {
                let documentData = result.data;
                if (documentData?.length > 0) {
                    setRecord(documentData[0]);
                }
            }
            setLoading(false);
        });
    };

    const hasError = () => {
        if (!loading && (!record || !record.fileId)) {
            return true;
        }
        return false;
    };

    const fileURL = () => {
        if (record?.localFileURL) {
            return record.localFileURL;
        }

        return record?.thumbnailURL ? record?.thumbnailURL : record?.fileURL;
    };

    const getExtension = (name) => {
        if (name) {
            return name.substring(name.lastIndexOf(".") + 1);
        }
        return "";
    };

    const getColor = (fileName) => {
        const extension = getExtension(fileName);
        const color = iconMapping[extension]?.color;
        return color ? color : "#413ab4";
    };

    const getIcon = (fileName) => {
        const extension = getExtension(fileName);
        const icon = iconMapping[extension]?.icon;
        return icon ? icon : faFile;
    };

    const fileType = () => {
        return record?.type?.startsWith("image") ? "image" : record?.type?.startsWith("video") ? "video" : "other";
    };

    const handleVideoFocus = (event) => {
        event.stopPropagation();
        event.preventDefault();
        event.target?.classList.add("active");
        let videoEle = event.target;
        if (videoEle) {
            videoEle.muted = true;
            videoEle.play();
        }
    };

    const stop = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const elements = document.querySelectorAll(".video-center.active");
        for (const element of elements) {
            element.classList.remove("active");
            let videoEle = element;
            if (videoEle) {
                videoEle.pause();
                videoEle.muted = true;
            }
        }
    };
    return (
        <Box
            onClick={handleDownload}
            sx={{
                width: "100%",
            }}
        >
            {record && (
                <>
                    {record?.status === "started" && (
                        <Box className="progress-container">
                            {" "}
                            <CircularProgressWithLabel
                                value={record.percentage || randomNumber(5, 25)}
                            ></CircularProgressWithLabel>{" "}
                        </Box>
                    )}
                    {fileType() === "image" && <img alt="fileimage" src={fileURL()} className="image"></img>}
                    {fileType() === "other" && (
                        <Box sx={{ color: getColor(record.fileName) }} className="other">
                            <FontAwesomeIcon icon={getIcon(record.fileName)} />
                        </Box>
                    )}
                    {fileType() === "video" && (
                        <Box className="other video-container" sx={{ background: "black" }}>
                            <video
                                loop
                                className="image selected-image video video-center"
                                onMouseEnter={handleVideoFocus}
                                onMouseLeave={stop}
                                style={{ width: "100%" }}
                            >
                                <source src={fileURL()} type="video/mp4" />
                            </video>
                        </Box>
                    )}

                    {/* <Box>{document.name}</Box> */}
                </>
            )}
            {hasError() && (
                <Box className="warning">
                    <WarningIcon></WarningIcon>
                </Box>
            )}

            {loading && (
                <Box sx={{ color: "white" }} className="warning">
                    <CircularProgress color="inherit" />
                </Box>
            )}
        </Box>
    );
};

export { Document };
