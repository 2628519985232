import { createSlice } from "@reduxjs/toolkit";
import { query, update, create } from "../../services/salesforce";

export const speakerHoldsSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "speakerHolds",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = "succeeded";
            state.error = null;
        },
        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? "initalLoading" : "succeeded";
        },
        loading: (state, action) => {
            state.status = action.payload ? "loading" : "succeeded";
        },
        saveRequest: (state, action) => {
            let payload = action.payload;
            state.data.records = state.data.records.map((item) => {
                if (item.id === payload.recordId) {
                    item = payload.data;
                }
                return item;
            });
            state.data = { ...state.data };
            state.status = "succeeded";
        },
    },
});

export default speakerHoldsSlice.reducer;

// Export all the reducers actions here
export const { initialize, error, initialLoading, saveRequest, loading } = speakerHoldsSlice.actions;

// Public Selectors
export const selectSpeakerHoldsData = (state) => state.speakerHolds.data;
export const selectSpeakerHoldsStatus = (state) => state.speakerHolds.status;
export const selectSpeakerHoldsError = (state) => state.speakerHolds.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true));
    const userDetails = await props.getUserDetails();
    const result = await query("calendarEntry", "getSpeakerRequest", { accountId: userDetails?.contact?.accountId });
    if (result.isSuccess) {
        dispatch(initialize({ records: result.data, userDetails: userDetails }));
    } else {
        dispatch(error(result.errorMessage));
    }
};

export const updateRequestAsync = (props) => async (dispatch) => {
    if (props.displayLoading) {
        dispatch(loading(true));
    }
    const result = await update("calendarEntry", props.record);
    if (result.isSuccess) {
        dispatch(saveRequest({ recordId: props.record.id, data: result.data }));
    } else {
        dispatch(error(result.errorMessage));
    }
};
