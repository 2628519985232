import { createSlice } from "@reduxjs/toolkit";
import { query, update, create, publicQuery } from "../../services/salesforce";
import { Auth } from "aws-amplify";
import * as loom from "@loomhq/loom-embed";
import { tr } from "date-fns/locale";

export const proposalSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "proposal",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
        hasAccess: true,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        initialize: (state, action) => {
            state.data[action.payload.key] = { ...state.data[action.payload.key], ...action.payload.data };
            state.status = "succeeded";
            if (action.payload.setAccess) {
                state.hasAccess = true;
            }
            state.error = null;
        },
        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        setAccess: (state, action) => {
            state.hasAccess = false;
            state.error = action.payload;
            state.status = "succeeded";
        },
        initialLoading: (state, action) => {
            state.hasAccess = true;
            state.status = action.payload ? "initalLoading" : "succeeded";
        },
        loading: (state, action) => {
            state.status = action.payload ? "loading" : "succeeded";
        },
        saveProposal: (state, action) => {
            let payload = action.payload;
            let data = state.data[payload.recordId];
            let found = false;
            data.records = data.records.map((item) => {
                if (item.id === payload.data.id) {
                    found = true;
                    return payload.data;
                }
                return item;
            });
            if (!found) {
                data.records.push(payload.data);
            }

            state.data[payload.recordId] = { ...state.data[payload.recordId], records: data.records };
            state.status = "succeeded";
        },
        updateOpportunityStage: (state, action) => {
            let payload = action.payload;
            let data = state.data[payload.recordId];
            data.opportunity.stageName = payload.data;

            state.data[payload.recordId] = { ...state.data[payload.recordId], opportunity: data.opportunity };
            state.status = "succeeded";
        },
        saveProposals: (state, action) => {
            let payload = action.payload;
            let data = state.data[payload.recordId];
            let mapSavedData = {};
            for (let record of payload.data) {
                mapSavedData[record.id] = record;
            }
            data.records = data.records.map((item) => {
                if (mapSavedData[item.id]) {
                    return mapSavedData[item.id];
                }
                return item;
            });

            state.data[payload.recordId] = { ...state.data[payload.recordId], records: data.records };
            state.status = "succeeded";
        },
        saveGroup: (state, action) => {
            let payload = action.payload;
            let data = state.data[payload.recordId];
            let found = false;
            data.groups = data.groups.map((item) => {
                if (item.id === payload.data.id) {
                    found = true;
                    return payload.data;
                }
                return item;
            });
            if (!found) {
                data.groups.push(payload.data);
            }

            state.data[payload.recordId] = { ...state.data[payload.recordId], groups: data.groups };
            state.status = "succeeded";
        },
        saveRequestAvailability: (state, action) => {
            let payload = action.payload;
            let data = state.data[payload.recordId];
            const isArray = Array.isArray(payload.data);
            if (!data.calendarEntries) {
                data.calendarEntries = [];
            }
            let records = isArray ? payload.data : [payload.data];
            if (payload.action === "create") {
                data.calendarEntries = [...data.calendarEntries, ...records];
            } else {
                let mapRecords = {};
                for (let record of records) {
                    mapRecords[record.id] = record;
                }
                data.calendarEntries = data.calendarEntries.map((item) => {
                    if (mapRecords[item.id]) {
                        return mapRecords[item.id];
                    }
                    return item;
                });
            }

            state.data[payload.recordId] = { ...state.data[payload.recordId], groups: data.groups };
            state.status = "succeeded";
        },
    },
});

export default proposalSlice.reducer;

// Export all the reducers actions here
export const {
    initialize,
    error,
    loading,
    initialLoading,
    saveProposal,
    saveGroup,
    saveProposals,
    updateOpportunityStage,
    saveRequestAvailability,
    setAccess,
} = proposalSlice.actions;

// Public Selectors
export const selectProposalData = (state) => state.proposal.data;
export const selectProposalStatus = (state) => state.proposal.status;
export const selectProposalError = (state) => state.proposal.error;
export const selectProposalHasAccess = (state) => state.proposal.hasAccess;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true));
    let resultOpportunity;

    resultOpportunity = await query("opportunity", "recordById", {
        recordId: props.recordId,
        queryFields: "getDetailQueryFields",
        application: props.application,
        contactId: props.contactId,
    });

    if (resultOpportunity.isSuccess && resultOpportunity.data?.length > 0) {
        if (resultOpportunity.data[0]?.proposalVideo) {
            try {
                let url = resultOpportunity.data[0]?.proposalVideo;
                var newURL = new URL(url);
                newURL.search = ""; // Remove all parameters
                url = newURL.href;
                loom.oembed(url).then((loomData) => {
                    let data = { proposalVideo: loomData };
                    dispatch(initialize({ data, key: props.recordId }));
                });
            } catch (error) {
                console.log(error);
            }
        }

        let data = { opportunity: resultOpportunity.data[0] };
        console.log(data);
        props.trackEvent(
            props.getTrackEventData(
                "view",
                "proposal",
                `Viewed ${data?.opportunity?.eventName} proposal`,
                {},
                "",
                "proposal"
            )
        );
        dispatch(initialize({ data, key: props.recordId, setAccess: true }));
    } else if (resultOpportunity.isSuccess) {
        dispatch(setAccess("No access"));
    } else {
        dispatch(error(resultOpportunity.errorMessage));
    }
};

export const getProposedSpeakerAsync = (props) => async (dispatch) => {
    let result;
    let tourStatus;
    result = await query("proposedSpeaker", "proposedSpeakers", {
        recordId: props.recordId,
        proposalId: props.proposalId,
        orderByField: "Proposal_Group__r.Order__c, Order__c",
        application: props.application,
        contactId: props.contactId,
    });
    tourStatus = await getUserInfo();

    if (result.isSuccess) {
        let data = { records: result.data, tourStatus: tourStatus };

        let speakers = [];
        let opportunityId;
        for (let record of data.records) {
            opportunityId = record.opportunityId;
            if (
                record.status === "Availability Requested" ||
                record.status === "Request To Book" ||
                record.status === "Request To Book Approved"
            ) {
                speakers.push(record.speakerId);
            }  
        }

        if (speakers.length > 0 && !props.shared) {
            dispatch(getCalendarEnrtiesAsync({ speakers, opportunityId, recordId: props.recordId }));
        }

        dispatch(initialize({ data, key: props.recordId }));
    } else {
        dispatch(error(result.errorMessage));
    }
};

export const getCalendarEnrtiesAsync = (props) => async (dispatch) => {
    const result = await query("calendarEntry", "getApprovedCalenderEntriesRequest", {
        speakers: props.speakers.join(","),
        opportunityId: props.opportunityId,
    });
    if (result.isSuccess) {
        let data = { calendarEntries: result.data };
        console.log("Speaker Calendar Entries", data);
        dispatch(initialize({ data, key: props.recordId }));
    } else {
        dispatch(error(result.errorMessage));
    }
};

export const saveProposalAsync = (props) => async (dispatch) => {
    dispatch(saveProposal({ recordId: props.recordId, data: props.data }));
    props.callback({ isSuccess: true, data: props.data });
    const result = await update("proposedSpeaker", props.data);
    if (!result.isSuccess) {
        dispatch(error(result.errorMessage));
    } else {
        dispatch(updateOpportunityStage({ recordId: props.recordId, data: result.data.opportunityStageName }));
    }
};

export const saveGroupAsync = (props) => async (dispatch) => {
    dispatch(loading(true));
    const result = await create("proposalGroup", props.data);
    if (result.isSuccess) {
        dispatch(saveGroup({ recordId: props.recordId, data: result.data }));
        props.callback(result);
    } else {
        dispatch(error(result.errorMessage));
    }
};

export const updateProposalGroupAsync = (props) => async (dispatch) => {
    dispatch(loading(true));
    const result = await update("proposedSpeaker", props.data);
    if (result.isSuccess) {
        dispatch(saveProposals({ recordId: props.recordId, data: result.data }));
        props.callback(result);
    } else {
        dispatch(error(result.errorMessage));
    }
};

export const saveRequestAvailabilityAsync = (props) => async (dispatch) => {
    dispatch(loading(true));

    if (props.data.id) {
        const result = await update("calendarEntry", props.data);
        if (result.isSuccess) {
            props.callback(result);
            dispatch(saveRequestAvailability({ recordId: props.recordId, data: result.data, action: "update" }));
        } else {
            dispatch(error(result.errorMessage));
        }
    } else {
        const result = await create("calendarEntry", props.data);
        if (result.isSuccess) {
            props.callback(result);
            dispatch(saveRequestAvailability({ recordId: props.recordId, data: result.data, action: "create" }));
        } else {
            dispatch(error(result.errorMessage));
        }
    }
};

async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    console.log("attributes:", user.attributes);
    if (user.attributes["custom:tour_status"] !== undefined) {
        return user.attributes;
    }
    return false;
}
