import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Divider
  } from '@mui/material';


import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Grid container wrap="nowrap" spacing={0} sx={{ position: "relative" }}>
            <Grid xs={4}
                    sx={{
                        height: "calc(100vh - 114px)",
                        background: "#FAFAF9",
                        p: "1.2rem",
                        display: { xs: "none", lg: "block" },
                        width: "330px",
                        position: "absolute",
                        top: "2px",
                        overflow: "scroll",
                    }}
                    item>
                <Skeleton variant="rounded" width={294} height={294} />
                <Skeleton sx={{mt: '1.2rem'}} variant="rounded" width='80%' height={20} />
                <Skeleton sx={{mt: '1rem'}} variant="rounded" width='120px' height={20} />
                <Skeleton sx={{mt: '1rem'}} variant="rounded" width='100%' height={80} />
                <Skeleton sx={{mt: '2rem'}} variant="rounded" width='100%' height={30} />
                <Skeleton sx={{mt: '1rem'}} variant="rounded" width='100%' height={30} />
                <Divider sx={{mt: '1rem'}}></Divider>
                <Skeleton sx={{mt: '1rem'}} variant="rounded" width='100%' height={30} />
                <Skeleton sx={{mt: '1rem'}} variant="rounded" width='100%' height={30} />
            </Grid>
            <Grid lg={8}
                xs={12}
                item
                sx={{
                    minWidth: "calc(100% - 330px - 1.5rem)",
                    position: "absolute",
                    overflow: "scroll",
                    height: "calc(100vh - 114px)",
                    // left: "calc(330px + 1rem)",
                    left: { xs: "1rem", lg: "calc(330px + 1rem)" },
                    mt: { xs: "1px", lg: "0px" },
                }}
                className="right-side"
            >
                <Grid wrap="nowrap" container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    
                </Grid>
                <Divider></Divider>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={80} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={60} />
                </Box>

                <Divider></Divider>

                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={80} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={80} />
                </Box>

                <Divider></Divider>

                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={80} />
                </Box>

                <Divider></Divider>
            </Grid>
            
        </Grid>
    </div>
)

