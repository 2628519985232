import { createContext, useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { Auth } from "aws-amplify";
import { query } from "../services/salesforce";

const AuthContext = createContext();

const randomDigits = (number) => {
    const randomValues = new Uint8Array(number);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
};

const intToHex = (number) => {
    return number.toString(16).padStart(2, "0");
};

const getAllUrlParams = (url) => {
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    let obj = {};

    if (queryString) {
        queryString = queryString.split("#")[0];

        let arr = queryString.split("&");

        for (let i = 0; i < arr.length; i++) {
            let a = arr[i].split("=");

            let paramName = a[0];
            let paramValue = typeof a[1] === "undefined" ? true : a[1];

            paramName = paramName.toLowerCase();
            if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

            if (paramName.match(/\[(\d+)?\]$/)) {
                let key = paramName.replace(/\[(\d+)?\]/, "");
                if (!obj[key]) obj[key] = [];

                if (paramName.match(/\[\d+\]$/)) {
                    let index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    obj[key].push(paramValue);
                }
            } else {
                if (!obj[paramName]) {
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === "string") {
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
};

export const AuthProvider = ({ children, application }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const redirectLocation = location.state?.path || "/";
    const answerSearchParams = location.state?.searchParams;

    const passwordLessLogin = async (data) => {
        let type =
            data.application === "preview"
                ? "user"
                : redirectLocation && getAllUrlParams(redirectLocation)
                ? getAllUrlParams(redirectLocation).type
                : "";
        const params = {
            username: data.email,
            password: data.password ? data.password : randomDigits(10),
            attributes: {
                name: data.email,
                email: data.email,
            },
            validationData: { type: type ? type : "" },
        };
        let hasError = false;
        try {
            await Auth.signUp(params);
        } catch (e) {
            if (e.message.indexOf("Email not found") > -1) {
                hasError = true;
            }
            console.log(e);
        }
        if (!hasError) {
            try {
                const thisUser = await Auth.signIn(data.email);
                setUser(thisUser);
                navigate("/answer", {
                    replace: true,
                    state: {
                        path: redirectLocation,
                        searchParams: { simulation: searchParams.get("simulation"), type: type },
                    },
                });
            } catch (e) {
                console.log(e);
                setTimeout(() => window.location.reload(), 2000);
            }
        } else {
            data.callback(true);
        }
    };

    const login = async (data) => {
        try {
            const thisUser = await Auth.signIn(data.email, data.password);
            setUser(thisUser);
            navigate(redirectLocation, { replace: true });
        } catch (e) {
            console.log(e);
            setTimeout(() => window.location.reload(), 2000);
        }
    };

    const answer = async (userInfo, answer, callback) => {
        try {
            let user = userInfo;
            if (user.challengeName === "CUSTOM_CHALLENGE") {
                user = await Auth.sendCustomChallengeAnswer(user, answer);
                await Auth.currentSession();
                let userData = await Auth.currentAuthenticatedUser();
                setUser(userData);
                const userDetails = await getUserDetails();
                debugger;
                if (
                    (answerSearchParams?.type === "anonymous" || answerSearchParams?.type === "public") &&
                    !userDetails?.contact
                ) {
                    navigate(redirectLocation.replace("shared/proposal", "contact-details"), { replace: true });
                } else {
                    navigate(redirectLocation, { replace: true });
                }
            }
        } catch (e) {
            console.log(e);
            callback(e.message);
            if (e.message) {
                setTimeout(() => {
                    navigate("/login", {
                        replace: true,
                        state: { path: redirectLocation, searchParams: { simulation: searchParams.get("simulation") } },
                    });
                }, 6 * 1000);
            }
        }
    };

    const logout = async () => {
        await Auth.signOut();
        setUser(null);
        localStorage.clear();
        navigate("/login" + (location.state?.query ? location.state?.query : ""), {
            replace: true,
            state: { path: location.state?.path },
        });
    };

    const isAuthenticated = async () => {
        try {
            let user = await Auth.currentSession();
            return true;
        } catch {
            return false;
        }
    };

    const getUserDetailsNotAsync = () => {
        let jwtToken = user?.signInUserSession?.accessToken?.jwtToken;
        if (jwtToken) {
            const userData = localStorage.getItem(jwtToken);
            if (userData) {
                return JSON.parse(userData);
            }
        }
        return "";
    };

    const setUserDetailsNotAsync = (details) => {
        let jwtToken = user?.signInUserSession?.accessToken?.jwtToken;
        if (jwtToken) {
            localStorage.setItem(jwtToken, JSON.stringify(details));
        }
        return "";
    };

    const getUserDetails = async () => {
        if (!user) {
            let user = await Auth.currentAuthenticatedUser();
            setUser(user);
        }

        let jwtToken = user.signInUserSession.accessToken.jwtToken;
        if (jwtToken) {
            const userData = localStorage.getItem(jwtToken);
            if (userData) {
                return JSON.parse(userData);
            } else {
                let result;
                if (application !== "preview" && answerSearchParams?.type !== "user") {
                    if (answerSearchParams?.simulation) {
                        result = await query("contact", "getContactByEmail", {
                            email:
                                application === "speaker"
                                    ? "stephenson2755@gmail.com"
                                    : "yvonne.studer@chase.com.invalid",
                        });
                    } else {
                        result = await query("contact", "getContactByEmail", {
                            email: encodeURIComponent(user.username),
                        });
                    }
                }

                if (result?.isSuccess && result?.data?.length > 0) {
                    user["contact"] = result.data[0];
                }
                if (answerSearchParams?.type) {
                    user["userType"] = application === "preview" ? "user" : answerSearchParams?.type;
                }
                if (application === "preview" || answerSearchParams?.type === "user") {
                    user["loggedInAsUser"] = true;
                }
                user["application"] = application;
                let failed = false;
                try {
                    localStorage.setItem(jwtToken, JSON.stringify(user));
                } catch (error) {
                    failed = true;
                    localStorage.clear();
                }

                if (failed) {
                    localStorage.setItem(jwtToken, JSON.stringify(user));
                }

                return user;
            }
        }

        return user;
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                getUserDetails,
                passwordLessLogin,
                login,
                answer,
                logout,
                getUserDetailsNotAsync,
                setUserDetailsNotAsync,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    return useContext(AuthContext);
};

export { useAuth };
