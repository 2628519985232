import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent } from "../../utils";
import { selectContactData, selectContactStatus, selectContactError, initializeAsync, loading } from "./contactSlice";

// Add custom imports here

import { create } from "../../services/salesforce";

import {
    Box,
    Card,
    Button,
    TextField,
    Typography,
    CardContent,
    MenuItem,
    Divider,
    Drawer,
    Menu,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
} from "@mui/material";

import { config, styles } from "../../style";
import Grid from "@mui/material/Unstable_Grid2";
import { useAuth } from "../../hooks/useAuth";

const Contact = () => {
    const data = useSelector(selectContactData);
    const status = useSelector(selectContactStatus);
    const error = useSelector(selectContactError);
    const dispatch = useDispatch();

    // Add Variables

    const [subject, setSubject] = useState("");
    const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
    const [reason, setReason] = useState("");
    const [reasonErrorMessage, setReasonErrorMessage] = useState("");
    const [type, setType] = useState("");
    const [typeErrorMessage, setTypeErrorMessage] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
    const { getUserDetails } = useAuth();

    useEffect(() => {
        if (status === "idle") {
            dispatch(initializeAsync({ getUserDetails }));
        }
    }, [status, dispatch, getUserDetails]);

    const getSalesforcePicklist = (field) => {
        if (data?.describe) {
            return data?.describe.fields.find((item) => item.name === field)?.picklistValues;
        }
        return [];
    };

    const reset = () => {
        setSubject("");
        setSubjectErrorMessage("");
        setReason("");
        setReasonErrorMessage("");
        setType("");
        setTypeErrorMessage("");
        setDescription("");
        setDescriptionErrorMessage("");
    };

    const submit = async () => {
        let hasError = false;
        if (!subject) {
            hasError = true;
            setSubjectErrorMessage("This is required field.");
        }
        if (!reason) {
            hasError = true;
            setReasonErrorMessage("This is required field.");
        }
        if (!type) {
            hasError = true;
            setTypeErrorMessage("This is required field.");
        }
        if (!description) {
            hasError = true;
            setDescriptionErrorMessage("This is required field.");
        }

        if (!hasError) {
            let record = {
                accountId: data?.userDetails?.contact?.accountId,
                origin: "Web",
                reason: reason,
                contactId: data?.userDetails?.contact?.id,
                description: description,
                priority: "Medium",
                status: "New",
                subject: subject,
                type: type,
                ownerId: data?.userDetails?.contact?.accountOwnerId,
            };
            dispatch(loading(true));
            const result = await create("case", record);
            if (result.isSuccess) {
                const detailEvent = new CustomEvent("openToster", {
                    detail: { variant: "success", message: "Successfully create the case" },
                });
                document.dispatchEvent(detailEvent);
                reset();
            } else {
                const detailEvent = new CustomEvent("openToster", {
                    detail: { variant: "error", message: result.errorMessage },
                });
                document.dispatchEvent(detailEvent);
            }

            dispatch(loading(false));
        }
    };

    let body = (
        <Box sx={styles.container}>
            <Card sx={styles.card}>
                <CardContent sx={{ pt: "15px", pb: "15px" }}>
                    <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography sx={styles.pageHeading}>Contact WSB</Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={reset} variant="outlined">
                                Reset
                            </Button>
                            <Button onClick={submit} sx={{ ml: "1rem" }} variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card sx={{ ...styles.card, mt: "1rem" }}>
                <CardContent sx={{ pt: "15px", pb: "15px" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid xs={12} item>
                            <TextField
                                required
                                fullWidth
                                value={subject}
                                error={!!subjectErrorMessage}
                                onChange={(event) => {
                                    setSubject(event.target.value);
                                    setSubjectErrorMessage("");
                                }}
                                label="Subject"
                                variant="outlined"
                                helperText={subjectErrorMessage}
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <FormControl fullWidth>
                                <InputLabel
                                    required
                                    error={!!reasonErrorMessage}
                                    helperText={reasonErrorMessage}
                                    id="reason-label"
                                >
                                    Reason
                                </InputLabel>
                                <Select
                                    required
                                    labelId="reason-label"
                                    id="reason-select"
                                    label="Reason"
                                    value={reason}
                                    onChange={(event) => {
                                        setReason(event.target.value);
                                        setReasonErrorMessage("");
                                    }}
                                    error={!!reasonErrorMessage}
                                    helperText={reasonErrorMessage}
                                >
                                    {getSalesforcePicklist("Reason").map((item) => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                                {reasonErrorMessage && (
                                    <FormHelperText error={true}>{reasonErrorMessage}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={6} item>
                            <FormControl fullWidth>
                                <InputLabel
                                    required
                                    error={!!typeErrorMessage}
                                    helperText={typeErrorMessage}
                                    id="type-label"
                                >
                                    Type
                                </InputLabel>
                                <Select
                                    required
                                    labelId="type-label"
                                    id="type-select"
                                    label="Type"
                                    value={type}
                                    onChange={(event) => {
                                        setType(event.target.value);
                                        setTypeErrorMessage("");
                                    }}
                                    error={!!typeErrorMessage}
                                    helperText={typeErrorMessage}
                                >
                                    {getSalesforcePicklist("Type").map((item) => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                                {typeErrorMessage && <FormHelperText error={true}>{typeErrorMessage}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                required
                                fullWidth
                                value={description}
                                multiline
                                rows={4}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                    setDescriptionErrorMessage("");
                                }}
                                label="Description"
                                variant="outlined"
                                error={!!descriptionErrorMessage}
                                helperText={descriptionErrorMessage}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );

    return getPageContent(body, status, error);
};

export { Contact };
