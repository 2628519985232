import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Card,
    CardContent,
    Divider
  } from '@mui/material';

import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Card sx={styles.card}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                    <Grid item>
                        <Skeleton variant="rounded" width={400} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                </Grid>
                <Grid sx={{my: '15px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                    </Grid>
                </Grid>
                <Divider sx={{mx: '-15px'}}/>  
                <Grid sx={{my: '15px'}} container wrap="nowrap" spacing={0} justifyContent="start">
                    <Grid item>
                        <Skeleton sx={{mr: '10px'}} variant="circular" width={20} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={150} height={20} />
                    </Grid>
                </Grid>
                <Box>
                    <Skeleton variant="rounded" width="100%" height={40} />
                </Box>
                <Divider sx={{mx: '-15px', mt: '15px'}}/>  

                <Grid container wrap="nowrap" spacing={3} sx={{ flexGrow: 1, my: '3px' }}>
                    <Grid xs={6} xsOffset={3} md={2} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={4} md={2} mdOffset="auto">
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={4} xsOffset={4} md={2} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
                
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px', padding: '0px'}}>
            <CardContent sx={{paddingTop: '15px', p: 0}}>
            <Grid container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={70} height={10} />
                    </Grid>
                </Grid>
                <Divider></Divider>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={80} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={60} />
                </Box>

                <Divider></Divider>

                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={80} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={80} />
                </Box>

                <Divider></Divider>

                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between" sx={{p: '1rem'}}>
                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={20} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                </Grid>
                <Box sx={{ p: '1rem', pt: '0px'}}>
                    <Skeleton variant="rounded" width='100%' height={80} />
                </Box>

                <Divider></Divider>
            </CardContent>
        </Card>

        
        
    </div>
)

