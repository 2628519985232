import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import {
    selectEventsData,
    selectEventsStatus,
    selectEventsError,
    initializeAsync
} from './eventsSlice';

import {
    Button,
    Box,
    Paper,
    Menu,
    MenuItem
} from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import { config, styles } from '../../style';
import { EventCard } from "../../components/EventCard"
import {skeleton} from './skeleton';


// Add custom imports here

const Events = () => {
    const data = useSelector(selectEventsData);
    const status = useSelector(selectEventsStatus);
    const error = useSelector(selectEventsError);
    const dispatch = useDispatch();
    const { getUserDetails } = useAuth();
    const navigate = useNavigate()
    // Add Variables

    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);
    const handleFilterMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (filterValue) => {
        seteventFilter(filterValue);
        setAnchorEl(null);
    }

    const [eventFilter, seteventFilter] = useState('All');



    useEffect(() => {
        if(status === 'idle') {
            dispatch(initializeAsync({ getUserDetails }));
        }
    }, [status, dispatch, getUserDetails]);

    const handleCardClick = (recordId) => {
        navigate('/event-detail/' + recordId)
    }

    let body = (
        <>
            {status === 'initalLoading' ? skeleton : 
            <>
            <Box>
                <Paper sx={{ p: '20px', display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <Box style={{ fontSize: '12px', color: 'gray' }}>
                        Events
                    </Box>
                    <Box style={{ textTransform: 'uppercase', fontWeight: '800', fontSize: '21px' }}>
                        {data?.userDetails?.contact?.accountName}
                    </Box>
                </Paper>
            </Box>
            <Box sx={{ marginTop: '1rem', mb: '20px' }}>
                <Paper sx={{ p: '20px' }} elevation={3}>
                    <Box sx={{ mb: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box>
                            <Button
                                id="filter-speakers"
                                aria-controls={filterOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={filterOpen ? 'true' : undefined}
                                onClick={handleFilterMenuClick}
                                startIcon={<FilterListIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                                sx={{ ...styles.outlinedButton, width: '100%' }}
                                variant="outlined"
                            >
                                {`Event Status: ${eventFilter}`}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={filterOpen}
                                onClose={() => handleFilterMenuClose("All")}
                                MenuListProps={{
                                    'aria-labelledby': 'filter-speakers',
                                }}

                            >
                                <MenuItem onClick={() => handleFilterMenuClose("All")} value="All">All</MenuItem>
                                <MenuItem onClick={() => handleFilterMenuClose("Open")} value="Open">Open Proposals</MenuItem>
                                <MenuItem onClick={() => handleFilterMenuClose("Closed")} value="Closed">Closed Proposals</MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                    <Grid container spacing={3} alignItems="stretch">


                        {data?.records?.map((record) => (
                            <Grid item key={record.id} lg={4} md={4} xs={12} onClick={() => handleCardClick(record.id)}>
                                <EventCard event={record} type="event"></EventCard>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Box>


            {/* <Box>
                <span style={{fontSize:'12px', color: 'gray'}}>
                    {data?.userDetails?.contact?.recordTypeName}
                </span>
            </Box>
            <Box>
                <span style={{textTransform: 'uppercase', fontWeight: '800'}}>
                    {data?.userDetails?.contact?.accountName}
                </span>
            </Box>
            <Box sx={{marginTop: '1rem'}}>
                <Grid container spacing={3}>
                    {data?.records?.map((record) => (
                        <Grid item key={record.id} lg={4} md={4} xs={12} onClick={() => handleCardClick(record.id)}>
                            <Card sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                                <CardContent sx={{p:0,  paddingBottom: '0px !important'}}>
                                    <Box sx={{ p: 0 }}>
                                    <Grid sx={{p:0}} container wrap="nowrap" spacing={2}>
                                        <Grid item sx={{background: '#dfdfdf', paddingTop: '15px', paddingLeft: '15px'}}>
                                            <AccountBalanceIcon sx={{width: '4rem', height: '4rem'}} color="action"/>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{padding: '15px'}}>
                                                <Box>
                                                    <Typography noWrap>{record?.name}</Typography>
                                                </Box>
                                                <Box>
                                                    <span style={{fontSize: '12px'}}>{record?.stageName}</span>
                                                </Box>
                                        </Grid>
                                    </Grid>

                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box> */}
            </>
            }
        </>
    )

    return getPageContent(body, status, error);
}

export { Events }