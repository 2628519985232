import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Divider,
    Typography,
    Button,
    Breadcrumbs,
    Link,
    TextField,
    IconButton,
    InputAdornment
  } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {config, styles} from '../../style';
import { DocumentUpload } from '../DocumentUpload';
import {Modal} from '../Modal';
import SearchIcon from '@mui/icons-material/Search';



export const DocumentUploadContainer = forwardRef((props, ref) => {
    const { parentId, userDetails } = props;
    const documentUploadRef = useRef(null);
    const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [searchText, setSearchText] = useState('');
    const [selectedId, setSelectedId] = useState(parentId)
    const [breadcrumbList, setBreadcrumbList] = useState([{id: parentId, name: 'Root'}])
    
    useImperativeHandle(ref, () => ({

        uploadFile(){
            documentUploadRef.current.uploadFile();
        },

        createFolder(){
            setOpenCreateFolderModal(true)
        }
    
    }));

    const handleFolderCreateSave = () => {
        if(folderName){
            documentUploadRef.current.saveFolder(folderName)
            setFolderName('');
            setOpenCreateFolderModal(false)
        }  
    }

    const handleChangeFolder = (details) => {
        setSelectedId(details.id);
        setBreadcrumbList([...breadcrumbList, details])
        setSearchText(''); 
        documentUploadRef.current.setSearchTextValue('')
    }

    const handleBreadCrumbLink = (item, index) => {
        let newList = [];
        for(let i = 0; i < breadcrumbList.length; i++){
            if(i !== index){
                newList.push(breadcrumbList[i])
            }else{
                newList.push(breadcrumbList[i])
                break;
            }
        }
        
        handleChangeFolder(item)
        setBreadcrumbList(newList);
    }


    return (
        <div>
            
            <Box sx={{margin: '-15px', marginBottom: '15px', background: 'white'}}>
                <Grid sx={{width: '100%', padding: '0'}} container wrap="nowrap" spacing={0} justifyContent="space-between"  alignItems='center'>
                    <Grid item>
                        <Box sx={{p: '15px'}}>
                            <div role="presentation">
                                <Breadcrumbs sx={{background: 'white', p: 0}} maxItems={4} aria-label="breadcrumb">
                                    {
                                        breadcrumbList && breadcrumbList.map((item, index) => (
                                            <>
                                                { index === breadcrumbList.length - 1 ? <Typography color="text.primary">{item.name}</Typography> :
                                                    <Link underline="hover" color="inherit" onClick={() => handleBreadCrumbLink(item, index)}>
                                                        {item.name}
                                                    </Link>
                                                }
                                            </>
                                            
                                        ))
                                    }
                                    
                                </Breadcrumbs>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{p: '15px'}}>
                        <TextField
                            label="Search.."
                            value={searchText}
                            onChange={(event) => {setSearchText(event.target.value); documentUploadRef.current.setSearchTextValue(event.target.value)}}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment>
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                
                <Divider></Divider>
            </Box>
            
            <DocumentUpload ref={documentUploadRef} parentId={selectedId} userDetails={userDetails} onChangeFolder={handleChangeFolder}></DocumentUpload>

            <Modal open={openCreateFolderModal} title="Create new folder" onClose={() => {setOpenCreateFolderModal(false); setFolderName('')}} onSave={handleFolderCreateSave} maxWidth="md" >
                <Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="otp"
                        label="Folder Name"
                        name="folder-name"
                        autoFocus
                        sx={{borderRadius: '8px'}}
                        variant="outlined"
                        onChange={(event) => setFolderName(event.target.value)}
                    />
                </Box>
            </Modal>
        </div>
    )
})