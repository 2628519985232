import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '../../components/Form';
import { getPageContent } from '../../utils';
import {
    selectFormContainerData,
    selectFormContainerStatus,
    selectFormContainerError,
    initializeAsync
} from './formContainerSlice';

// Add custom imports here

const FormContainer = () => {
    const data = useSelector(selectFormContainerData);
    const status = useSelector(selectFormContainerStatus);
    const error = useSelector(selectFormContainerError);
    const dispatch = useDispatch();

    // Add Variables

    let body = (
        <Form></Form>
    )

    return getPageContent(body, status, error);
}

export { FormContainer }