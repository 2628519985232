import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';

const CopyClipboardText = ({ label, value, onCopy, tooltip }) => {
    const [linkCopied, setLinkCopied,] = useState(false);

    const handleLinkCopy = () => {
        setLinkCopied(true);
        setTimeout(() => {
            setLinkCopied(false)
        }, 2000)
        onCopy();
    }

    return (

        <TextField
            fullWidth
            label={<>
                {label}
                {tooltip && <Tooltip title={tooltip}>
                    <IconButton>
                        <Info></Info>
                    </IconButton>
                </Tooltip>
                }

            </>}
            defaultValue=" "
            value={value}
            InputProps={{
                readOnly: true,
                endAdornment: <CopyToClipboard text={value}
                    onCopy={() => handleLinkCopy()}>
                    <Button sx={{
                        width: '130px',
                        marginRight: '-14px',
                        height: '57px',
                        borderRadius: '0 8px 8px 0',
                        border: '1px solid #efecec',
                        borderLeft: '1px solid #efecec',
                        background: '#f7f7f7',
                        color: linkCopied ? 'green' : ''
                    }}
                        variant="outlined">{linkCopied ? 'Copied!' : 'Copy link'}</Button>
                </CopyToClipboard>
            }}
            sx={{ mb: '20px' }}
        />
    )
}

export { CopyClipboardText }