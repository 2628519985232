import {get, post} from '../../api'

const query = async (objectName, conditionName, params) => {
    return new Promise(async(resolve, reject) => {
        get('object/query/' + objectName + '/' + (conditionName ? conditionName : 'q'), params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const save = async (objectName, body) => {
    return new Promise(async(resolve, reject) => {
        post('object/save/' + objectName, body)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const deleteRecord = async (objectName, body) => {
    return new Promise(async(resolve, reject) => {
        post('object/delete/' + objectName, body)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

export {query, save, deleteRecord}