import { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import CloseIcon from '@mui/icons-material/Close';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { User as UserIcon } from '../../icons/user';
import { format } from 'date-fns';
import { query } from '../../services/salesforce'
import {
    Box,
    Button,
    Typography,
    Divider,
    IconButton,
    Card,
    CardContent
} from '@mui/material';

import { Badge } from '../../components/Badge';

import Skeleton from '@mui/material/Skeleton';
import { config, styles } from '../../style';
import { useTracking } from 'react-tracking';

export const DashboardList = (props) => { 
    const [records, setRecords] = useState([])

    useEffect(() => {
        getRecords();
    }, []);

    const getRecords = async () => {
        const result = await query('speakerList', 'getDashboardList', {});
        if (result.isSuccess) {
            setRecords(result.data)
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                {records?.map(item => (
                    <Grid item lg={4}>
                        <Card sx={styles.card}>
                            <CardContent sx={{ }}>
                                <Grid container sx={{p: 0}}>
                                    <Grid item>
                                        <Typography sx={styles.cardHeading}>
                                            {item.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mx: '-15px', my: '10px' }} />
                                {
                                    item?.members?.map(member => (
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'start', 
                                            mb: '8px'
                                        }}>
                                            <Box sx={{ height: '30px'}}>
                                                {member.speakerImageURL && <Box component="img" sx={{ objectFit: 'cover', width: '30px', height: '30px', objectPosition: 'top', borderRadius: '100px' }} src={`${member.speakerImageURL}`}  ></Box>}
                                                {!member.speakerImageURL && <Skeleton variant="circular" width={30} height={30} />}
                                            </Box>
                                            <Box sx={{ml: '10px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <Box >
                                                    <Typography sx={styles.subHeading}>
                                                        {member.speakerName}
                                                    </Typography>
                                                    
                                                </Box>
                                            </Box>
                                            
                                        </Box>
                                    ))
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}