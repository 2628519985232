import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { format } from "date-fns";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    IconButton,
    Divider,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Switch,
    Link,
} from "@mui/material";

import { get, post } from "../../api";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { config, styles } from "../../style";

import { describe, query } from "../../services/salesforce";
import "./style.css";

import { getCanadaStates, getUSStates } from "./utils";
import GoogleAutocomplete from "../../components/GoogleAutocomplete";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { RichTextArea } from "../../components/RichTextArea";

const CustomAccordion = (props) => {
    const [expanded, setExpanded] = useState(true);
    let { label, children, isCompleted } = props;

    let id = label.replaceAll(" ", "-").toLowerCase();

    const handleClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Accordion
                className="request-to-book"
                sx={{ mb: expanded ? "0px" : "1rem", boxShadow: expanded ? "gray" : "none" }}
                defaultExpanded={true}
                expanded={expanded}>
                <AccordionSummary
                    sx={{
                        background: "#eeeeee",
                        minHeight: "auto !important",
                        borderRadius: expanded ? "7px 7px 0 0" : "7px 7px 7px 7px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleClick}
                    aria-controls="panel1a-content"
                    id={id}>
                    {!isCompleted && (
                        <RadioButtonUncheckedIcon
                            sx={{ mr: "10px", ml: "-8px", color: "#d4d4d4" }}></RadioButtonUncheckedIcon>
                    )}
                    {isCompleted && (
                        <CheckCircleOutlineIcon
                            sx={{ mr: "10px", ml: "-8px", color: "#189212" }}></CheckCircleOutlineIcon>
                    )}
                    <Typography>{label}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: "15px" }}>{children}</AccordionDetails>
            </Accordion>
        </>
    );
};

export const RequestToBook = forwardRef((props, ref) => {
    const {
        shared,
        calendarEntryRecord,
        setCalendarEntryRecord,
        confirmBookingRecord,
        setBookingConfirmNote,
        bookingConfirmNote,
        setConfirmBookingCompleted,
    } = props;
    const [initialLoading, setInitialLoading] = useState(false);
    const [countryPicklist, setCountryPicklist] = useState([]);
    const [endDateTimePicklist, setEndDateTimePicklist] = useState([]);
    const [startDateTimePicklist, setStartDateTimePicklist] = useState([]);

    const [eventTypePicklist, setEventTypePicklist] = useState([]);
    const [platformPicklist, setPlatformPicklist] = useState([]);
    const [mediaCoveragePicklist, setMediaCoveragePicklist] = useState([]);
    const [recordingTypePicklist, setRecordingTypePicklist] = useState([]);
    const [speechTopics, setSpeechTopics] = useState([]);
    const [timer, setTimer] = useState(undefined);

    const checkboxOption = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];

    useEffect(() => {
        if (!shared) {
            getCalendarEntryDescribe();
            getSpeakerSpeechTopic();
            setTimeout(() => {
                setBookingCompleted();
            }, 1000);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        getData() {
            let records = [];

            return records;
        },
    }));

    const getSpeakerSpeechTopic = async () => {
        let result = await query("speechTopic", "getSpeakerRecords", {
            speakerId: calendarEntryRecord.speakerId,
            opportunityId: calendarEntryRecord.opportunityId,
        });
        if (result.isSuccess) {
            setSpeechTopics(result.data);
        }
    };

    const getCalendarEntryDescribe = async () => {
        const result = await describe("calendarEntry", { cacheable: true });
        if (result.isSuccess) {
            console.log(result);
            for (let field of result?.data?.fields) {
                if (field.name === "Location_Country_Global__c") {
                    console.log("field.picklistValues", field.picklistValues);
                    setCountryPicklist(
                        field.picklistValues.filter((item) => item.label !== "Virtual / Remote Broadcast")
                    );
                } else if (field.name === "New_Event_Type__c") {
                    setEventTypePicklist(field.picklistValues);
                } else if (field.name === "Platform__c") {
                    setPlatformPicklist(field.picklistValues);
                } else if (field.name === "Media_Coverage__c") {
                    setMediaCoveragePicklist(field.picklistValues);
                } else if (field.name === "Recording_Type__c") {
                    setRecordingTypePicklist(field.picklistValues);
                }
            }
        }
        setInitialLoading(false);
    };

    const isVirtual = () => {
        return calendarEntryRecord.eventType === "Virtual";
    };

    const isOtherPlatform = () => {
        return calendarEntryRecord.platform === "Other";
    };

    const isCustomSpeechTopic = () => {
        return calendarEntryRecord.speechTopic === "custom";
    };

    const handleChange = (value, fieldName) => {
        let record = { ...calendarEntryRecord };
        record[fieldName] = value;

        if (fieldName === "preEventContactSameAsContractIssue" && record[fieldName] === true) {
            record.preEventContact = undefined;
            record.preEventContactName = "";
            record.preEventContactTitle = "";
            record.preEventContactTelephone = "";
            record.preEventContactMobilePhone = "";
            record.preEventContactEmail = "";
            record.preEventContactStreet1 = "";
            record.preEventContactCity = "";
            record.preEventContactState = "";
            record.preEventContactCountry = "";
            record.preEventContactZip = "";
        } else if (fieldName === "onsiteContactSameAs" && record[fieldName] === true) {
            record.onsiteContact = undefined;
            record.onsiteContactName = "";
            record.onsiteContactTitle = "";
            record.onsiteContactTelephone = "";
            record.onsiteContactMobilePhone = "";
            record.onsiteContactEmail = "";
            record.onsiteContactStreet1 = "";
            record.onsiteContactCity = "";
            record.onsiteContactState = "";
            record.onsiteContactCountry = "";
            record.onsiteContactZip = "";
        }

        if (fieldName === "eventType" && record.eventType === "Virtual") {
            record.venue = "Platform";
            record.venueStreet1 = "Platform";
            record.venueStreet2 = "Platform";
            record.venueCity = "Platform";
            record.venueState = "Platform";
            record.venueCountry = "Platform";
            record.venueZip = "Platform";
            record.venueFaxNumber = "Platform";
            record.venuePhoneNumber = "Platform";
        }

        if (fieldName === "eventType" && record.eventType !== "Virtual") {
            record.venue = "";
            record.venueStreet1 = "";
            record.venueStreet2 = "";
            record.venueCity = "";
            record.venueState = "";
            record.venueCountry = "";
            record.venueZip = "";
            record.venueFaxNumber = "";
            record.venuePhoneNumber = "";
        }
        setCalendarEntryRecord({
            ...record,
        });
    };

    const handleBlur = () => {
        createContactRecord(calendarEntryRecord);
    };

    const createContactRecord = (record) => {
        if (validateContractIssuedTo() && !record.contractIssuedTo) {
            record.contractIssuedTo = "blank";
            setCalendarEntryRecord({
                ...record,
            });

            let contact = prepareName(record.contractIssuedToName);
            contact.Phone = record.contractIssuedToTelephone;
            contact.MobilePhone = record.contractIssuedToMobilePhone;
            contact.Email = record.contractIssuedToEmail;
            contact.MailingStreet =
                record.contractIssuedToStreet1 +
                (record.contractIssuedToStreet2 ? " " + record.contractIssuedToStreet2 : "");
            contact.MailingCity = record.contractIssuedToCity;
            contact.MailingCountry = record.contractIssuedToCountry;
            contact.MailingPostalCode = record.contractIssuedToZip;

            createIfNotExistContact(contact, "contractIssuedTo");
        }

        if (
            validatePrevEventContract() &&
            !calendarEntryRecord.preEventContactSameAsContractIssue &&
            !calendarEntryRecord.preEventContact
        ) {
            record.preEventContact = "blank";
            setCalendarEntryRecord({
                ...record,
            });
            let contact = prepareName(record.preEventContactName);
            contact.Phone = record.preEventContactTelephone;
            contact.MobilePhone = record.preEventContactMobilePhone;
            contact.Email = record.preEventContactEmail;

            createIfNotExistContact(contact, "preEventContact");
        }

        if (validateOnSiteContact() && !calendarEntryRecord.onsiteContactSameAs && !calendarEntryRecord.onsiteContact) {
            record.onsiteContact = "blank";
            setCalendarEntryRecord({
                ...record,
            });
            let contact = prepareName(record.onsiteContactName);
            contact.Phone = record.onsiteContactTelephone;
            contact.MobilePhone = record.onsiteContactMobilePhone;
            contact.Email = record.onsiteContactEmail;

            createIfNotExistContact(contact, "onsiteContact");
        }
    };

    const prepareName = (value) => {
        let contact = {};
        let splittedName = value?.split(" ");
        if (splittedName.length === 1) {
            contact.LastName = value;
        } else if (splittedName.length === 2) {
            contact.FirstName = splittedName[0];
            contact.LastName = splittedName[1];
        } else if (splittedName.length === 3) {
            contact.FirstName = splittedName[0];
            contact.MiddleName = splittedName[1];
            contact.LastName = splittedName[2];
        } else if (splittedName.length > 3) {
            contact.FirstName = splittedName[0];
            contact.MiddleName = splittedName[1];
            contact.LastName = splittedName.slice(-2).join(" ");
        }

        return contact;
    };

    const createIfNotExistContact = (contact, field) => {
        post("opportunity/createIfNotExistContact", {
            opportunityId: calendarEntryRecord.opportunityId,
            contact: contact,
        }).then(async (result) => {
            if (result.isSuccess && result.data) {
                calendarEntryRecord[field] = result.data.Id;
                setCalendarEntryRecord({
                    ...calendarEntryRecord,
                });
            } else {
                calendarEntryRecord[field] = "";
                setCalendarEntryRecord({
                    ...calendarEntryRecord,
                });
            }
        });
    };

    const handleVenueChange = (value, type) => {
        let record = { ...calendarEntryRecord };
        if (type === "venue") {
            record.venue = value.label?.substring(0, 254);
            record.venueStreet1 = value.street1;
            record.venueStreet2 = value.street2;
            record.venueCity = value.city;
            record.venueState = value.state;
            record.venueCountry = value.country;
            record.venueCountryGlobal = value.country;
            record.venueZip = value.zipcode;
        } else {
            record.hotel = value.label.substring(0, 254);
            record.hotelStreet1 = value.street1;
            record.hotelStreet2 = value.street2;
            record.hotelCity = value.city;
            record.hotelState = value.state;
            record.hotelCountry = value.country;
            record.hotelCountryGlobal = value.country;
            record.hotelZip = value.zipcode;
        }
        setCalendarEntryRecord({
            ...record,
        });
    };

    const setBookingCompleted = () => {
        // if (timer) {
        //     clearTimeout(timer);
        // }
        // let timerValue = setTimeout(() => {

        // }, 500);
        // setTimer(timerValue);
        let validated = validateSubmitButton();
        console.log("validated", validated);
        if (!validated) {
            document.querySelector("#request-to-book-submit")?.setAttribute("disabled", true);
            document.querySelector("#request-to-book-submit")?.classList.add("Mui-disabled");
        } else {
            document.querySelector("#request-to-book-submit")?.removeAttribute("disabled");
            document.querySelector("#request-to-book-submit")?.classList.remove("Mui-disabled");
        }
        //setConfirmBookingCompleted(validated);
    };

    const getLocationName = (record, type) => {
        let data = [];
        if (type === "venue") {
            data.push(record.venue?.split(",")[0]);
            if (record.venueStreet1) {
                data.push(record.venueStreet1);
            }
            if (record.venueStreet2) {
                data.push(record.venueStreet2);
            }
            if (record.venueCity) {
                data.push(record.venueCity);
            }
            if (record.venueState) {
                data.push(record.venueState);
            }
            if (record.venueCountry) {
                data.push(record.venueCountry);
            }
            if (record.venueZip) {
                data.push(record.venueZip);
            }
        } else {
            data.push(record.hotel?.split(",")[0]);
            if (record.hotelStreet1) {
                data.push(record.hotelStreet1);
            }
            if (record.hotelStreet2) {
                data.push(record.hotelStreet2);
            }
            if (record.hotelCity) {
                data.push(record.hotelCity);
            }
            if (record.hotelState) {
                data.push(record.hotelState);
            }
            if (record.hotelCountry) {
                data.push(record.hotelCountry);
            }
            if (record.hotelZip) {
                data.push(record.hotelZip);
            }
        }
        return data.join(", ");
    };

    const createMarkup = (text) => {
        return { __html: text };
    };

    const displayField = (required, type, label, fieldName, options, value, maxLength) => {
        let field;
        let id = label.replaceAll(" ", "-").toLowerCase();
        if (calendarEntryRecord) {
            if (type === "text") {
                field = (
                    <TextField
                        fullWidth
                        required={required}
                        inputProps={maxLength ? { maxLength: maxLength } : { maxLength: 255 }}
                        defaultValue={value ? value : calendarEntryRecord[fieldName]}
                        value={value ? value : calendarEntryRecord[fieldName]}
                        label={label}
                        InputLabelProps={{ shrink: calendarEntryRecord[fieldName] ? true : false }}
                        variant="outlined"
                        onChange={(event) => handleChange(event.target.value, fieldName)}
                        onBlur={handleBlur}
                    />
                );
            } else if (type === "select") {
                field = (
                    <FormControl fullWidth>
                        <InputLabel
                            required={required}
                            shrink={calendarEntryRecord[fieldName] ? true : false}
                            id={`${id}-label`}>
                            {label}
                        </InputLabel>
                        <Select
                            required={required}
                            labelId={`${id}-label`}
                            id={`${id}-select`}
                            label={label}
                            placeholder="Select value"
                            value={value ? value : calendarEntryRecord[fieldName]}
                            onChange={(event) => handleChange(event.target.value, fieldName)}
                            onBlur={handleBlur}>
                            {options.map((item) => (
                                <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            } else if (type === "date") {
                field = (
                    <LocalizationProvider sx={{ width: "100%" }} fullWidth dateAdapter={AdapterDayjs}>
                        <DatePicker
                            required={required}
                            className="event-date"
                            fullWidth
                            labelId={`${id}-label`}
                            id={`${id}-date`}
                            style={{ width: "100%" }}
                            label={label + (required ? " *" : "")}
                            variant="outlined"
                            value={value ? value : calendarEntryRecord[fieldName]}
                            onChange={(newValue) => handleChange(newValue, fieldName)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                );
            } else if (type === "switch") {
                field = (
                    <FormControlLabel
                        required={required}
                        control={<Switch defaultChecked={value ? value : calendarEntryRecord[fieldName]} />}
                        label={label}
                        onChange={(event) => handleChange(event.target.checked, fieldName)}
                    />
                );
            } else if (type === "textarea") {
                field = (
                    <TextField
                        required={required}
                        fullWidth
                        multiline={true}
                        rows={3}
                        InputLabelProps={{ shrink: calendarEntryRecord[fieldName] ? true : false }}
                        value={value ? value : calendarEntryRecord[fieldName]}
                        label={label}
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={(event) => handleChange(event.target.value, fieldName)}
                    />
                );
            } else if (type === "rich-textarea") {
                field = (
                    <>
                        <InputLabel>{label}</InputLabel>
                        <div
                            dangerouslySetInnerHTML={createMarkup(
                                value ? value : calendarEntryRecord[fieldName]
                            )}></div>
                    </>
                );
            }

            return field;
        }

        return "";
    };

    const openLocation = async (locationName) => {
        const url = "https://www.google.com/maps?q=" + locationName;
        window.open(url, "_blank");
    };

    const getSpeechTopicRecord = () => {
        if (speechTopics?.length > 0) {
            let options = speechTopics.map((item) => {
                return { value: item.speechTopic, label: item.customTopicName };
            });

            return [{ label: "Custom", value: "custom" }, ...options];
        }
        return [{ label: "Custom", value: "custom" }];
    };

    const validateFields = (fields) => {
        let valid = true;
        for (let field of fields) {
            if (!calendarEntryRecord || !calendarEntryRecord[field]) {
                valid = false;
                break;
            }
        }

        return valid;
    };

    const validateEventDetail = () => {
        let fields = ["eventName", "eventDate", "eventType", "mediaCoverage", "recordingType"];
        if (isVirtual()) {
            fields.push("platform");
            if (isOtherPlatform()) {
                fields.push("otherPlatform");
            }
        }
        return validateFields(fields);
    };

    const validateContractIssuedTo = () => {
        let fields = [
            "contractIssuedToName",
            "contractIssuedToTelephone",
            "contractIssuedToMobilePhone",
            "contractIssuedToEmail",
            "contractIssuedToStreet1",
            "contractIssuedToCity",
            "contractIssuedToState",
            "contractIssuedToCountry",
            "contractIssuedToZip",
        ];
        return validateFields(fields);
    };

    const validatePrevEventContract = () => {
        if (calendarEntryRecord.preEventContactSameAsContractIssue) {
            return true;
        } else {
            let fields = [
                "preEventContactName",
                "preEventContactTelephone",
                "preEventContactMobilePhone",
                "preEventContactEmail",
            ];
            return validateFields(fields);
        }
    };

    const validateOnSiteContact = () => {
        if (calendarEntryRecord.onsiteContactSameAs) {
            return true;
        } else {
            let fields = [
                "onsiteContactName",
                "onsiteContactTelephone",
                "onsiteContactMobilePhone",
                "onsiteContactEmail",
            ];
            return validateFields(fields);
        }
    };

    const validateVenueDetails = () => {
        if (isVirtual()) {
            return true;
        }
        let fields = ["venueStreet1", "venueCity", "venueState", "venueCountryGlobal", "venueZip", "venuePhoneNumber"];
        return validateFields(fields);
    };

    const validateHotelDetails = () => {
        if (isVirtual()) {
            return true;
        }

        if (calendarEntryRecord.sameAsVenue) {
            return true;
        }
        let fields = ["hotelStreet1", "hotelCity", "hotelState", "hotelCountryGlobal", "hotelZIP", "hotelPhoneNumber"];
        return validateFields(fields);
    };

    const validateSpeechTopic = () => {
        if (calendarEntryRecord.speechTopic && calendarEntryRecord.speechTopic !== "custom") {
            return true;
        }
        if (calendarEntryRecord.speechTopic === "custom") {
            let fields = ["customSpeech"];
            return validateFields(fields);
        }
        return false;
    };

    const validateAdditionalDetails = () => {
        let fields = [
            "aboutTheEvent",
            "additionalSpeakers",
            "previousSpeakers",
            "audienceSize",
            "audienceProfile",
            "speakerAttire",
            "sponsors",
            "willTicketsBeSold",
        ];
        return validateFields(fields);
    };

    const validateSubmitButton = () => {
        if (
            validateEventDetail() &&
            validateContractIssuedTo() &&
            validatePrevEventContract() &&
            validateOnSiteContact() &&
            validateVenueDetails() &&
            validateHotelDetails() &&
            validateSpeechTopic() &&
            validateAdditionalDetails()
        ) {
            return true;
        }

        return false;
    };

    return (
        <form>
            {calendarEntryRecord && <div>{setBookingCompleted()}</div>}
            <Typography variant="h3" gutterBottom>
                Request To Book
            </Typography>
            <Typography variant="body1" gutterBottom>
                In order to move forward with booking {calendarEntryRecord?.speakerName}, we will need to prepare an
                agreement. To be sure we have the most accurate details to prepare the offer, please fill out our
                pre-booking questionnaire below.
            </Typography>
            <Typography variant="body1" sx={{ mb: "30px" }}>
                You may save and return to this form anytime you'd like. Each section will turn green as you complete
                them and you'll be able to submit once all information is provided. If you have any questions, feel free
                to reach out!
            </Typography>
            <CustomAccordion label={"Event Detail"} isCompleted={validateEventDetail()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                        <TextField
                            fullWidth
                            value={calendarEntryRecord?.accountName}
                            label="Client Name"
                            variant="outlined"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Event Name", "eventName")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "date", "Event Date", "eventDate")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "select", "Event Type", "eventType", eventTypePicklist)}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "select", "Media", "mediaCoverage", mediaCoveragePicklist)}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "select", "Recording", "recordingType", recordingTypePicklist)}
                    </Grid>

                    {isVirtual() && (
                        <>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>

                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "select", "Platform", "platform", platformPicklist)}
                            </Grid>
                            {isOtherPlatform() && (
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "Other Platform", "otherPlatform")}
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </CustomAccordion>
            <CustomAccordion label={"Contract Issued To"} isCompleted={validateContractIssuedTo()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        <b>Contract Contact Details</b>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" sx={{ mb: "30px" }}>
                            Please provide the contact details of the person the contract should be issued to. We may
                            have pre-filled this section for you with an existing contact's information, but please
                            modify as necessary.
                        </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Name", "contractIssuedToName")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Office Phone", "contractIssuedToTelephone")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Mobile Phone", "contractIssuedToMobilePhone")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Email", "contractIssuedToEmail")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <b>Address</b>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Street 1", "contractIssuedToStreet1")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(false, "text", "Street 2", "contractIssuedToStreet2")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "City", "contractIssuedToCity")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "State", "contractIssuedToState")}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "select", "Country", "contractIssuedToCountry", countryPicklist)}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "text", "Zip code", "contractIssuedToZip")}
                    </Grid>
                </Grid>
            </CustomAccordion>

            <CustomAccordion label={"Pre-Event Contact"} isCompleted={validatePrevEventContract()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(
                            false,
                            "switch",
                            "Same as Contract Issued To",
                            "preEventContactSameAsContractIssue"
                        )}
                    </Grid>
                    {!calendarEntryRecord.preEventContactSameAsContractIssue && (
                        <>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <b>Pre-Event Contact Details</b>
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Name", "preEventContactName")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Office Phone", "preEventContactTelephone")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Mobile Phone", "preEventContactMobilePhone")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Email", "preEventContactEmail")}
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CustomAccordion>

            <CustomAccordion label={"On-site Contact"} isCompleted={validateOnSiteContact()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(false, "switch", "Same as Contract Issued To", "onsiteContactSameAs")}
                    </Grid>
                    {!calendarEntryRecord.onsiteContactSameAs && (
                        <>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <b>On-Site Contact Details</b>
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Name", "onsiteContactName")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Office Phone", "onsiteContactTelephone")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Mobile Phone", "onsiteContactMobilePhone")}
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                {displayField(true, "text", "Email", "onsiteContactEmail")}
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CustomAccordion>
            {!isVirtual() && (
                <CustomAccordion label={"Venue Details"} isCompleted={validateVenueDetails()}>
                    <Grid fullWidth container spacing={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <Typography variant="body1" sx={{ mb: "30px" }}>
                                Please use the address search to find the venue location address. Select from the list
                                of suggested addresses and then confirm the venue address is correct before submitting.
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <GoogleAutocomplete
                                label={"Venue Search"}
                                value={calendarEntryRecord["venue"]}
                                onChangeValue={(value) => handleVenueChange(value, "venue")}></GoogleAutocomplete>
                        </Grid>

                        {calendarEntryRecord.venue && (
                            <Grid item lg={12} md={12} xs={12} sx={{ display: "flex" }}>
                                <LocationOnIcon sx={{ color: "#b8b8b8", mr: ".4rem" }}></LocationOnIcon>
                                <b>Venue:</b>{" "}
                                <Link
                                    onClick={() => openLocation(calendarEntryRecord.venue)}
                                    title="Open location in map"
                                    sx={{ color: "#f56856", ml: ".7rem" }}
                                    href="#">
                                    {getLocationName(calendarEntryRecord, "venue")}
                                </Link>
                            </Grid>
                        )}

                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <b>Address</b>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Street 1", "venueStreet1")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Street 2", "venueStreet2")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "City", "venueCity")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "State", "venueState")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Country", "venueCountry")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "select", "Country Global", "venueCountryGlobal", countryPicklist)}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Zip code", "venueZip")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Fax Number", "venueFaxNumber")}
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Phone Number", "venuePhoneNumber")}
                        </Grid>
                    </Grid>
                </CustomAccordion>
            )}

            {!isVirtual() && (
                <CustomAccordion label={"Hotel Details"} isCompleted={validateHotelDetails()}>
                    <Grid fullWidth container spacing={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <Typography variant="body1" sx={{ mb: "30px" }}>
                                Please use the address search to find the hotel address. Select from the list of
                                suggested addresses and then confirm the hotel address is correct before submitting. If
                                the hotel is the same as the venue, check the option below.
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            {displayField(false, "switch", "Same as Venue", "sameAsVenue")}
                        </Grid>
                        {!calendarEntryRecord.sameAsVenue && (
                            <>
                                <Grid item lg={12} md={12} xs={12}>
                                    <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                                </Grid>
                                {/* <Grid item lg={4} md={4} xs={12}>
                                    {displayField(false, "text", "Name", "hotel")}
                                </Grid> */}
                                <Grid item lg={4} md={4} xs={12}>
                                    <GoogleAutocomplete
                                        label={"Name"}
                                        value={calendarEntryRecord["hotel"]}
                                        onChangeValue={(value) =>
                                            handleVenueChange(value, "hotel")
                                        }></GoogleAutocomplete>
                                </Grid>
                                {calendarEntryRecord.hotel && (
                                    <Grid item lg={12} md={12} xs={12} sx={{ display: "flex" }}>
                                        <LocationOnIcon sx={{ color: "#b8b8b8", mr: ".4rem" }}></LocationOnIcon>
                                        <b>Hotel:</b>{" "}
                                        <Link
                                            onClick={() => openLocation(calendarEntryRecord.hotel)}
                                            title="Open location in map"
                                            sx={{ color: "#f56856", ml: ".7rem" }}
                                            href="#">
                                            {getLocationName(calendarEntryRecord, "hotel")}
                                        </Link>
                                    </Grid>
                                )}
                                <Grid item lg={12} md={12} xs={12}>
                                    <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12}>
                                    <b>Address</b>
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "Street 1", "hotelStreet1")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(false, "text", "Street 2", "hotelStreet2")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "City", "hotelCity")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "State", "hotelState")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(false, "text", "Country", "hotelCountry")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(
                                        true,
                                        "select",
                                        "Country Global",
                                        "hotelCountryGlobal",
                                        countryPicklist
                                    )}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "Zip code", "hotelZIP")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(false, "text", "Fax Number", "hotelFaxNumber")}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12}>
                                    {displayField(true, "text", "Phone Number", "hotelPhoneNumber")}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CustomAccordion>
            )}

            <CustomAccordion label={"Speech Topic"} isCompleted={validateSpeechTopic()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" sx={{ mb: "30px" }}>
                            Select a speech topic from the available speech topics below. Custom speech topics need to
                            be approved by the speaker and may not be available or may affect the final speaker fee.
                        </Typography>
                    </Grid>

                    <Grid item lg={8} md={8} xs={12}>
                        {displayField(
                            true,
                            "select",
                            "Speech Topic",
                            "speechTopic",
                            speechTopics ? getSpeechTopicRecord() : []
                        )}
                    </Grid>
                    {calendarEntryRecord.speechTopic === "custom" && (
                        <Grid item lg={12} md={12} xs={12}>
                            {displayField(true, "textarea", "Custom Speech Topic", "customSpeech")}
                        </Grid>
                    )}
                </Grid>
            </CustomAccordion>

            <CustomAccordion label={"Additional Details"} isCompleted={validateAdditionalDetails()}>
                <Grid fullWidth container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" sx={{ mb: "30px" }}>
                            Please provide as much detail as possible about the event, including the most recent
                            timetable.
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {/* {displayField(false, "textarea", "Timetable", "contractedTimeline")} */}
                        <RichTextArea
                            label={"Timetable"}
                            value={calendarEntryRecord.contractedTimeline}
                            onChange={(value) => (calendarEntryRecord.contractedTimeline = value)}></RichTextArea>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "About the Event", "aboutTheEvent")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "Additional Speakers", "additionalSpeakers")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "Previous Speakers", "previousSpeakers")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "text", "Audience Size", "audienceSize")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "Audience Profile", "audienceProfile")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "text", "Speaker's Attire", "speakerAttire", "", "", 200)}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "Sponsors", "sponsors")}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "select", "Will tickets be sold?", "willTicketsBeSold", checkboxOption)}
                    </Grid>
                </Grid>
            </CustomAccordion>

            <Grid xs={12} md={3} sx={{ m: "15px 5px", mt: "15px" }}>
                <Box>
                    <h4 style={{ marginBottom: "15px" }}>Selected Fees</h4>
                </Box>
                {calendarEntryRecord?.selectedFeeId &&
                confirmBookingRecord?.speakerDetails?.filter(
                    (item) => item.type === "Fee" && item.id === calendarEntryRecord.selectedFeeId
                )?.length > 0
                    ? confirmBookingRecord.speakerDetails
                          .filter((item) => item.type === "Fee" && item.id === calendarEntryRecord.selectedFeeId)
                          .map((item) => (
                              <>
                                  <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      className="booking-fee-container">
                                      <Grid item lg={12}>
                                          <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                              <Grid xs={8} item>
                                                  <b>{item.feeName?.split(/-(.*)/s)[1]}</b>
                                              </Grid>
                                              <Grid item>
                                                  {new Intl.NumberFormat("en-US", {
                                                      style: "currency",
                                                      currency: "USD",
                                                      maximumFractionDigits: 0,
                                                  }).format(item.feeAmount ? item.feeAmount : 0)}
                                              </Grid>
                                          </Grid>
                                          <Box>
                                              {item.clientExtent && (
                                                  <>
                                                      Client to provide{" "}
                                                      {item.clientExtent.toLowerCase() === "custom"
                                                          ? item.customClientExtent
                                                          : item.clientExtent}
                                                  </>
                                              )}
                                          </Box>
                                      </Grid>
                                  </Grid>
                              </>
                          ))
                    : "No Fee Available"}
            </Grid>

            <Box sx={{ mt: "10px" }}>
                <TextField
                    onChange={(event) => setBookingConfirmNote(event.target.value)}
                    fullWidth
                    value={bookingConfirmNote}
                    label="Please share any additional details about this booking request not provided in the form above."
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRows={5}
                />
            </Box>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ m: "30px 0 30px 0" }}>
                    Once you've provided all the details on the form you can submit it and we'll begin preparing the
                    speaker offer! If you're not ready to submit, feel free to click save and return at your earliest
                    convenience.
                </Typography>
            </Grid>
        </form>
    );
});
