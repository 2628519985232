import React, {useEffect} from 'react';
import { useAuth } from '../../hooks/useAuth'

const Signout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        const callApi = async () => {
            logout()
        }
        callApi();
    }, [logout]);

    
    

    return <p></p>
}

export { Signout }