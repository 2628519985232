import { DragDropFile } from "../DragDropFile";
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import {getFileUrl} from '../../services/s3'
import { UploadFilesToS3 } from "../UploadFilesToS3";
import { query, save, deleteRecord } from '../../services/object';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Divider,
    Typography,
    Button,
    IconButton,
    Breadcrumbs,
    Link,
    MenuItem,
    Menu
  } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {config, styles} from '../../style';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { 
    faFile
} from '@fortawesome/free-solid-svg-icons'

import {iconMapping} from './iconMapping'

import CircularProgress from '@mui/material/CircularProgress';
import './style.css'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { format } from 'date-fns';  
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const DocumentCard = (props) => {
    const {item, onCardClick, getIcon, getColor, onDownload, CircularProgressWithLabel, onDeleteClick, getExtension} = props;

    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const actionOpen = Boolean(actionAnchorEl);

    const handleActionMenuClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setActionAnchorEl(event.currentTarget);
    }

    const handleActionMenuClose = () => {
        setActionAnchorEl(null);
    }

    const handleActionSelect = (action) => {
        if(action === 'Delete'){
            onDeleteClick(item)
        }
        setActionAnchorEl(null);
    }

    return (
        <Grid key={item.id || item.name} xs={12} md={4} lg={3} item>
            <Card sx={{ position: 'relative', cursor: 'pointer', minHeight: '188px' }} onClick={() => onCardClick(item)}>
                <CardMedia
                    sx={{ height: 80, p: '15px', pt: '5px', fontSize: '2rem', color: getColor(item.fileName) }}
                >
                    <Grid sx={{width: '100%', padding: '0'}} container wrap="nowrap" spacing={0} justifyContent="space-between"  alignItems='center'>
                        <Grid item>
                            <FontAwesomeIcon icon={getIcon(item.fileName)} />
                            <Typography sx={{marginTop: '-8px', textTransform: 'uppercase', fontSize: '10px'}} component="div" noWrap variant="caption" color="text.secondary">
                                {getExtension(item.fileName)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="action-button"
                                aria-controls={actionOpen ? 'action-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={actionOpen ? 'true' : undefined}
                                variant="outlined"
                                disableElevation
                                onClick={(event) => handleActionMenuClick(event)} 
                                sx={{marginTop: '-5px'}} 
                                aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                            <StyledMenu
                                id="action-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'action-button',
                                }}
                                anchorEl={actionAnchorEl}
                                open={actionOpen}
                                onClose={handleActionMenuClose}
                            >
                                <MenuItem onClick={() => handleActionSelect('Delete')} disableRipple>
                                    Delete
                                </MenuItem>
                                <MenuItem onClick={() => handleActionSelect('Share')} disableRipple>
                                    Share
                                </MenuItem>


                            </StyledMenu>
                        </Grid>
                    </Grid>
                    
                </CardMedia>
                <CardContent>
                    {item.status === 'started' && <Box className="progress-container"> <CircularProgressWithLabel value={item.percentage || 5}></CircularProgressWithLabel> </Box>  }
                    <Typography noWrap gutterBottom variant="h6" component="div">
                        {item.name}
                    </Typography>
                    <Typography sx={{mt: '-6px'}} noWrap variant="caption" color="text.secondary" component="div">
                        {item.createdDate && format(new Date(item.createdDate), 'MMM d, yyyy HH:mm a')}
                    </Typography>
                </CardContent>
                <CardActions sx={{background: '#f1f5ff', paddingBottom: '4px'}}>
                    <Grid sx={{width: '100%', padding: '0', pl: '7px'}} container wrap="nowrap" spacing={0} justifyContent="space-between"  alignItems='center'>
                        <Grid item>
                            <Typography variant="overline" noWrap color="text.primary" component="div">
                                {item.createdByName}
                            </Typography>
                            <Typography component="div" noWrap variant="caption" color="text.secondary">
                                Created By
                            </Typography>
                        </Grid>
                        {item.documentType !== 'folder' && <Grid item>
                            <DownloadForOfflineIcon onClick={(event) => onDownload(event, item)} sx={{color: '#413ab4', marginRight: '9px', cursor: 'pointer'}}></DownloadForOfflineIcon>
                        </Grid>}
                    </Grid>
                    
                    
                </CardActions>
            </Card>
        </Grid>
    )
}