import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Divider,
    Typography,
    Button,
    IconButton,
    Breadcrumbs,
    Link,
    MenuItem,
    Menu
  } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { iconMapping } from './iconMapping'

import {
    faFile
} from '@fortawesome/free-solid-svg-icons';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { format } from 'date-fns';  
import { styled, alpha } from '@mui/material/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { getFileUrl } from '../../services/s3'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const FileUpload = ({ }) => {
    const [files, setFiles] = useState();

    const handleFileChange = (event) => {
        let files = event.target.files;
        let arrFile = []
        for (let i = 0; i < files.length; i++){
            arrFile.push(files[i]);
        }
        setFiles(arrFile)
    }

    const getExtension = (name) => {
        if(name){
            return name.substring(name.lastIndexOf(".")+1)
        }
        return ''
    }

    const getIcon = (fileName) => {
        const extension = getExtension(fileName);
        const icon = iconMapping[extension]?.icon;
        return icon ? icon : faFile
    }

    const getColor = (fileName) => {
        const extension = getExtension(fileName);
        const color = iconMapping[extension]?.color;
        return color ? color : '#413ab4'
    }

    const handleDownload = async (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        const {isSuccess, data} = await getFileUrl({fileId: item.fileId})
        if(isSuccess){
            downloadFile(data, item.name)
        }
    }

    const downloadFile = (downloadUrl, name) => {
        const downloadContainer = document.querySelector('.download-container');
        const fileName = name;

        let a = document.createElement('a');
        a.href = downloadUrl;
        a.target = '_blank';
        // Use a.download if available, it prevents plugins from opening.
        a.download = fileName;
        // Add a to the doc for click to work.
        if (downloadContainer) {
            downloadContainer.appendChild(a);
        }
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        }
        // Delete the temporary link.
        downloadContainer.removeChild(a);
    }


    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const actionOpen = Boolean(actionAnchorEl);

    const handleActionMenuClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setActionAnchorEl(event.currentTarget);
    }

    const handleActionMenuClose = () => {
        setActionAnchorEl(null);
    }

    const handleActionSelect = (action, item) => {
        if(action === 'Delete'){
            onDeleteClick(item)
        }
        setActionAnchorEl(null);
    }

    const onDeleteClick = (item) => {
        setFiles(files.filter(record => record.name !== item.name))

    }

    return (
        <Box sx={{border: '1px solid #e7e8f0', borderRadius: '8px', padding: '10px' }} >
            <Box>
                <label htmlFor="upload-photo">
                    <input
                        style={{ display: 'none' }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                    />

                    <Button variant="contained" sx={{ borderRadius: '5px', fontSize: '12px', p: '4px 8px' }} component="span">
                        Choose file
                    </Button>
                </label>
            </Box>
            
            <Box sx={{display: 'flex'}}>
                {files?.map(record => (
                    <>
                        <Grid key={record.id || record.name} xs={12} md={4} lg={3} item>
                            <Card sx={{ position: 'relative', cursor: 'pointer', border: '1px solid #e9e9e9', marginTop: '0.75rem', minWidth: '200px', marginRight: '0.75rem' }}>
                                <CardMedia
                                    sx={{ p: '15px', pt: '5px', fontSize: '2rem', color: getColor(record.name) }}
                                >
                                    <Grid sx={{width: '100%', padding: '0'}} container wrap="nowrap" spacing={0} justifyContent="space-between"  alignItems='center'>
                                        <Grid item>
                                            <FontAwesomeIcon icon={getIcon(record.name)} />
                                            <Typography sx={{marginTop: '-8px', textTransform: 'uppercase', fontSize: '10px'}} component="div" noWrap variant="caption" color="text.secondary">
                                                {getExtension(record.name)}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton id={"action-button" + record.id}
                                                aria-controls={actionOpen ? ( 'action-menu' + record.id) : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={actionOpen ? 'true' : undefined}
                                                variant="outlined"
                                                disableElevation
                                                onClick={(event) => handleActionMenuClick(event)} 
                                                sx={{marginTop: '-5px'}} 
                                                aria-label="settings">
                                                <MoreVertIcon />
                                            </IconButton>
                                            <StyledMenu
                                                id={"action-menu" + record.id}
                                                MenuListProps={{
                                                    'aria-labelledby': 'action-button' + record.id,
                                                }}
                                                anchorEl={actionAnchorEl}
                                                open={actionOpen}
                                                onClose={handleActionMenuClose}
                                            >
                                                <MenuItem onClick={() => handleActionSelect('Delete', record)} disableRipple>
                                                    Delete
                                                </MenuItem>


                                            </StyledMenu>
                                        </Grid>
                                    </Grid>
                                    
                                </CardMedia>
                                <CardContent>
                                    <Typography noWrap gutterBottom variant="h6" component="div">
                                        {record.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                ))}
            </Box>
        </Box>
    );
}

export { FileUpload }