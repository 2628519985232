import { createSlice } from '@reduxjs/toolkit';

export const answerSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'answer',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.data = {};
            state.status = 'failed';
            state.error = action.payload;
        },
    },
})

export default answerSlice.reducer

// Export all the reducers actions here
export const { initialize, error } = answerSlice.actions;


// Public Selectors
export const selectAnswerData = (state) => state.answer.data;
export const selectAnswerStatus = (state) => state.answer.status;
export const selectAnswerError = (state) => state.answer.error;

export const initializeAsync = (params) => async (dispatch) => {
    // const result = await query('account');
    // if(result.isSuccess){
    //     dispatch(initialize({records: result.data}));
    // }
    // else{
    //     dispatch(error(result.errorMessage))
    // }

    dispatch(initialize({}));
}

