import { createSlice } from '@reduxjs/toolkit';
import {query, publicQuery, apex} from '../../services/salesforce'

export const helpSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'help',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
    },
})

export default helpSlice.reducer

// Export all the reducers actions here
export const { initialize, error, initialLoading } = helpSlice.actions;


// Public Selectors
export const selectHelpData = (state) => state.help.data;
export const selectHelpStatus = (state) => state.help.status;
export const selectHelpError = (state) => state.help.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true))
    const result = await publicQuery('opportunity', 'getHelpDocument', {type: props.application === 'speaker' ? 'Speaker' : 'Client'});
    if(result.isSuccess){
        dispatch(initialize({records: result.data}));
    }
    else{
        dispatch(error(result.errorMessage))
    }

}

