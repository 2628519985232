import {get, post} from '../../api'

const getUploadURL = async (params) => {
    return new Promise(async(resolve, reject) => {
        get('s3/getUploadURL', params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const getMultipartUpload = async (params) => {
    return new Promise(async(resolve, reject) => {
        get('s3/getMultipartUpload', params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const getFileUrl = async (params) => {
    return new Promise(async(resolve, reject) => {
        get('s3/getFileUrl', params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const getUploadPart = async (params) => {
    return new Promise(async(resolve, reject) => {
        post('s3/getUploadPart', params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const completePartUpload = async (params) => {
    return new Promise(async(resolve, reject) => {
        post('s3/completePartUpload', params)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
}

const uploadToS3 = async (url, file, returnResponse) => {
    let headers = {};
    if(returnResponse){
        headers['Content-Type'] = file.type;
    }
    
    return new Promise(async(resolve, reject) => {
        await fetch(url, {method: "PUT", headers: headers, body: file})
            .then(async response => {
                console.log(JSON.stringify(response));
                if(response.ok){
                    resolve(true)
                }
            })
            .catch(error => reject(error))
    })
}

export {getUploadURL, getMultipartUpload, getUploadPart, completePartUpload, uploadToS3, getFileUrl}