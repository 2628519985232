import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import { useAuth } from '../../hooks/useAuth';
import {
    selectCalendarData,
    selectCalendarStatus,
    selectCalendarError,
    initializeAsync
} from './calendarSlice';

import { Box } from '@mui/material';

// Add custom imports here

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

const Calendar = () => {
    const data = useSelector(selectCalendarData);
    const status = useSelector(selectCalendarStatus);
    const error = useSelector(selectCalendarError);
    const dispatch = useDispatch();
    const { getUserDetails } = useAuth();

    const [events, setEvents] = useState([]);

    const eventData = useSelector((state) => {
        let sfEvents = state.calendar.data.records;
        let tEvents = [];
        console.log(sfEvents)
        if(sfEvents && sfEvents?.length > 0) {
            for(let event of sfEvents) {
                //let startTime = event.startDateTime ? `${event.startDate}T${event.startDateTime}` : `${event.startDate}`
                let cEvent = {
                    title: event.name,
                    start: `${event.startDate}`,
                    end: `${event.endDate}`,
                    id: event.id
                }
                tEvents.push(cEvent);
            }
        }
        console.log(tEvents)
        return tEvents;

    })

    const headerToolbarConfig = {
        left: 'prev,next today',
        center: 'title',
        right: 'listMonth,listYear,listDay'
    }
    const viewsConfig = {
        listMonth: { buttonText: 'Month' },
        listYear: { buttonText: 'Year' },
        listDay: { buttonText: 'Day' }
    }
    // Add Variables

    useEffect(() => {
        if(status === 'idle') {
            dispatch(initializeAsync({ getUserDetails }));
        }
    }, [status, dispatch, getUserDetails]);


    let body = (
        <Box>
            {<FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                progressiveEventRendering={true}
                events={eventData}
                headerToolbar={headerToolbarConfig}
                views={viewsConfig}
            />}
        </Box>

    )

    return getPageContent(body, status, error);
}

export { Calendar }