import { Backdrop, CircularProgress } from "@mui/material";

import { getTimeMeInstance } from "./timeMe";

export const getPageContent = (body, status, error) => {
    let content;
    const loading =
        status === "loading" ? (
            <Backdrop sx={{ color: "#fff", zIndex: 100000 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        ) : (
            ""
        );

    return status === "failed" ? (
        <p>Error : {error}</p>
    ) : (
        <>
            {body}
            {loading}
        </>
    );
};

export const uniqueString = (length) => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const getTimeMe = () => {
    return getTimeMeInstance();
};

export const setLocalStorage = (key, data, ttl) => {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: data,
        expiry: now.getTime() + (ttl ? ttl : 2 * 60 * 60 * 1000),
    };
    try {
        localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
        localStorage.clear();
        setTimeout(() => {
            try {
                localStorage.setItem(key, JSON.stringify(item));
            } catch (error) {
                console.log(error);
            }
        }, 10 * 1000);
    }
};

export const getLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

export const randomNumber = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
