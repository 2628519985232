import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../pages/Login/loginSlice';
import homeReducer from '../pages/Home/homeSlice';
import answerReducer from '../pages/Answer/answerSlice'
import listPageReducer from '../pages/ListPage/listPageSlice'
import detailPageReducer from '../pages/DetailPage/detailPageSlice'
import proposalReducer from '../pages/Proposal/proposalSlice'
import speakerReducer from '../pages/Speaker/speakerSlice'
import sharedProposalReducer from '../pages/SharedProposal/sharedProposalSlice'
import contactReducer from '../pages/Contact/contactSlice'
import eventsReducer from '../pages/Events/eventsSlice'
import eventDetailReducer from '../pages/EventDetail/eventDetailSlice'
import helpReducer from '../pages/Help/helpSlice'
import calendarReducer from '../pages/Calendar/calendarSlice'
import speakerHoldsReducer from '../pages/SpeakerHolds/speakerHoldsSlice'
import documentReducer from '../pages/Document/documentSlice'
import formContainerReducer from '../pages/FormContainer/formContainerSlice'
import speakerSearchReducer from '../pages/SpeakerSearch/speakerSearchSlice'
import dashboardReducer from '../pages/Dashboard/dashboardSlice'
import contactDetailsReducer from '../pages/ContactDetails/contactDetailsSlice'
export default configureStore({
    reducer: {
        login: loginReducer,
        answer: answerReducer,
        listPage: listPageReducer,
        detailPage: detailPageReducer,
        proposal: proposalReducer,
        speaker: speakerReducer,
        sharedProposal: sharedProposalReducer,
        contact: contactReducer,
        events: eventsReducer,
        eventDetail: eventDetailReducer,
        help: helpReducer,
        calendar: calendarReducer,
        speakerHolds: speakerHoldsReducer,
        document: documentReducer,
        formContainer: formContainerReducer,
        speakerSearch: speakerSearchReducer,
        dashboard: dashboardReducer,
        contactDetails: contactDetailsReducer,
        home: homeReducer
    },
})