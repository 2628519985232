import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import {
    selectHelpData,
    selectHelpStatus,
    selectHelpError,
    initializeAsync
} from './helpSlice';

// Add custom imports here

import { skeleton } from './skeleton';


import {
    Box,
    Card,
    CardContent,
    Divider,
    Typography
  } from '@mui/material';

import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';
import { Section } from '../../components/Section';

const Help = (props) => {
    const data = useSelector(selectHelpData);
    const status = useSelector(selectHelpStatus);
    const error = useSelector(selectHelpError);
    const dispatch = useDispatch();

    // Add Variables

    useEffect(() => {
        if(status === 'idle'){
            dispatch(initializeAsync(props));
        }
    }, [status, dispatch, props]);

    let body = (
        <Box>
            {status === 'initalLoading' ? skeleton : 
                <Box>
                    <Card sx={styles.card}>
                        <CardContent sx={{paddingTop: '15px'}}>
                            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={styles.pageHeading}>
                                        Help Document
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {data?.records?.map(record => (
                        <Card sx={{...styles.card, mt: '15px', pb: '0px'}}>
                            <CardContent sx={{paddingTop: '15px', p: 0}}>
                                <Box sx={{padding: '15px'}}>
                                    <Typography sx={styles.cardHeading}>
                                            {record.name}
                                    </Typography>
                                    <Box>
                                        <div dangerouslySetInnerHTML={{ __html: record.description }} />
                                    </Box>
                                </Box>
                                <Divider></Divider>
                                <Box sx={{padding: '15px', pb: '0px'}}>
                                    <Section section={record} hideSectionHeader></Section>
                                </Box>
                                
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            }
        </Box>
    )

    return getPageContent(body, status, error);
}

export { Help }