import { createSlice } from '@reduxjs/toolkit';
import { query, describe, create, update } from '../../services/salesforce'

export const dashboardSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'dashboard',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
        saveSpeakerList: (state, action) => {
            if (!state.data.userLists) {
                state.data.userLists = []
            } 
            if (action.payload.data?.parentList) {
                state.data.userListsParentId.push(action.payload.data?.parentList);
            }
            
        }
    },
})

export default dashboardSlice.reducer

// Export all the reducers actions here
export const { initialize, error, loading, initialLoading, saveSpeakerList } = dashboardSlice.actions;


// Public Selectors
export const selectDashboardData = (state) => state.dashboard.data;
export const selectDashboardStatus = (state) => state.dashboard.status;
export const selectDashboardError = (state) => state.dashboard.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true))
    const result = await query('speakerList', 'getReccomendedList', {});
    let userListsResult = await query(`speakerList`, 'getSpeakerListsWithParent', { contactId: props.contactId });
    if (result.isSuccess && userListsResult.isSuccess) {
        let payload = {reccomendedList: result.data, userListsParentId: userListsResult.data?.map(item => item.parentList)}
        dispatch(initialize(payload));
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const createSpeakerListAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const result = await create('speakerList', props.list);
    if (result.isSuccess) {
        let speakerList = result.data;
        dispatch(saveSpeakerList({ data: result.data }));
        let membersList = props.members.map(item => {
            return {speakerListId: speakerList.id, speakerId: item.speakerId}
        })
        
        const resultMemeber = await create('speakerListMember', membersList);
        if (resultMemeber.isSuccess) {
            dispatch(loading(false))
        }
        props.callback(resultMemeber)
    }
    else {
        dispatch(error(result.errorMessage))
        props.callback(result)
    }
}

