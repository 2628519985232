import {Auth} from 'aws-amplify';
import awsConfig from '../aws-exports'

export const getApiName = () => {
    return  awsConfig.aws_cloud_logic_custom[0]?.name
}

export const getToken = async () => {
    try{
        const user = await Auth.currentAuthenticatedUser();
        const token = user?.signInUserSession?.idToken?.jwtToken;

        return token;
    }
    catch (error){
        return '';
    }
    
}


export const getHeader = async () => {
    return {
        headers: {
            Authorization: await getToken()
        }
    }
}

export const getSubPath = (header) => {
    if(header.headers.Authorization){
        return '/private/api/';
    }
    return '/api/'
}

export const setParams = (url, params) => {
    if(params){
        url += '?' 
        let arrParams = [];
        for(const name in params){
            arrParams.push(encodeURI(`${name}=${params[name] ? params[name] : ''}`))
        }
        url+=arrParams.join('&');
    }

    return url;
}

