import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./hooks/useAuth";
import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";
import store from "./store";
import { Provider } from "react-redux";

if (window.location.hostname === "localhost") {
    awsConfig.aws_cloud_logic_custom = awsConfig.aws_cloud_logic_custom.map((config) => {
        config.endpoint = `http://localhost:3001`;
        return config;
    });
}
let application = "client";
if (window.location.host === "localhost:5001" || window.location.host.startsWith("speaker.")) {
    application = "speaker";
}

if (window.location.host === "localhost:6001" || window.location.host.startsWith("preview.")) {
    application = "preview";
}

Amplify.configure(awsConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthProvider application={application}>
                <App application={application} />
            </AuthProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
