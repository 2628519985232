import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./DashboardNavbar";
import Drawer from "@mui/material/Drawer";

import { DashboardSidebar } from "./DashboardSidebar";
import { useAuth } from "../../hooks/useAuth";

import { Box, Snackbar, Alert, Popper } from "@mui/material";
import { Comment } from "../../components/Comment";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
        paddingLeft: 280,
    },
}));

export const DashboardLayout = (props) => {
    const { children, tab, publicPage, application } = props;
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const { getUserDetailsNotAsync } = useAuth();
    const [userDetails, setUserDetails] = useState({});
    const [openComment, setOpenComment] = useState(false);
    const [subject, setSubject] = useState(false);
    const [parentId, setParentId] = useState(false);
    const [commentType, setCommentType] = useState(false);
    const [speakerName, setSpeakerName] = useState(false);
    const [speakerId, setSpeakerId] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [parentName, setParentName] = useState("");

    const [openToster, setOpenToster] = useState(false);
    const [tosterVariant, setTosterVariant] = useState("info");
    const [tosterMessage, setTosterMessage] = useState("");
    const [anchorCommentEl, setAnchorCommentEl] = useState(null);
    const [commnetOnClose, setCommnetOnClose] = useState(null);
    const [opportunityOwner, setOpportunityOwner] = useState(null);

    const openCommentModel = Boolean(anchorCommentEl);
    const idComment = openCommentModel ? "leave-comment-fab-modal" : undefined;

    useEffect(() => {
        document.addEventListener("openDrawer", handleOpenDrawer);
        document.addEventListener("dispalyOpportunityOwner", handleDispalyOpportunityOwner);
        document.addEventListener("hideDrawer", handleHideDrawer);
        document.addEventListener("openToster", handleOpenToster);

        setUser();

        return () => {
            document.removeEventListener("openDrawer", handleOpenDrawer);
            document.removeEventListener("dispalyOpportunityOwner", handleDispalyOpportunityOwner);
            document.removeEventListener("hideDrawer", handleHideDrawer);
            document.removeEventListener("openToster", handleOpenToster);
        };
    }, []);

    const setUser = () => {
        let userDetails = getUserDetailsNotAsync();
        if (userDetails) {
            setUserDetails(userDetails);
        }
    };

    const handleDispalyOpportunityOwner = (event) => {
        setOpportunityOwner(event.detail);
    };

    const handleOpenToster = async (event) => {
        const detail = event.detail;
        setTosterVariant(detail.variant);
        setTosterMessage(detail.message);
        setOpenToster(true);
    };

    const handleOpenDrawer = (event) => {
        event.stopPropagation();
        const detail = event.detail;
        setSubject(detail.subject);
        setParentId(detail.parentId);
        setParentName(detail.parentName);
        setCommentType(detail.type);
        setSpeakerName(detail.speakerName);
        setSpeakerId(detail.speakerId);
        setAccountId(detail.accountId);
        if (detail.type === "popover") {
            setCommnetOnClose({ ...detail });
            setAnchorCommentEl(anchorCommentEl ? null : detail.target);
        } else {
            setOpenComment(true);
        }
    };
    const handleHideDrawer = (event) => {
        event.stopPropagation();
        setOpenComment(false);
        setAnchorCommentEl(null);
        if (event?.detail?.value && event?.detail?.value.onClose) {
            event?.detail?.value.onClose();
            setCommnetOnClose(undefined);
        }
    };

    const handleTosterClose = () => {
        setOpenToster(false);
    };

    return (
        <>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                        width: "100%",
                        padding: {
                            lg: "1.5rem 2rem",
                            xs: "1.5rem .5rem",
                        },
                        marginTop: {
                            lg: "0px",
                            xs: "50px",
                        },
                        maxWidth: tab === "speaker-search" ? "100%" : "1200px",
                        margin: "auto",
                    }}>
                    {children}
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
            <DashboardSidebar
                tab={tab}
                userDetails={userDetails}
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
                publicPage={publicPage}
                application={application}
                opportunityData={opportunityOwner}
            />
            <Popper
                id={idComment}
                placement="top-end"
                open={openCommentModel}
                anchorEl={anchorCommentEl}
                sx={{
                    height: "calc(100vh - 197px)",
                    background: "white",
                    border: "1px solid gray",
                    borderRadius: "10px",
                    marginBottom: "8px !important",
                    zIndex: "100000",
                }}>
                <Comment
                    subject={subject}
                    userDetails={userDetails}
                    parentId={parentId}
                    type={commentType}
                    speakerName={speakerName}
                    speakerId={speakerId}
                    applicationType={application}
                    displayType="popover"
                    detail={commnetOnClose}
                    accountId={accountId}
                    parentName={parentName}></Comment>
            </Popper>

            <Drawer sx={{ width: "200px", zIndex: "1500" }} anchor="right" open={openComment}>
                <Comment
                    subject={subject}
                    userDetails={userDetails}
                    parentId={parentId}
                    type={commentType}
                    speakerName={speakerName}
                    speakerId={speakerId}
                    applicationType={application}
                    accountId={accountId}
                    parentName={parentName}></Comment>
            </Drawer>

            <Snackbar
                sx={{ left: { lg: "calc(50% + 140px)" } }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openToster}
                autoHideDuration={6000}
                onClose={handleTosterClose}>
                <Alert onClose={handleTosterClose} severity={tosterVariant} variant="filled">
                    {tosterMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
