import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { User as UserIcon } from "../../icons/user";
import { format } from "date-fns";
import { Box, Button, Typography, Divider, IconButton } from "@mui/material";

import { Badge } from "../../components/Badge";

import { query, save } from "../../services/object";
import { skeleton } from "./skeleton";
import Skeleton from "@mui/material/Skeleton";
import { config, styles } from "../../style";
import { useTracking } from "react-tracking";
import "./style.css";
import { DocumentUpload } from "../DocumentUpload";
import { Document } from "../Document";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export const Comment = (props) => {
    console.log(props);
    const {
        subject,
        parentId,
        userDetails,
        displayInline,
        type,
        speakerName,
        speakerId,
        applicationType,
        displayType,
        detail,
        accountId,
        parentName,
    } = props;
    const [displayDropZone, setDisplayDropZone] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [initialLoading, setInitialLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [unreadComments, setUnreadComments] = useState([]);
    const [inlineComment, setInlineComment] = useState({});
    const [displayLineText, setDisplayLineText] = useState(false);

    const { trackEvent } = useTracking();
    const documentUploadRef = useRef();

    const commentsRef = useRef(comments);

    useEffect(() => {
        setInitialLoading(true);
        getComments();
    }, []);

    const setCommentReferance = (value) => {
        setComments(value);
        commentsRef.current = value;
    };

    const getComments = async () => {
        const result = await query("comment", "getRelatedRecords", {
            parentId: props.parentId,
            applicationType: applicationType,
            accountId: accountId ? accountId : userDetails?.contact?.accountId,
        });
        setInitialLoading(false);
        if (result.isSuccess) {
            let commentData = result.data;
            let loggedInUesrId = userDetails.username;
            let unreadComments = commentData.filter((item) => item.viewedUsers.indexOf(loggedInUesrId) === -1);

            let sortedComments = commentData.sort((p1, p2) =>
                p1.createdDate < p2.createdDate ? 1 : p1.createdDate > p2.createdDate ? -1 : 0
            );

            if (displayInline) {
                for (let comment of sortedComments) {
                    if (comment?.createdByName.startsWith("WSB")) {
                        setInlineComment(comment);
                        break;
                    }
                }
            }
            if (unreadComments.length > 0) {
                save(
                    "comment",
                    unreadComments.map((item) => {
                        return { ...item, viewedUsers: [...item.viewedUsers, loggedInUesrId] };
                    })
                );
            }
            setCommentRecords(sortedComments, unreadComments);
            setUnreadComments(unreadComments);
        }
    };

    const setCommentRecords = (sortedComments, unreadComments) => {
        let firstDate;
        sortedComments = sortedComments.map((obj, index) => {
            let secondDate = obj.createdDate;
            let sameDayComment = false;

            let displayDate = false;
            if (!firstDate) {
                displayDate = false;
                firstDate = obj.createdDate;
            } else {
                if (sortedComments.length > index + 1) {
                    secondDate = sortedComments[index + 1].createdDate;
                }
                displayDate = !sameDay(firstDate, secondDate);
                firstDate = secondDate;
            }

            if (index + 1 === sortedComments.length) {
                displayDate = true;
            }

            let isUnread = unreadComments.find((unreadItem) => obj.id === unreadItem.id);

            if (obj.createdByName?.includes("WSB")) {
                return {
                    ...obj,
                    isUnread: isUnread,
                    isReceived: true,
                    rowClass: isUnread ? "unread" : "",
                    class: "shared received",
                    authorClass: "author received",
                    dateClass: "date-stamp-right",
                    newDay: !sameDayComment,
                    displayDate: displayDate,
                };
            }
            return {
                ...obj,
                isUnread: isUnread,
                isSent: true,
                rowClass: isUnread ? "unread" : "",
                class: "shared sent",
                authorClass: "author sent",
                dateClass: "date-stamp-left",
                newDay: !sameDayComment,
                displayDate: displayDate,
            };
        });

        setCommentReferance(sortedComments);
    };

    const sameDay = (d1, d2) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const start_of_d1 = new Date(new Date(d1).toLocaleString("en-US", { timeZone: timezone }))
            .setHours(0, 0, 0, 0)
            .valueOf();
        const start_of_d2 = new Date(new Date(d2).toLocaleString("en-US", { timeZone: timezone }))
            .setHours(0, 0, 0, 0)
            .valueOf();

        console.log(timezone, start_of_d1, start_of_d2);
        return start_of_d1 === start_of_d2;
    };

    const handleHideDrawer = () => {
        const hideEvent = new CustomEvent("hideDrawer", { detail: { value: detail } });
        document.dispatchEvent(hideEvent);
        // trackEvent({
        //     action: 'click',
        //     type: 'comment-sidebar',
        //     description: 'Clicked to hide comment sidebar',
        //     parentId: parentId,
        //     speakerId: speakerId,
        //     speakerName: speakerName,
        //     userDetails: userDetails})
    };

    const handlePostComment = async (isFile) => {
        let subjectString = subject ? `${subject}` : "";
        if (type === "speaker-comment") {
            subjectString = `${speakerName}`;
        }
        if (type === "request-speakers") {
            subjectString = "Requested more speakers";
        }
        let record = {
            parentId: parentId,
            parentName: parentName,
            subject: subjectString,
            comment: isFile ? "Shared file" : commentText,
            createdByName: userDetails?.contact?.name,
            createdById: userDetails?.contact?.id,
            recipientId: userDetails?.contact?.accountOwnerId,
            speakerId: speakerId,
            accountId: accountId ? accountId : userDetails?.contact?.accountId,
            accountName: userDetails?.contact?.accountName,
            applicationType: applicationType,
            viewedUsers: [userDetails.username],
            fileType: isFile ? "File" : "Text",
        };
        const result = await save("comment", record);
        if (result.isSuccess) {
            setCommentRecords([result.data, ...commentsRef.current], []);
            //setComments([result.data, ...comments])
        }
        setCommentText("");
        trackEvent({
            action: "submit",
            type: "comment",
            description: subjectString,
            parentId: parentId,
            speakerId: speakerId,
            speakerName: speakerName,
            userDetails: userDetails,
        });
        if (isFile) {
            return result.data;
        }
    };

    const handleFileChange = async (files) => {
        let newFiles = [];
        for (let file of files) {
            let comment = await handlePostComment(true);
            file.parentId = comment.id;
            newFiles.push(file);
        }

        return newFiles;
    };

    const handleFileStatusChange = (documents) => {
        let mapDocument = {};
        for (const documentRecord of documents) {
            mapDocument[documentRecord.parentId] = documentRecord;
        }
        let records = commentsRef.current.map((comment) => {
            if (mapDocument[comment.id]) {
                comment.document = mapDocument[comment.id];
            }
            return { ...comment };
        });
        setCommentRecords(records, []);
    };

    const isUnread = (recordId) => {
        return unreadComments.find((item) => item.id === recordId);
    };

    const getTextaraeContainer = () => {
        return displayType === "popover"
            ? {
                  p: "0.5rem 0.4rem",
                  pt: "8px",
                  pb: "0px",
                  bottom: "0px",
                  position: "fixed",
                  width: "320px",
                  background: "white",
                  borderTop: "1px solid #d6d6d6",
                  paddingBottom: "8px",
                  borderRadius: "0 0 10px 10px",
              }
            : {
                  p: "0.5rem 0.4rem",
                  pt: "8px",
                  pb: "0px",
                  bottom: "0px",
                  position: "fixed",
                  width: "320px",
                  background: "white",
                  borderTop: "1px solid #d6d6d6",
                  paddingBottom: "8px",
              };
    };

    const getListStyle = () => {
        return displayType === "popover"
            ? {
                  marginTop: "22px",
                  position: "fixed",
                  height: "calc(100vh - 355px)",
                  overflow: "auto",
                  paddingBottom: subject ? "2rem" : "1rem",
                  overflowX: "hidden",
              }
            : {
                  marginTop: "22px",
                  position: "fixed",
                  height: "calc(100vh - 154px)",
                  overflow: "auto",
                  paddingBottom: subject ? "2rem" : "1rem",
                  overflowX: "hidden",
              };
    };

    const attachFileClick = () => {
        documentUploadRef.current.uploadFile();
    };

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed

            // Trigger the button element with a click
            if (commentText && !event.shiftKey) {
                event.preventDefault();
                event.stopPropagation();
                handlePostComment();
            }
            if (event.shiftKey) {
                return 13;
            }
        }
    };

    return (
        <>
            {displayInline ? (
                <>
                    {inlineComment ? (
                        <>
                            <Grid
                                sx={{ my: "15px", mb: "0px" }}
                                container
                                wrap="nowrap"
                                spacing={0}
                                justifyContent="start"
                                alignContent="center"
                                alignItems="center">
                                <Grid item>
                                    <UserIcon
                                        sx={{ border: "1px solid gray", borderRadius: "10px" }}
                                        fontSize="small"
                                        color="action"
                                    />
                                </Grid>
                                <Grid item sx={{ ...styles.cardHeading, marginTop: "-5px", marginLeft: "10px" }}>
                                    <Typography sx={styles.cardHeading}>{inlineComment.createdByName}</Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: "5px" }}>{inlineComment.comment}</Box>
                        </>
                    ) : (
                        <>
                            <Grid sx={{ my: "15px" }} container wrap="nowrap" spacing={0} justifyContent="start">
                                <Grid item>
                                    <Skeleton sx={{ mr: "10px" }} variant="circular" width={20} height={20} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rounded" width={150} height={20} />
                                </Grid>
                            </Grid>
                            <Box>
                                <Skeleton variant="rounded" width="100%" height={40} />
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <Box sx={{ width: "320px", fontSize: "13px" }}>
                    <Grid
                        sx={{
                            p: "0.5rem 0.4rem",
                            position: "fixed",
                            width: "320px",
                            background: "white",
                            borderBottom: "1px solid #d6d6d6",
                            marginTop: "-8px",
                            borderRadius: "10px 10px 0 0",
                        }}
                        container
                        wrap="nowrap"
                        spacing={0}
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item>
                            <Typography sx={{ fontSize: "12px" }}>
                                Proposal Comments ({comments ? comments.length : 0})
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleHideDrawer}
                                sx={{
                                    border: "1px solid #dedede",
                                    borderRadius: "5px",
                                    color: "#4F46E5",
                                    padding: "0",
                                }}>
                                <CloseIcon fontSize="small"></CloseIcon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            borderColor: "#e5e5e5",
                            my: 1,
                        }}
                    />

                    <Box className="list" sx={getListStyle()}>
                        {initialLoading
                            ? skeleton
                            : comments?.map((comment) => (
                                  <Box key={comment.id}>
                                      <Box sx={{ p: ".2rem .5rem" }} className={comment.rowClass}>
                                          {comment.displayDate && (
                                              <div className="date-divider">
                                                  {format(new Date(comment.createdDate), "MMMM dd, yyyy") ===
                                                  format(new Date(), "MMMM dd, yyyy") ? (
                                                      <>Today</>
                                                  ) : (
                                                      format(new Date(comment.createdDate), "MMMM dd, yyyy")
                                                  )}
                                              </div>
                                          )}
                                          <div className="comment-container">
                                              {comment.fileType === "File" ? (
                                                  <Box
                                                      className={comment.class}
                                                      sx={{
                                                          position: "relative",
                                                          height: "154px",
                                                          minWidth: "100px",
                                                          p: "2px",
                                                      }}>
                                                      <Document
                                                          parentId={comment.id}
                                                          documentRecord={comment.document}></Document>
                                                  </Box>
                                              ) : (
                                                  <div className={comment.class}>
                                                      {comment.subject && (
                                                          <Box sx={{ fontWeight: "800" }}>{comment.subject}</Box>
                                                      )}
                                                      <div style={{ whiteSpace: "pre-line" }}>{comment.comment}</div>
                                                  </div>
                                              )}

                                              {comment.createdById !== userDetails?.contact?.id && (
                                                  <div className={comment.authorClass}>
                                                      {comment.isUnread && comment.isSent && (
                                                          <div className="unread-dot right"></div>
                                                      )}

                                                      {comment.createdByName}
                                                      {comment.isUnread && comment.isReceived && (
                                                          <div className="unread-dot left"></div>
                                                      )}
                                                  </div>
                                              )}
                                          </div>

                                          {/* <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                            <Grid item>
                                                <UserIcon fontSize="20px" sx={{ border: '1px solid gray', borderRadius: '10px', color: 'gray' }}></UserIcon>
                                            </Grid>
                                            <Grid item sx={{ ...styles.cardHeading, flex: '1', ml: '5px', fontWeight: '600', fontSize: '15px', color: '#232323' }}>
                                                <Typography sx={{ ...styles.cardHeading, fontSize: '12px' }}>
                                                    <b>{comment.createdByName}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{ fontSize: '12px', color: '#7E7E7E' }}>
                                                    {format(new Date(comment.createdDate), 'MMM d, yyyy')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {comment.subject ? <Box sx={{ color: '#232323', fontSize: '12px', my: '8px' }}>
                                            <span style={{ color: '#7C2D12', fontSize: '12px', padding: '4px 8px', borderRadius: '3px', border: '1px solid #FED7AA', background: '#FFF7ED' }} >
                                                {comment.subject}
                                            </span>
                                        </Box> : ''}

                                        <Box sx={{ color: '#232323', fontSize: '12px' }}>
                                            {comment.comment}
                                        </Box> */}
                                      </Box>
                                  </Box>
                              ))}
                    </Box>
                    <Box
                        sx={{
                            position: displayDropZone ? "absolute" : "relative",
                            marginTop: displayDropZone ? "28px" : "0px",
                            height: displayDropZone ? "calc(100% - 60px)" : "0px",
                            width: displayDropZone ? "calc(100% + 2rem)" : "0px",
                        }}>
                        <DocumentUpload
                            ref={documentUploadRef}
                            parentId={""}
                            userDetails={userDetails}
                            disableIntialLoading={true}
                            onFileChange={handleFileChange}
                            disabledFileDisplay={true}
                            onFileStatusChange={handleFileStatusChange}
                            onDropZoneChange={(value) => setDisplayDropZone(value)}></DocumentUpload>
                    </Box>

                    <Box sx={getTextaraeContainer()}>
                        {subject && <Badge subject={`${subject}`}></Badge>}
                        <TextareaAutosize
                            onChange={(event) => setCommentText(event.target.value)}
                            aria-label="empty textarea"
                            placeholder="Type your comments"
                            value={commentText}
                            style={{
                                border: "1px solid #e5e5e5",
                                borderRadius: "5px",
                                fontFamily: "inherit",
                                fontSize: "12px",
                                padding: "0.5rem",
                                width: "100%",
                                minHeight: "40px",
                            }}
                            onKeyDown={handleKeyUp}
                            onClick={() => setDisplayLineText(true)}
                            onBlur={() => setDisplayLineText(false)}
                        />
                        <Grid
                            container
                            wrap="nowrap"
                            spacing={0}
                            justifyContent="start"
                            alignItems={"center"}
                            sx={{ width: "100%" }}>
                            {/* <Grid item>
                                    <Button variant="outlined" sx={{ mt: '2px', ml: 2, borderRadius: '5px', fontSize: '12px', p: '4px 8px', border: '1px solid #cbcbcb' }}>
                                        Cancel
                                    </Button>
                                </Grid> */}
                            {displayLineText && (
                                <Grid item sx={{ width: "100%" }}>
                                    <Typography sx={{ fontSize: ".6rem" }}>
                                        <b>Shift + Enter</b> to add a new line
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item sx={{ width: "100%" }}>
                                <Grid container wrap="nowrap" spacing={0} justifyContent="end" alignItems={"center"}>
                                    <Grid
                                        item
                                        sx={{ cursor: "pointer", verticalAlign: "middle", alignSelf: "center" }}
                                        onClick={attachFileClick}>
                                        <AttachFileIcon
                                            sx={{
                                                marginTop: "7px",
                                                fontSize: "1.2rem",
                                                color: "#20335B",
                                            }}></AttachFileIcon>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={() => handlePostComment()}
                                            disabled={commentText ? false : true}
                                            variant="contained"
                                            sx={{
                                                mt: "2px",
                                                ml: 2,
                                                borderRadius: "5px",
                                                fontSize: "12px",
                                                p: "4px 8px",
                                            }}>
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </>
    );
};
