import * as React from 'react';
import { Box } from '@mui/material';

const badgeStyle = {
    purple: {
        backgroundColor: '#EEF2FF',
        border: '1px solid #C7D2FE',
        borderRadius: '4px',
        fontSize: '12px',
        mb: '5px',
        padding: '2px 8px',
        color: '#312E81'

    },
    blue: {
        backgroundColor: '#e9ebef',
        border: '1px solid #20335b',
        fontSize: '12px',
        borderRadius: '4px',
        mb: '5px',
        padding: '4px 8px'
    },
    orange: {
        backgroundColor: '#fef0ee',
        border: '1px solid #fde1dd',
        borderRadius: '4px',
        fontSize: '12px',
        mb: '5px',
        padding: '4px 8px'
    }
};

const Badge = ({ subject, color = 'purple' }) => {

    return (
        <Box sx={{ display: 'flex' }} >
            <Box sx={{ ...badgeStyle[color] }}>
                {subject}
            </Box>
        </Box>
    );
}

export { Badge }
