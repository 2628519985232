import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { ReactComponent as WSBLogo } from '../../assets/images/logos/logo-wsb-color.svg';

import {
  Box
} from '@mui/material';

export const Logo = styled((props) => {
  const { variant, ...other } = props;

  const color = variant === 'light' ? '#C1C4D6' : '#5048E5';

  return (
    <Box sx={{ p: 3, width: '200px', m: 'auto' }}>
      <WSBLogo />
    </Box>
    
  );
})``;

Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
