import { createSlice } from "@reduxjs/toolkit";
import { query, describe } from "../../services/salesforce";

export const homeSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "home",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = "succeeded";
            state.error = null;
        },
        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? "loading" : "succeeded";
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? "initalLoading" : "succeeded";
        },
    },
});

export default homeSlice.reducer;

// Export all the reducers actions here
export const { initialize, error, loading, initialLoading } = homeSlice.actions;

// Public Selectors
export const selectHomeData = (state) => state.home.data;
export const selectHomeStatus = (state) => state.home.status;
export const selectHomeError = (state) => state.home.error;

export const initializeAsync = (params) => async (dispatch) => {
    dispatch(initialLoading(true));
    const userDetails = await params.getUserDetails();
    const result = await query("opportunity", "getOpenParentOpportunity", {
        accountId: userDetails?.contact?.accountId,
        contactId: userDetails?.contact?.id,
    });
    if (result.isSuccess) {
        dispatch(initialize({ records: result.data, userDetails: userDetails }));
    } else {
        dispatch(error(result.errorMessage));
    }
};
