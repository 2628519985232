import Skeleton from '@mui/material/Skeleton';

import {
    Box,
    Card,
    CardContent,
    Divider,
    Typography
  } from '@mui/material';

import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Card sx={styles.card}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                    <Grid item>
                        <Typography sx={styles.pageHeading}>
                            Help Document
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px', padding: '0px'}}>
            <CardContent sx={{paddingTop: '15px', p: 0}}>
                <Grid container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" sx={{minWidth: '100px', maxWidth: '400px'}} height={20} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={80} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px', padding: '0px'}}>
            <CardContent sx={{paddingTop: '15px', p: 0}}>
                <Grid container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" sx={{minWidth: '100px', maxWidth: '400px'}} height={20} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={80} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px', padding: '0px'}}>
            <CardContent sx={{paddingTop: '15px', p: 0}}>
                <Grid container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" sx={{minWidth: '100px', maxWidth: '400px'}} height={20} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={80} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
        <Card sx={{...styles.card, mt: '15px', padding: '0px'}}>
            <CardContent sx={{paddingTop: '15px', p: 0}}>
                <Grid container spacing={2} sx={{width: '100%', p: '1rem'}}>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" sx={{minWidth: '100px', maxWidth: '400px'}} height={20} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={80} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={12} item>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        
        
    </div>
)

