const config = {
    /** Colors */
    primaryColor: '#20335B',
    baseColor: '#232323',
    brandColor: '#ef6b19',
    grayColor: '#575757',
    successColor: '#4FBEAA',
    errorColor: '#F56856',
    pageBackgroundColor: '#FAFAF9',
    sidebarColor: 'linear-gradient(180deg, rgba(32,51,91,1) 0%, rgba(75,24,74,1) 100%)',


    /** Borders */
    border: '1px solid #D0D5DD',

    /** Font Weigth */
    baseFontWeight: '400',
    pageHeaderFontWeight: '700',
    cardHeaderFontWeight: '600',

    /** Font Size */
    baseFontSize: '16px',
    pageHeaderFontSize: '20px',
    cardHeaderFontSize: '20px',
    subHeadingFontSize: '16px'
}

const styles = {
    container: {
        fontSize: config.baseFontSize,
        fontWeight: config.baseFontWeight,
        color: config.baseColor,
    },
    card: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 0px 1px rgba(107, 144, 192, 0.0486779), 0px 1px 2px rgba(89, 97, 104, 0.147645), 0px 2px 8px rgba(89, 97, 104, 0.0960719)',
        borderRadius: '10px',
        minWidth: '275px',
        color: config.baseColor,
        cardHeaderColor: 'linear-gradient(90deg, rgba(32,51,91,1) 0%, rgba(75,24,74,1) 100%)',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0 0 8px 1px #20335b80'

        }
    },
    innerCard: {
        background: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #EAECF0',
        borderRadius: '10px',
        minWidth: '275px',
        color: config.baseColor,
        cardHeaderColor: 'linear-gradient(90deg, rgba(32,51,91,1) 0%, rgba(75,24,74,1) 100%)',
    },
    pageHeading: {
        fontSize: config.pageHeaderFontSize,
        fontWeight: config.pageHeaderFontWeight,
        color: config.baseColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },
    cardHeading: {
        fontSize: config.cardHeaderFontSize,
        fontWeight: config.cardHeaderFontWeight,
        color: config.baseColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },
    subHeading: {
        fontSize: config.subHeadingFontSize,
        color: '#667085',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },
    textButton: {
        padding: '0px',
        background: 'transparent !important',
        padding: '0px',
        color: config.baseColor
    },
    outlinedButton: {
        padding: '4px 12px',
        background: 'white',
        color: config.baseColor,
        border: config.border,
    },
    outlinedSuccessButton: {
        padding: '4px 12px',
        background: 'white',
        color: config.successColor,
        border: config.border,
    },
    outlinedErrorButton: {
        padding: '4px 12px',
        background: 'white',
        color: config.errorColor,
        border: config.border,
    },
    outlineButtonToggle: {

        padding: '4px 12px',
        background: 'white',
        color: config.baseColor,
        border: config.border,
        borderLeft: 'unset !important',
        marginLeft: '0px !important'
    },
    hero: {
        position: 'relative',
        width: '100%',
        height: '80vh',
        '& img': {
            objectFit: 'cover',
        },
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    buttonHoverFilled: {
        '&:hover': {
            backgroundColor: 'white',
            color: '#1A2847',
            border: '1px solid white',
            ' .MuiButton-startIcon svg': {
                color: '#F56856'
            }
        }
    }

}

export { config, styles }