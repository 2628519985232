import { get, post } from "../../api";

const encrypt = async (params) => {
    return new Promise(async (resolve, reject) => {
        get("utils/encrypt", params)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export { encrypt };
