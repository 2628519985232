import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent } from "../../utils";
import {
    selectSpeakerHoldsData,
    selectSpeakerHoldsStatus,
    selectSpeakerHoldsError,
    initializeAsync,
    updateRequestAsync,
    saveRequest,
} from "./speakerHoldsSlice";

// Add custom imports here
import { useAuth } from "../../hooks/useAuth";

import { Box, Card, Button, TextField, Typography, CardContent, MenuItem, Divider, Drawer, Menu } from "@mui/material";
import { red } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { skeleton } from "./skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { config, styles } from "../../style";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterListIcon from "@mui/icons-material/FilterList";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AddCommentIcon from "@mui/icons-material/AddComment";

const SpeakerHolds = () => {
    const data = useSelector(selectSpeakerHoldsData);
    const status = useSelector(selectSpeakerHoldsStatus);
    const error = useSelector(selectSpeakerHoldsError);
    const dispatch = useDispatch();
    const { getUserDetails } = useAuth();

    // Add Variables

    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);
    const [requestFilter, setRequestFilter] = useState("All");
    const [commentChanged, setCommentChanged] = useState(false);

    useEffect(() => {
        if (status === "idle") {
            dispatch(initializeAsync({ getUserDetails }));
        }
    }, [status, dispatch, getUserDetails]);

    const handleFilterMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (filterValue) => {
        setRequestFilter(filterValue);
        setAnchorEl(null);
    };

    const saveComment = (record, value) => {
        if (commentChanged) {
            let clonedRecord = JSON.parse(JSON.stringify(record));
            clonedRecord.speakerComment = value;
            dispatch(updateRequestAsync({ record: clonedRecord }));
            setCommentChanged(false);
        }
    };

    const updateType = (record, type) => {
        if (type && type !== record.type) {
            let clonedRecord = JSON.parse(JSON.stringify(record));
            clonedRecord.type = type;
            dispatch(updateRequestAsync({ record: clonedRecord, displayLoading: true }));
        }
    };

    const handleCommentChange = (record, value) => {
        if (value !== record.speakerComment) {
            let clonedRecord = JSON.parse(JSON.stringify(record));
            clonedRecord.speakerComment = value;
            dispatch(saveRequest({ data: clonedRecord, recordId: clonedRecord.id }));
            setCommentChanged(true);
        }
    };

    const getFilteredRecord = () => {
        if (data?.records && data?.records.length > 0) {
            if (requestFilter === "Accept hold") {
                return data.records.filter((item) => item.type === "ACCEPT HOLD");
            }
            if (requestFilter === "Not Available") {
                return data.records.filter((item) => item.type === "NOT AVAILABLE");
            }

            return data.records;
        } else {
            return [];
        }
    };

    const handleOpenComment = (subject) => {
        const event = new CustomEvent("openDrawer", {
            detail: {
                parentId: data?.userDetails?.contact?.accountId,
                parentName: data?.userDetails?.contact?.accountName,
                subject: subject,
                accountId: data?.userDetails?.contact?.accountId,
            },
        });
        document.dispatchEvent(event);
    };

    let body = (
        <Box>
            {status === "initalLoading" ? (
                skeleton
            ) : (
                <Box sx={styles.container}>
                    <Card sx={styles.card}>
                        <CardContent sx={{ paddingTop: "15px" }}>
                            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={styles.pageHeading}>
                                        Event hold request for {data?.userDetails?.contact?.accountName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ mx: "-15px", mt: "15px" }} />

                            <Grid container wrap="nowrap" spacing={3} sx={{ flexGrow: 1, my: "3px", pb: 0 }}>
                                <Grid xs={6} xsOffset={3} md={3} mdOffset={0} sx={{ pb: 0 }}>
                                    <Button
                                        id="filter-speakers"
                                        aria-controls={filterOpen ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={filterOpen ? "true" : undefined}
                                        onClick={handleFilterMenuClick}
                                        startIcon={
                                            <FilterListIcon fontSize="small" sx={{ color: config.primaryColor }} />
                                        }
                                        sx={{ ...styles.outlinedButton, width: "100%" }}
                                        variant="outlined">
                                        {`Request Hold Filters: ${requestFilter}`}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={filterOpen}
                                        onClose={() => handleFilterMenuClose("All")}
                                        MenuListProps={{
                                            "aria-labelledby": "filter-speakers",
                                        }}>
                                        <MenuItem onClick={() => handleFilterMenuClose("All")} value="All">
                                            All
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => handleFilterMenuClose("Accept hold")}
                                            value="Accept hold">
                                            Accept hold
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => handleFilterMenuClose("Not Available")}
                                            value="Not Available">
                                            Not Available
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                                <Grid xs={4} md={2} mdOffset="auto" sx={{ pb: 0 }}>
                                    <Button
                                        id="leave-comment"
                                        onClick={() => handleOpenComment("")}
                                        sx={{ ...styles.outlinedButton, width: "100%" }}
                                        startIcon={
                                            <AddCommentIcon fontSize="small" sx={{ color: config.primaryColor }} />
                                        }
                                        variant="outlined">
                                        Add comments
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {getFilteredRecord().map((item) => (
                        <Box>
                            <Card sx={{ ...styles.card, mt: "15px" }}>
                                <CardContent sx={{ paddingTop: "15px" }}>
                                    <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                                        <Grid xs={12} md={6} mdOffset={0} sx={{ borderRight: config.border }}>
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                sx={{ p: 0 }}
                                                spacing={0}
                                                justifyContent="space-between">
                                                <Grid sx={styles.cardHeading} item>
                                                    {item.opportunityName}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                sx={{ pl: 0, fontSize: "12px", mt: "-10px" }}
                                                container
                                                wrap="nowrap"
                                                spacing={0}
                                                justifyContent="start">
                                                <Grid item>
                                                    {item?.startDate ? (
                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                                mr: "20px",
                                                                fontSize: "12px",
                                                            }}>
                                                            <CalendarMonthIcon
                                                                fontSize="small"
                                                                sx={{ color: "gray", width: "15px" }}
                                                            />
                                                            <span style={{ marginLeft: "5px" }}>
                                                                {format(new Date(item?.startDate), "MMMM d, yyyy")}
                                                            </span>
                                                        </Box>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    {item?.locationCity && (
                                                        <Box
                                                            sx={{
                                                                display: "inline-flex",
                                                                verticalAlign: "text-bottom",
                                                                boxSizing: "inherit",
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                                fontSize: "12px",
                                                            }}>
                                                            <LocationOnIcon
                                                                fontSize="small"
                                                                sx={{ color: "gray", width: "15px" }}
                                                            />
                                                            <span style={{ marginLeft: "5px" }}>
                                                                {item?.locationCity}, {item?.locationState}
                                                            </span>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Box>{item.note}</Box>
                                        </Grid>

                                        <Grid xs={12} md={3} sx={{ borderRight: config.border }}>
                                            <Grid
                                                sx={{ m: "-10px" }}
                                                container
                                                wrap="nowrap"
                                                spacing={0}
                                                justifyContent="space-between">
                                                <Grid xs={12} item>
                                                    <b>Your Comment</b>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                sx={{ m: "-10px" }}
                                                container
                                                wrap="nowrap"
                                                spacing={0}
                                                justifyContent="space-between">
                                                <Grid xs={12} item>
                                                    <TextField
                                                        fullWidth
                                                        value={item.speakerComment}
                                                        multiline
                                                        rows={4}
                                                        label=""
                                                        variant="outlined"
                                                        onChange={(event) =>
                                                            handleCommentChange(item, event.target.value)
                                                        }
                                                        onBlur={(event) => saveComment(item, event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} md={3} mdOffset={0}>
                                            <Button
                                                onClick={() => updateType(item, "ACCEPT HOLD")}
                                                sx={{
                                                    ...styles.outlinedSuccessButton,
                                                    width: "100%",
                                                    background: item?.type === "ACCEPT HOLD" ? green[50] : "",
                                                }}
                                                startIcon={<TaskAltIcon fontSize="small" />}
                                                variant="outlined">
                                                {item?.type === "ACCEPT HOLD" ? "Accepted hold" : "Accept hold"}
                                            </Button>
                                            <Button
                                                onClick={() => updateType(item, "NOT AVAILABLE")}
                                                sx={{
                                                    ...styles.outlinedErrorButton,
                                                    width: "100%",
                                                    mt: "10px",
                                                    background: item?.type === "NOT AVAILABLE" ? red[50] : "",
                                                }}
                                                startIcon={<CloseIcon fontSize="small" />}
                                                variant="outlined">
                                                {item?.type === "NOT AVAILABLE" ? "Not available" : "Not available"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );

    return getPageContent(body, status, error);
};

export { SpeakerHolds };
