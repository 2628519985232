import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import {
    selectSharedProposalData,
    selectSharedProposalStatus,
    selectSharedProposalError,
    initializeAsync,
} from './sharedProposalSlice';

// Add custom imports here
import {
    Box,
    Card,
    Button,
    TextField,
    Typography,
    CardContent,
    MenuItem,
    Divider,
    Drawer,
    Menu
} from '@mui/material';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';

import { format } from 'date-fns';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonIcon from '@mui/icons-material/Person';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal } from '../../components/Modal'
import { CopyToClipboard } from 'react-copy-to-clipboard';


import { config, styles } from '../../style';
import { skeleton } from './skeleton';
import Skeleton from '@mui/material/Skeleton';
import { Speaker } from '../Speaker';
import { useAuth } from '../../hooks/useAuth';


const SharedProposal = () => {
    const data = useSelector(selectSharedProposalData);
    const status = useSelector(selectSharedProposalStatus);
    const error = useSelector(selectSharedProposalError);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { recordId } = useParams();
    const { getUserDetails } = useAuth();

    const [speakerFilter, setSpeakerFilter] = useState('All');
    const [openShareLinkModal, setOpenShareLinkModal] = useState(false)
    const [sharedUrl, setSharedUrl] = useState('')
    const [openSpeakerScreen, setOpenSpeakerScreen] = useState(false);
    const [selectedSpeakerId, setSelectedSpeakerId] = useState('');
    const [userDetails, setUserDetails] = useState({})
    const [selectedSpeaker, setSelectedSpeaker] = useState({})
    const [linkCopied, setLinkCopied] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);
    const handleFilterMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (filterValue) => {
        setSpeakerFilter(filterValue);
        setAnchorEl(null);
    }

    const opportunityData = useSelector((state) => {
        let data = state.sharedProposal.data[recordId]
        let mapRecords = {}
        if (data) {
            for (let record of data?.records) {
                let key = record.proposalGroupId ? record.proposalGroupId : 'x';
                if (!mapRecords[key]) {
                    mapRecords[key] = {
                        group: {
                            id: record.proposalGroupId,
                            name: record.proposalGroupName,
                            description: record.proposalGroupDescription
                        },
                        records: []
                    }
                }
                (mapRecords[key])?.records?.push(record)
            }
            return { ...data, groupedData: mapRecords }
        }

        return data

    });

    console.log('opportunityData', opportunityData)

    useEffect(() => {
        document.addEventListener('closeSpeakerDrawer', handleCloseSpeakerDrawer);
        if (!data[recordId]) {
            dispatch(initializeAsync({ recordId }));
        }
        setUser();
        return () => {
            document.removeEventListener("closeSpeakerDrawer", handleCloseSpeakerDrawer);
        }
    }, [dispatch, data, recordId]);

    const setUser = async () => {
        let userDetails = await getUserDetails()
        setUserDetails(userDetails)
    }

    const handleAboutClick = (event, id, record) => {
        event.stopPropagation()
        setSelectedSpeaker(record)
        setSelectedSpeakerId(id)
        setOpenSpeakerScreen(true)
    }

    const handleCloseSpeakerDrawer = (event) => {
        event.stopPropagation()
        setOpenSpeakerScreen(false)
        setSelectedSpeakerId('')
    }

    const getAgentNotes = (record) => {
        if (record?.speakerDetails?.filter(item => item.type === 'Agent Notes')?.length > 0) {
            return record?.speakerDetails?.filter(item => item.type === 'Agent Notes')[0].agentNotes
        }
        return ''
    }


    const cardComponent = (records, group) => {
        if (records && records.length > 0) {
            return (<>
                {group?.name ?
                    <Grid item lg={12} sx={{ mt: '15px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: '500' }}>
                            <h2>{group?.name}</h2>
                        </Box>
                        <Box sx={{ fontSize: '12px', color: 'gray' }}>
                            {group?.description}
                        </Box>
                    </Grid> : <Box sx={{ mt: '15px', fontSize: '14px', fontWeight: '500' }}>Uncategorized Speakers</Box>}
                {records.map((record) => (
                    <Card sx={{ ...styles.card, mt: '15px' }} id={`card-${record.id}`} key={`card-${record.id}`}>
                        <CardContent sx={{ paddingTop: '15px' }}>
                            <Grid container spacing={3} sx={{ flexGrow: 1, m: '-15px' }}>
                                <Grid xs={12} md={6} mdOffset={0} sx={{ borderRight: config.border }}>
                                    <Grid sx={{ m: '-10px' }} container wrap="nowrap" spacing={0} justifyContent="start">
                                        <Grid item>
                                            {record.speakerImageURL && <Box component="img" sx={{ objectFit: 'cover', width: '110px', height: '130px', objectPosition: 'top', borderRadius: '4px', mr: '20px' }} src={`${record.speakerImageURL}`}  ></Box>}
                                            {!record.speakerImageURL && <Skeleton sx={{ mr: '20px' }} variant="rounded" width={110} height={130} />}
                                        </Grid>
                                        <Grid sx={{ width: '100%', marginLeft: '30px' }} item>
                                            <Grid sx={{ m: '-10px' }} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                                <Grid item>
                                                    <Typography sx={styles.cardHeading}>
                                                        {record.speakerName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {record.speakerStatus === 'Exclusive' && <Typography sx={{ color: '#ef6b19', fontSize: '12px', fontSize: '12px', border: '1px solid gainsboro', padding: '0px 5px', borderRadius: '5px', background: '#fffbf3' }} >
                                                        WSB Exclusive Speaker
                                                    </Typography>}
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ my: '10px' }}>
                                                <Typography sx={{ fontSize: '12px' }} >
                                                    {record.speakerPromotionalTitle}
                                                </Typography>
                                                {/* <Typography sx={{ fontSize: '12px' }} >
                                                    <div dangerouslySetInnerHTML={{ __html: record.speakerBoldStatement }} />
                                                </Typography> */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ m: '-10px' }} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                        <Grid item>
                                            <Button id={`about-${record.speakerId}`} onClick={(event) => handleAboutClick(event, record.speakerId, record)} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<PersonIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">About</Button>
                                        </Grid>
                                        {/* <Grid item>
                                            <Button onClick={(event) => handleRequestAvailabilityClick(event, record.speakerId, record)} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<EventAvailableIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Request availability</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => handleOpenComment('Commented on ' + record.speakerName)} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<AddCommentIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Leave a comment</Button>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={3} sx={{ borderRight: config.border }}>
                                    <h3>Fees</h3>
                                    {record?.speakerDetails?.filter(item => item.type === 'Fee')?.length > 0 ?
                                        (
                                            record.speakerDetails.filter(item => item.type === 'Fee').map(item => (
                                                <Grid sx={{ m: '-10px' }} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                                    <Grid xs={8} item>
                                                        {item.feeName?.split(/-(.*)/s)[1]}
                                                    </Grid>
                                                    <Grid item>
                                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(item.feeAmount ? item.feeAmount : 0)}
                                                    </Grid>

                                                </Grid>
                                            ))
                                        )
                                        : 'No Fee Available'
                                    }
                                    {getAgentNotes(record) &&
                                        (
                                            <div style={{ marginTop: "1rem" }}>
                                                <Typography variant="h3" sx={{ mb: '5px', fontSize: '18px' }}>Agent notes</Typography>
                                                <Grid sx={{ m: '-10px' }} container wrap="nowrap" spacing={0} justifyContent="space-between">
                                                    <Grid item>
                                                        {getAgentNotes(record)}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }

                                </Grid>
                                <Grid xs={12} md={3} mdOffset={0}>
                                    <Button onClick={(event) => shortListProposal(event, record)} sx={{ ...styles.outlinedSuccessButton, width: '100%', background: record?.status === 'Shortlist' ? green[50] : '' }} startIcon={<TaskAltIcon fontSize='small' />} variant="outlined" data-amplify-analytics-on="click"
                                        data-amplify-analytics-name="shortlistedSpeaker"
                                        data-amplify-analytics-attrs={`speakerName:${record.speakerName},speakerId:${record.speakerId}`} id={`shortlist-${record.speakerId}`}>
                                        {record?.status === 'Shortlist' ? 'Shortlisted' : 'Shortlist'}
                                    </Button>
                                    <Button onClick={(event) => removeProposal(event, record)} sx={{ ...styles.outlinedErrorButton, width: '100%', mt: '10px', background: record?.status === 'Removed' ? red[50] : '' }} startIcon={<CloseIcon fontSize='small' />} variant="outlined" data-amplify-analytics-on="click"
                                        data-amplify-analytics-name="rejectedSpeaker"
                                        data-amplify-analytics-attrs={`speakerName:${record.speakerName},speakerId:${record.speakerId}`}>
                                        {record?.status === 'Removed' ? 'Rejected' : 'Remove'}
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                ))
                }
            </>)
        }
        return ''

    }

    const shortListProposal = (event, record) => {
        event.stopPropagation()
    }

    const removeProposal = (event, record) => {
        event.stopPropagation()
    }


    const handleShareLinkModalClose = () => {
        setSharedUrl('');
        setOpenShareLinkModal(false)
        setLinkCopied(false);
    }


    let body = (
        <div>
            {status === 'initalLoading' ? skeleton :
                <div style={styles.container}>
                    <Card sx={styles.card}>
                        <CardContent sx={{ pt: '15px', pb: '15px' }}>
                            <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={styles.pageHeading}>
                                        {opportunityData?.opportunity?.eventName}
                                    </Typography>
                                </Grid>
                                {/* <Grid item>
                                    <Button onClick={sharedProposalClick} startIcon={<ShareIcon sx={{ color: config.primaryColor }} fontSize='small' />} variant="text" sx={styles.textButton}>Share Proposal</Button>
                                </Grid> */}
                            </Grid>
                            <Grid sx={{ my: '5px' }} container wrap="nowrap" spacing={0} justifyContent="start">
                                <Grid item>
                                    {
                                        opportunityData?.opportunity?.startPlayDate ? (
                                            <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'center', mr: '20px', fontSize: '16px' }}>
                                                <CalendarMonthIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                                <span style={{ marginLeft: '5px' }}>{format(new Date(opportunityData?.opportunity?.startPlayDate), 'MMMM d, yyyy')}</span>
                                            </Box>
                                        ) : ''
                                    }
                                </Grid>
                                <Grid item>
                                    {
                                        opportunityData?.opportunity?.venueName &&
                                        <Box sx={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'center', fontSize: '16px' }}>
                                            <LocationOnIcon fontSize="small" sx={{ color: 'gray', width: '15px' }} />
                                            <span style={{ marginLeft: '5px' }}>{opportunityData?.opportunity?.venueName}</span>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                            <Divider sx={{ mx: '-15px', my: '10px' }} />

                            {opportunityData?.opportunity?.proposalIntroduction &&
                                <Box sx={{ backgroundColor: styles.pageBackgroundColor, borderRadius: '4px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: opportunityData?.opportunity?.proposalIntroduction }} />
                                </Box>
                            }
                            <Divider sx={{ mx: '-15px', mt: '15px' }} />

                            <Grid container spacing={3} sx={{ flexGrow: 1, my: '3px' }}>
                                <Grid xs={12} md={3} mdOffset={0} sx={{ pb: '0px' }}>
                                    <Button
                                        id="filter-speakers"
                                        aria-controls={filterOpen ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={filterOpen ? 'true' : undefined}
                                        onClick={handleFilterMenuClick}
                                        startIcon={<FilterListIcon fontSize='small' sx={{ color: config.primaryColor }} />}
                                        sx={{ ...styles.outlinedButton, width: '100%' }}
                                        variant="outlined"
                                    >
                                        {`Speaker Filters: ${speakerFilter}`}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={filterOpen}
                                        onClose={() => handleFilterMenuClose("All")}
                                        MenuListProps={{
                                            'aria-labelledby': 'filter-speakers',
                                        }}

                                    >
                                        <MenuItem onClick={() => handleFilterMenuClose("All")} value="All">All</MenuItem>
                                        <MenuItem onClick={() => handleFilterMenuClose("Shortlisted")} value="Shortlisted">Shortlisted Speakers</MenuItem>
                                        <MenuItem onClick={() => handleFilterMenuClose("Rejected")} value="Rejected">Rejected Speakers</MenuItem>
                                    </Menu>

                                </Grid>
                                {/* <Grid xs={6} md={3} xsOffset="auto" mdOffset="auto" sx={{ pb: '0px' }}>
                                    <Button id="request-speakers" onClick={() => handleOpenComment('Requested more speakers')} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<AddCircleOutlineIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Request more speakers</Button>
                                </Grid>
                                <Grid xs={6} md={3} mdOffset={0} sx={{ pb: '0px' }}>
                                    <Button id="leave-comment" onClick={() => handleOpenComment('')} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<AddCommentIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Add comments</Button>
                                </Grid> */}
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box sx={{ pt: 0 }}>

                        {
                            speakerFilter === 'Shortlisted' && opportunityData?.groupedData && Object.keys(opportunityData?.groupedData).map((key) => (
                                cardComponent(opportunityData?.groupedData[key]?.records.filter(item => item.status === 'Shortlist'), opportunityData?.groupedData[key]?.group)
                            ))
                        }

                        {
                            speakerFilter === 'Rejected' && opportunityData?.groupedData && Object.keys(opportunityData?.groupedData).map((key) => (
                                cardComponent(opportunityData?.groupedData[key]?.records.filter(item => item.status === 'Removed'), opportunityData?.groupedData[key]?.group)
                            ))
                        }

                        {
                            speakerFilter === 'All' && opportunityData?.groupedData && Object.keys(opportunityData?.groupedData).map((key) => (
                                cardComponent(opportunityData?.groupedData[key]?.records, opportunityData?.groupedData[key]?.group)
                            ))
                        }

                    </Box>
                </div>
            }
            <Modal open={openShareLinkModal} title="Shared Link" onClose={handleShareLinkModalClose} hideActions maxWidth="md" >
                <Box>
                    <TextField
                        fullWidth
                        label="Shared Link"
                        defaultValue=" "
                        value={sharedUrl}
                        InputProps={{
                            readOnly: true
                        }}
                        sx={{ mb: '20px' }}
                    />
                    <CopyToClipboard text={sharedUrl}
                        onCopy={() => setLinkCopied(true)}>
                        <Button variant="outlined">{linkCopied ? 'Copied!' : 'Copy link'}</Button>
                    </CopyToClipboard>
                    <span style={{ fontSize: '12px', color: '#65748b', marginLeft: '13px' }}>This link will expire in 15 days.</span>
                </Box>
            </Modal>
            <Drawer
                anchor='right'
                open={openSpeakerScreen}
            >
                <Speaker width='700px' shared={true} opportunityId={recordId} speakerId={selectedSpeakerId} record={selectedSpeaker}></Speaker>
            </Drawer>

        </div>
    )

    return getPageContent(body, status, error);
}

export { SharedProposal }