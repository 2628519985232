import { createSlice } from '@reduxjs/toolkit';
import { query, describe, create, update } from '../../services/salesforce'
import { get } from '../../api';
import {query as dbQuery} from '../../services/object';

export const speakerSearchSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'speakerSearch',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = { ...state.data, speakerLists: action.payload.speakerLists };
            state.status = 'succeeded';
            state.error = null;
            if (!state.data.listBySpeaker) { 
                state.data.listBySpeaker = {}
            }
            if (state.data.speakerLists.length > 0) {
                for (let list of state.data.speakerLists) {
                    if (list.members?.length > 0) {
                        for (let member of list.members) {
                            if (!state.data.listBySpeaker[member.speakerId]) {
                                state.data.listBySpeaker[member.speakerId] = [];
                            }
                            state.data.listBySpeaker[member.speakerId].push(member.speakerListId)
                        }
                    }
                    
                }
            }
        },
        initializeCommnets: (state, action) => {
            state.data = { ...state.data, comments: action.payload.comments };
            if (!state.data.commentBySpeaker) { 
                state.data.commentBySpeaker = {}
            }
            if (state.data.comments.length > 0) { 
                for (let comment of state.data.comments) { 
                    if (!state.data.commentBySpeaker[comment.parentId]) {
                        state.data.commentBySpeaker[comment.parentId] = [];
                    }
                    state.data.commentBySpeaker[comment.parentId].push(comment);
                }
            }
        },
        initializeNotes: (state, action) => {
            state.data = { ...state.data, notes: action.payload.notes };
            if (!state.data.noteBySpeaker) { 
                state.data.noteBySpeaker = {}
            }
            if (state.data.notes.length > 0) { 
                for (let note of state.data.notes) { 
                    state.data.noteBySpeaker[note.speakerId] = note;
                }
            }
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
        saveSpeakerList: (state, action) => {
            if (!state.data.speakerLists) {
                state.data.speakerLists = []
            } 
            state.data.speakerLists.push(action.payload.data);
            for (let member of action.payload.data?.members) {
                if (!state.data.listBySpeaker[member.speakerId]) {
                    state.data.listBySpeaker[member.speakerId] = [];
                }
                state.data.listBySpeaker[member.speakerId].push(member.speakerListId)
            }

        },
        saveSpeakerListMember: (state, action) => {
            state.data.speakerLists = state.data.speakerLists.map(item => {
                if (item.id === action.payload.data.speakerListId) {
                    if (!item.members) {
                        item.members = [];
                    }
                    item.members.push(action.payload.data);
                    return item;
                }
                return item;
            })
            if (!state.data.listBySpeaker[action.payload.data.speakerId]) {
                state.data.listBySpeaker[action.payload.data.speakerId] = [];
            }
            state.data.listBySpeaker[action.payload.data.speakerId].push(action.payload.data.speakerListId)
        },
        updateSpeakerList: (state, action) => {
            if (!action.payload.data?.isDeleted) {
                state.data.speakerLists = state.data.speakerLists.map(item => {
                    if (item.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return item;
                })
            } else {
                let speakerList = state.data.speakerLists.find(item => item.id == action.payload.data.id)
                if (speakerList && speakerList.members?.length > 0) {
                    for (let member of speakerList.members) {
                        state.data.listBySpeaker[member.speakerId] = state.data.listBySpeaker[member.speakerId].filter(item => item !== action.payload.data.id)
                    }
                }
                state.data.speakerLists = state.data.speakerLists.filter(item => item.id !== action.payload.data.id)
            }
            
            
        },
        removeSpeakerListMember: (state, action) => {
            state.data.speakerLists = state.data.speakerLists.map(item => {
                if (item.id === action.payload.data.speakerListId) {
                    item.members = item.members.filter(item => item.id !== action.payload.data.id);
                    return item;
                }
                return item;
            })
            if (!state.data.listBySpeaker[action.payload.data.speakerId]) {
                state.data.listBySpeaker[action.payload.data.speakerId] = [];
            }
            state.data.listBySpeaker[action.payload.data.speakerId] = state.data.listBySpeaker[action.payload.data.speakerId].filter(item => item !== action.payload.data.speakerListId)
        },
        saveNotes: (state, action) => {
            state.data.noteBySpeaker[action.payload.data.speakerId] = action.payload.data;
        },
        addSpeakerDetails: (state, action) => {
            if (!state.data.speakerDetails) {
                state.data.speakerDetails = {}
            }
            state.data.speakerDetails[action.payload.data.id] = action.payload.data;
        },

    },
})

export default speakerSearchSlice.reducer

// Export all the reducers actions here
export const {
    initialize,
    error,
    loading,
    initialLoading,
    saveSpeakerList,
    saveSpeakerListMember,
    removeSpeakerListMember,
    updateSpeakerList,
    saveNotes,
    initializeCommnets,
    initializeNotes,
    addSpeakerDetails
} = speakerSearchSlice.actions;


// Public Selectors
export const selectSpeakerSearchData = (state) => state.speakerSearch.data;
export const selectSpeakerSearchStatus = (state) => state.speakerSearch.status;
export const selectSpeakerSearchError = (state) => state.speakerSearch.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true))
    let result = await query(`speakerList`, 'getSpeakerLists', { contactId: props.contactId });
    if (result.isSuccess) {
        dispatch(initialize({speakerLists: result.data}));
    }
    else{
        dispatch(error(result.errorMessage))
    }  
}
export const getCommentsAsync = (props) => async (dispatch) => {
    let resultComment = await dbQuery('comment', 'getAllAccountRecords', { applicationType: props.application, accountId: props.accountId })
    if (resultComment.isSuccess) {
        dispatch(initializeCommnets({comments: resultComment.data}));
    } 
}
export const getNotesAsync = (props) => async (dispatch) => {
    let resultNotes = await query('portalNote', 'getRecords', { contactId: props.contactId })
    if (resultNotes.isSuccess) {
        dispatch(initializeNotes({notes: resultNotes.data}));
    }
}

export const createSpeakerListAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const result = await create('speakerList', props.list);
    if (result.isSuccess) {
        let speakerList = result.data;
        dispatch(saveSpeakerList({ data: result.data }));
        const resultMemeber = await create('speakerListMember', { speakerListId: speakerList.id, speakerId: props.speakerId });
        if (resultMemeber.isSuccess) {
            dispatch(saveSpeakerListMember({ data: resultMemeber.data }));
            dispatch(loading(false))
        }
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const addSpeakerToListAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const result = await create('speakerListMember', { speakerListId: props.listId, speakerId: props.speakerId });
    if (result.isSuccess) {
        if (result.isSuccess) {
            dispatch(saveSpeakerListMember({ data: result.data }));
            dispatch(loading(false))
        }
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const removeSpeakerFromListAsync  = (props) => async (dispatch) => {
    dispatch(loading(true))
    const result = await update('speakerListMember', { ...props.member, isDeleted: true });
    if (result.isSuccess) {
        if (result.isSuccess) {
            dispatch(removeSpeakerListMember({ data: result.data }));
            dispatch(loading(false))
        }
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const updateSpeakerListAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const result = await update('speakerList', props.list);
    if (result.isSuccess) {
        dispatch(updateSpeakerList({ data: result.data }));
        dispatch(loading(false))
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const saveNotesAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    let result;
    if (props.note.id) {
        result = await update('portalNote', props.note);
    }
    else {
        result = await create('portalNote', props.note);
    }
    if (result.isSuccess) {
        dispatch(saveNotes({ data: result.data }));
        dispatch(loading(false))
    }
    else {
        dispatch(error(result.errorMessage))
    }
}

export const getSpeakerDetailsAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    let result = await query(`speaker`, 'getRecordById', { queryFields: 'getQueryFieldsWithDetails', recordId: props.id });
    if (result.isSuccess) {
        if (result.data?.length > 0) {
            dispatch(addSpeakerDetails({ data: result.data[0] }));
        }
        
        dispatch(loading(false))
    }
    else {
        dispatch(error(result.errorMessage))
    }
    props.callback(result)
}





