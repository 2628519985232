import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import StarterKit from "@tiptap/starter-kit";
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuButtonUnderline,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor,
    MenuButtonAddTable,
    MenuButtonBlockquote,
    MenuButtonBulletedList,
    MenuButtonCode,
    MenuButtonCodeBlock,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonHorizontalRule,
    MenuButtonImageUpload,
    MenuButtonIndent,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectTextAlign,
    isTouchDevice,
} from "mui-tiptap";

const RichTextArea = ({ value, label, onChange }) => {
    const rteRef = useRef(null);
    const theme = useTheme();
    const handleChange = () => {
        onChange(rteRef.current?.editor?.getHTML());
    };
    return (
        <Box>
            <Typography sx={{ fontSize: "12px" }}>{label}</Typography>
            <RichTextEditor
                ref={rteRef}
                extensions={[StarterKit]}
                onBlur={handleChange}
                content={value}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuDivider />

                        <MenuSelectHeading />

                        <MenuDivider />

                        <MenuButtonBold />

                        <MenuButtonItalic />

                        <MenuDivider />

                        <MenuButtonEditLink />

                        <MenuDivider />

                        <MenuButtonBulletedList />

                        <MenuDivider />

                        <MenuButtonBlockquote />

                        <MenuDivider />

                        <MenuButtonCode />

                        <MenuButtonCodeBlock />

                        <MenuDivider />

                        <MenuButtonHorizontalRule />

                        <MenuDivider />

                        <MenuButtonRemoveFormatting />

                        <MenuDivider />

                        <MenuButtonUndo />
                        <MenuButtonRedo />
                    </MenuControlsContainer>
                )}
            />
        </Box>
    );
};

export { RichTextArea };
