import Skeleton from '@mui/material/Skeleton';

import {
    Avatar,
    Box,
    Card,
    Button,
    TextField,
    Typography,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
  } from '@mui/material';

  import PortraitIcon from '@mui/icons-material/Portrait';
  import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
  import PersonAddIcon from '@mui/icons-material/PersonAdd';
  import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
  import BookmarkIcon from '@mui/icons-material/Bookmark';
  import BookmarksIcon from '@mui/icons-material/Bookmarks';
  import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
  import GroupAddIcon from '@mui/icons-material/GroupAdd';
  import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
  import HighlightOffIcon from '@mui/icons-material/HighlightOff';
  import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
  import ShareIcon from '@mui/icons-material/Share';
  import AddIcon from '@mui/icons-material/Add';

import {config, styles} from '../../style';

import Grid from '@mui/material/Unstable_Grid2';

export const skeleton = (
    <div>
        <Card sx={styles.card}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                    <Grid item>
                        <Skeleton variant="rounded" width={400} height={30} />
                    </Grid>
                </Grid>
                <Divider sx={{mx: '-15px', mt: '15px'}}/>  

                <Grid container wrap="nowrap" spacing={3} sx={{ flexGrow: 1, my: '3px', pb:0 }}>
                    <Grid xs={6} xsOffset={3} md={3} mdOffset={0} sx={{pb: 0}}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                    <Grid xs={4} md={2} mdOffset="auto" sx={{pb: 0}}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>


        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid container wrap="nowrap" sx={{p:0}} spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" sx={{width: {lg: '400px', xs: "100%"}}} height={20} />
                            </Grid>
                        </Grid>
                        <Grid sx={{pl:0}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={100} height={10} />
                            </Grid>
                            <Grid item>
                                <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Skeleton variant="rounded" width="100%" height={90} />
                        </Box>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='60%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='100%' height={90} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid container wrap="nowrap" sx={{p:0}} spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" sx={{width: {lg: '400px', xs: "100%"}}} height={20} />
                            </Grid>
                        </Grid>
                        <Grid sx={{pl:0}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={100} height={10} />
                            </Grid>
                            <Grid item>
                                <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Skeleton variant="rounded" width="100%" height={90} />
                        </Box>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='60%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='100%' height={90} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid container wrap="nowrap" sx={{p:0}} spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" sx={{width: {lg: '400px', xs: "100%"}}} height={20} />
                            </Grid>
                        </Grid>
                        <Grid sx={{pl:0}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={100} height={10} />
                            </Grid>
                            <Grid item>
                                <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Skeleton variant="rounded" width="100%" height={90} />
                        </Box>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='60%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='100%' height={90} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid container wrap="nowrap" sx={{p:0}} spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" sx={{width: {lg: '400px', xs: "100%"}}} height={20} />
                            </Grid>
                        </Grid>
                        <Grid sx={{pl:0}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={100} height={10} />
                            </Grid>
                            <Grid item>
                                <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Skeleton variant="rounded" width="100%" height={90} />
                        </Box>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='60%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='100%' height={90} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{...styles.card, mt: '15px'}}>
            <CardContent sx={{paddingTop: '15px'}}>
                <Grid container spacing={3} sx={{ flexGrow: 1}}>
                    <Grid xs={12} md={6} mdOffset={0} sx={{borderRight: config.border}}>
                        <Grid container wrap="nowrap" sx={{p:0}} spacing={0} justifyContent="space-between">
                            <Grid item>
                                <Skeleton variant="rounded" sx={{width: {lg: '400px', xs: "100%"}}} height={20} />
                            </Grid>
                        </Grid>
                        <Grid sx={{pl:0}} container wrap="nowrap" spacing={0} justifyContent="start">
                            <Grid item>
                                <Skeleton variant="rounded" width={100} height={10} />
                            </Grid>
                            <Grid item>
                                <Skeleton sx={{ml: '10px'}} variant="rounded" width={100} height={10} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Skeleton variant="rounded" width="100%" height={90} />
                        </Box>
                    </Grid>
                    
                    <Grid xs={12} md={3} sx={{borderRight: config.border}}>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='60%' height={15} />
                            </Grid>
                        </Grid>
                        <Grid sx={{m:'-10px'}} container wrap="nowrap" spacing={0} justifyContent="space-between">
                            <Grid xs={12} item>
                                <Skeleton variant="rounded" width='100%' height={90} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={3} mdOffset={0}>
                        <Skeleton variant="rounded" width='100%' height={30} />
                        <Skeleton sx={{mt: '15px'}} variant="rounded" width='100%' height={30} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
    </div>
)

