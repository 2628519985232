import { useEffect, useState, useRef, createRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import refUseState from 'react-usestateref'

import CloseIcon from '@mui/icons-material/Close';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { User as UserIcon } from '../../icons/user';
import { format } from 'date-fns';
import {
    Box,
    Button,
    Typography,
    Divider,
    IconButton,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tab
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import { Badge } from '../../components/Badge';
import Skeleton from '@mui/material/Skeleton';
import { config, styles } from '../../style';
import {get, post} from '../../api/';
import { CustomInput } from '../CustomInput';
import { useAuth } from '../../hooks/useAuth';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minHeight: '46px',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.85)',
        '&:hover': {
            color: config.primaryColor,
            opacity: 1,
        },
        '&.Mui-selected': {
            color: config.primaryColor,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: config.primaryColor,
        },
    }),
);

export const Form = (props) => {
    let { title, formId, recordId } = props;

    const params = new URLSearchParams(window.location.search);
    if (params.get('formId')) {
        formId = params.get('formId');
    }

    if (params.get('recordId')) {
        recordId = params.get('recordId');
    }

    const [initialLoading, setInitialLoading] = useState(false);
    const [form, setForm] = useState(undefined);
    const [record, setRecord] = useState(undefined);
    
    const inputRefs = useRef([]);

    const { getUserDetailsNotAsync } = useAuth();
    //const [userDetails, setUserDetails] = useState({})
    const [existingFormData, setExistingFormData] = useState({})
    const [displayThankyouMessage, setDisplayThankyouMessage] = useState(false)
    const [elementValues, setElementValues, elementValuesRef] = refUseState([])
    const [selectedTab, setSelectedTab] = useState(undefined)
    const [elementCount, setElementCount] = useState(0)
    const userDetails = getUserDetailsNotAsync();

    useEffect(() => {
        setInitialLoading(true)
        getFormDetails();
    }, []);

    const getFormDetails = async () => {
        if (formId) {
            const result = await get('form/formData', { formId: formId, recordId: recordId, whoId: userDetails?.contact?.id, whoEmail: userDetails.username });
            setInitialLoading(false)
            if (result.isSuccess && result.data) {
                setForm(result.data?.form)
                setRecord(result.data?.record)
                let elements = []
                let count = 0
                for (let section of result.data?.form?.sections) {
                    for (let element of section.elements) {
                        elements.push(element);
                        count++;
                    }
                }
                setElementCount(count);
                if (result.data?.form?.sections?.length > 0) {
                    setSelectedTab(result.data?.form?.sections[0].name);
                    inputRefs.current = result.data?.form?.sections[0].elements?.map((element, i) => inputRefs.current[i] ?? {sectionId: element.formSectionId, refdata: createRef(), element: element });
                }
                
                setExistingFormData(result.data?.existingRecord)
            }
            else {
                const detailEvent = new CustomEvent('openToster', { detail: { variant: 'error', message: result.errorMessage } })
                document.dispatchEvent(detailEvent)
            }
        }
        else {
            setInitialLoading(false)
        }
    }

    const handleSubmit = async (index) => {
        let sectionId = form.sections[index].id
        let fields = inputRefs.current.filter(item => item.sectionId === sectionId)
        let valideted = true;
        fields.forEach(item => {
            if (!item.current.validate()) { 
                valideted = false
            } else {
                setElementValue(item.element, item.current.getInputValue())
            }
        })
        if (valideted) {
            if (elementValuesRef.current.length === elementCount) {
                
                let formSubmission = {
                    id: existingFormData ? existingFormData.id : '',
                    formId: form.id,
                    formType: form.type,
                    formSalesforceObjectName : form.salesforceObjectName,
                    whatId: recordId,
                    whoId: userDetails?.contact?.id,
                    whoName: userDetails?.contact?.name,
                    whoEmail: userDetails.username,
                    elements: elementValuesRef.current
                }
                const result = await post('form/saveFormData', formSubmission);
                if (result.isSuccess) {
                    if (form.type !== 'Survey') {
                        const detailEvent = new CustomEvent('openToster', {detail: {variant: 'success', message: 'Successfully saved'}})
                        document.dispatchEvent(detailEvent)
                        if (form.type === 'Simple Form') {
                            if (!recordId && result.data.whatId) {
                                window.location.href = window.location.href + '&recordId=' + result.data.whatId;
                            }
                        }
                    } else {
                        setDisplayThankyouMessage(true);
                    }
                } else {
                    const detailEvent = new CustomEvent('openToster', {detail: {variant: 'error', message: result.errorMessage}})
                    document.dispatchEvent(detailEvent)
                }
            }
            
        }
        
    }

    const handleCancel = (index) => {
        let sectionId = form.sections[index].id
        let fields = inputRefs.current.filter(item => item.sectionId === sectionId)
        fields.forEach(item => {
            item.current.cancel()
        })
    }

    const getValue = (item) => {
        let find = elementValuesRef.current.find(innerItem => innerItem.id === item.id)
        if (find && find.value) {
            return find.value
        }
        let retValue = '';
        if (existingFormData?.elements?.length > 0) {
            const value = existingFormData.elements.find(record => record.id === item.id)
            if (value) {
                retValue = value.value
            }
        }
        if (!retValue && record) {
            if (item.type === 'Contact' && item.whatIdSalesforceField) {
                let field = item.whatIdSalesforceField.replace('__c', '__r')
                retValue = record[field] ? JSON.stringify(record[field]) : ''
            } else {
                if (item.whatIdSalesforceField && record[item.whatIdSalesforceField]) {
                    retValue = record[item.whatIdSalesforceField]
                }
            }
            
        }
        
        return retValue ? retValue : '' 
    }
    const handleSaveAndNext = (index) => {
        let sectionId = form.sections[index].id
        let fields = inputRefs.current.filter(item => item.sectionId === sectionId)
        let valideted = true;
        fields.forEach(item => {
            if (!item.current.validate()) { 
                valideted = false
            } else {
                setElementValue(item.element, item.current.getInputValue())
            }
        })
        if (valideted) {
            
            inputRefs.current = form.sections[index + 1].elements?.map((element, i) => {
                return { sectionId: element.formSectionId, refdata: createRef(), element: element }
            });
            setSelectedTab(form.sections[index + 1].name);
        }
        
    }

    const setElementValue = (element, value) => {
        let find = elementValuesRef.current.find(item => item.id === element.id)
        if (find) {
            setElementValues([...elementValuesRef.current.map(item => {
                if (item.id === element.id) {
                    find = true;
                    return { ...item, value: value };
                }
                return item;
            })])
        } else {
            setElementValues([...elementValuesRef.current, {...element, value: value}])
        }
    }

    const handlePrevious = (index) => {
        let sectionId = form.sections[index].id
        let fields = inputRefs.current.filter(item => item.sectionId === sectionId)
        fields.forEach(item => {
            setElementValue(item.element, item.current.getInputValue())
        })
        
        inputRefs.current = form.sections[index - 1].elements?.map((element, i) => {
            return { sectionId: element.formSectionId, refdata: createRef(), element: element }
        });
        setSelectedTab(form.sections[index - 1].name);
    }


    return (
        <>
            {!initialLoading &&
                <>
                {form?.name && < Box >
                    <Paper sx={{ mb: '1rem', p: '20px', display: 'flex', flexDirection: 'column' }} elevation={3}>
                        <Box style={{ fontSize: '16px', color: 'gray' }}>
                           FORM
                        </Box>
                        <Box style={{ textTransform: 'uppercase', fontWeight: '800', fontSize: '21px' }}>
                            {form?.name}
                        </Box>
                    </Paper>
                </Box>
                }
    
                <Paper sx={{ display: 'flex', flexDirection: 'column' }} elevation={3}>
                    {!displayThankyouMessage ?
                        <Box style={{ fontSize: '12px', color: 'gray' }}>
                            <TabContext value={selectedTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList variant="scrollable" scrollButtons="auto" sx={{ paddingLeft: '1rem' }} aria-label="lab API tabs example">
                                        {!form?.sections ? '' :
                                            form?.sections.map(item => (
                                                <AntTab iconPosition="start" key={item.id} label={item.name} value={item.name} />
                                            ))
                                        }
                                    </TabList>
                                </Box>
                                {!form?.sections ? '' :
                                    form?.sections.map((section, sectionIndex) => (
                                        <TabPanel key={section.id} value={section.name}>
                                            <Box >
                                                {section?.elements?.length > 0 && <>
                                                    {section?.elements?.map((item, i) => (
                                                        <CustomInput ref={inputRefs.current[i]} key={item.id} value={getValue(item)}  {...item}></CustomInput>
                                                    ))}
                                                    <Box style={{ fontSize: '12px', color: 'gray' }}>
                                                        <Grid container sx={{ p: 0 }} justifyContent="space-between">
                                                            <Grid item sx={{ p: 0 }}>
                                                                {sectionIndex !== 0 && <Button onClick={() => handlePrevious(sectionIndex)} variant="outlined">Previous</Button>}
                                                            </Grid>
                                                            <Grid item sx={{ p: 0 }}>
                                                                <Button onClick={() => handleCancel(sectionIndex)} variant="outlined">Cancel</Button>
                                                                {(sectionIndex + 1) === form.sections.length ?
                                                                    <Button onClick={() => handleSubmit(sectionIndex)} sx={{ ml: '1rem' }} variant="contained" >Submit</Button>
                                                                    :
                                                                    <Button onClick={() => handleSaveAndNext(sectionIndex)} sx={{ ml: '1rem' }} variant="contained" >Save & Next</Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Box>
                                                </>
                                                    
                                                }
                                            </Box>
                                        </TabPanel>
                                    ))
                                }
                            </TabContext>
                            


                            {/* {form?.sections[0]?.elements?.length > 0 &&
                                form?.sections[0]?.elements?.map((item, i) => (
                                    <CustomInput ref={inputRefs.current[i]} key={item.id} value={() => getValue(item)}  {...item}></CustomInput>
                                ))
                            }
                            <Box style={{ fontSize: '12px', color: 'gray' }}>
                                <Button onClick={handleCancel} variant="outlined">Cancel</Button>
                                <Button onClick={handleSubmit} sx={{ ml: '1rem' }} variant="contained">Save</Button>
                            </Box> */}
                        </Box>
                        :
                        <Box style={{ textAlign: 'center' }}>
                            <ThumbUpIcon sx={{fontSize: '4rem', color: '#3700ff'}}></ThumbUpIcon>
                            <Typography variant="h4" component="h4">
                                Thank you for your time and your answers.
                            </Typography>
                            <Typography variant="h6" component="h6">
                                We hope you like the questions.
                            </Typography>
                        </Box>
                    }
                </Paper>
                </>
                
            }
        </>

    );



}