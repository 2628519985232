import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';
import {
    selectDocumentData,
    selectDocumentStatus,
    selectDocumentError,
    initializeAsync
} from './documentSlice';

// Add custom imports here

import {
    Box,
    Card,
    CardContent,
    Divider,
    Typography,
    Button
  } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import { DocumentUploadContainer } from '../../components/DocumentUploadContainer';
import {config, styles} from '../../style';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import Grid from '@mui/material/Unstable_Grid2';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const Document = () => {
    const data = useSelector(selectDocumentData);
    const status = useSelector(selectDocumentStatus);
    const error = useSelector(selectDocumentError);
    const dispatch = useDispatch();
    const { getUserDetailsNotAsync } = useAuth();
    

    // Add Variables

    const [userDetails, setUserDetails] = useState(null)
    const documentUploadRef = useRef(null);

    

    useEffect(() => {
        if(status === 'idle'){
            dispatch(initializeAsync());
        }

        const setUserData = () => {
            const userData = getUserDetailsNotAsync()
            setUserDetails(userData)
        } 
        setUserData();
        
    }, [status, dispatch, getUserDetailsNotAsync]);

    

    const handleUploadClick = () => {
        documentUploadRef.current.uploadFile()
    }
    const handleCreateFolderClick = () => {
        documentUploadRef.current.createFolder()
    }

    let body = (
        <>
            <Box>
                <Card sx={styles.card}>
                    <CardContent sx={{paddingTop: '15px'}}>
                        <Grid container  spacing={0} justifyContent="space-between">
                            <Grid xs={12} md={6} item>
                                <Typography sx={styles.pageHeading}>
                                    Documents
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleCreateFolderClick} startIcon={<CreateNewFolderIcon sx={{ color: config.primaryColor }} fontSize='small' />} variant="text" sx={styles.textButton}>Create Folder</Button>
                                <Button onClick={handleUploadClick} startIcon={<FileUploadIcon sx={{ color: config.primaryColor }} fontSize='small' />} variant="text" sx={{...styles.textButton, ml: '1.5rem'}}>Upload Document</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{mt: '15px'}}>
                {userDetails && <Card sx={{...styles.card, p:0, background: '#f8f8f8'}}>
                    <CardContent  sx={{paddingTop: '15px', minHeight: '26rem', position: 'relative'}}>
                        <DocumentUploadContainer ref={documentUploadRef} parentId={userDetails?.contact?.accountId} userDetails={userDetails}></DocumentUploadContainer>
                    </CardContent>
                </Card>
                }
            </Box>
            
        </>
    )

    return getPageContent(body, status, error);
}

export { Document }