import { createSlice } from '@reduxjs/toolkit';
import {query, describe} from '../../services/salesforce';

export const listPageSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'listPage',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
    },
})

export default listPageSlice.reducer

// Export all the reducers actions here
export const { initialize, error, loading } = listPageSlice.actions;


// Public Selectors
export const selectListPageData = (state) => state.listPage.data;
export const selectListPageStatus = (state) => state.listPage.status;
export const selectListPageError = (state) => state.listPage.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(loading(true))
    const describeResult = await describe(props.objectName, {cacheable: true})
    const dataResult = await query(props.objectName, 'getRecords', {limit: 10, startsWith: 'test'});
    if(describeResult.isSuccess && dataResult.isSuccess){
        dispatch(initialize({columns: describeResult.data, records: dataResult.data}))
    }
    else{
        dispatch(error(describeResult.errorMessage + dataResult.errorMessage))
    }
}

