import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { config, styles } from '../../style';
import Grid from '@mui/material/Unstable_Grid2';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: config.border,
  marginBottom: '10px',
  borderRadius: '10px',
  color: config.primaryColor,
  background: 'white',
  '&:not(:last-child)': {
    borderBottom: config.border,

  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: config.primaryColor }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '10px',
  fontWeight: '600',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  color: config.grayColor
}));

export const Section = (props) => {
  const { section, recordId, hideSectionHeader } = props

  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    if(section?.subsections?.length > 0) {
      setExpanded(section?.subsections[0].id)
    }
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {!hideSectionHeader && section.description !== null && <Box sx={{ border: config.border, borderRadius: '10px', p: '1rem', mb: '10px' }}><div dangerouslySetInnerHTML={{ __html: section.description }} /></Box>}
      <div>
        {section?.subsections && section?.subsections.length > 0 && (
          <>
            {section?.subsections.map(item => {

              return (

                item.description !== '<p></p>' &&
                <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>{item.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    {item?.assets && <ImageList sx={{ my: '20px', mx: 0 }} cols={3}>
                      {
                          item?.assets.map((media) => (
                            <div>
                              {media.asset.type.startsWith('video') &&
                                <ImageListItem key={media.assetId}>

                                  <video width="335" height="223" controls>
                                    <source src={media.asset.fileURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                <ImageListItemBar
                                  position="top"
                                  sx={{
                                    background:
                                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                  }}
                                  actionIcon={
                                    <IconButton
                                      sx={{ color: 'white' }}
                                      aria-label={`open`}
                                      href={media.asset.fileURL}
                                      target="_blank"
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  }
                                  actionPosition="left"
                                />
                              </ImageListItem>
                              }
                              {media.asset.type.startsWith('image') &&
                                <ImageListItem key={media.assetId}>

                                  <img src={media.asset.fileURL}></img>
                                <ImageListItemBar
                                  position="top"
                                  sx={{
                                    background:
                                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                  }}
                                  actionIcon={
                                    <IconButton
                                      sx={{ color: 'white' }}
                                      aria-label={`open`}
                                      href={media.asset.fileURL}
                                      target="_blank"
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  }
                                  actionPosition="left"
                                />
                              </ImageListItem>
                              }
                              
                            </div>
                          
                        ))}
                    </ImageList>



                    }
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Typography>


                  </AccordionDetails>
                </Accordion>

              )
            }
            )}
          </>

        )}
      </div>
    </>
  )
}