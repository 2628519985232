import { createSlice } from "@reduxjs/toolkit";
import { query, describe } from "../../services/salesforce";

export const eventsSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "events",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = "succeeded";
            state.error = null;
        },
        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        loading: (state, action) => {
            state.status = action.payload ? "loading" : "succeeded";
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? "initalLoading" : "succeeded";
        },
    },
});

export default eventsSlice.reducer;

// Export all the reducers actions here
export const { initialize, error, loading, initialLoading } = eventsSlice.actions;

// Public Selectors
export const selectEventsData = (state) => state.events.data;
export const selectEventsStatus = (state) => state.events.status;
export const selectEventsError = (state) => state.events.error;

export const initializeAsync = (props) => async (dispatch) => {
    dispatch(initialLoading(true));
    const userDetails = await props.getUserDetails();
    const result = await query(
        "opportunity",
        userDetails.application === "speaker" ? "getClosedChildSpeakerOpportunity" : "getClosedChildOpportunity",
        { accountId: userDetails?.contact?.accountId }
    );
    if (result.isSuccess) {
        dispatch(initialize({ records: result.data, userDetails: userDetails }));
    } else {
        dispatch(error(result.errorMessage));
    }
};
