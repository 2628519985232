import {API} from 'aws-amplify';
import {setParams, getHeader, getSubPath, getApiName} from './utils'

const get = (path, params) => {
    return new Promise(async(resolve, reject) => {
        let header = await getHeader();
        let url = setParams(getSubPath(header) + path, params);
        const apiName = getApiName()
        await API.get(apiName, url, header)
        .then(result => {
            if(!result.isSuccess){
                console.log(result)
            }
            resolve(result)
        })
        .catch(error => {
            console.log(error)
            //window.location.href = '/signout' 
            reject(error)
        })
    })
}

const post = (path, data) => {
    return new Promise(async(resolve, reject) => {
        let header = await getHeader();
        let url = getSubPath(header) + path;
        header['body'] = data
        await API.post(getApiName(), url, header)
        .then(result => {
            resolve(result)
        })
        .catch(error => reject(error))
    })
    
}


export {get, post}