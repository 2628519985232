import { createSlice } from '@reduxjs/toolkit';
import { query, publicQuery, apex } from '../../services/salesforce'
import moment from 'moment';
import { format } from 'date-fns';

export const eventDetailSlice = createSlice({

    // Slice name, this should be always as page name.
    name: 'eventDetail',

    // Initial state for the slice
    initialState: {
        data: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },

    // Reducers will mutate the state, put all the state mutation method over here 
    reducers: {
        initialize: (state, action) => {
            state.data = action.payload;
            state.status = 'succeeded';
            state.error = null;
        },
        error: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        initialLoading: (state, action) => {
            state.status = action.payload ? 'initalLoading' : 'succeeded';
        },
        loading: (state, action) => {
            state.status = action.payload ? 'loading' : 'succeeded';
        },
    },
})

export default eventDetailSlice.reducer

// Export all the reducers actions here
export const { initialize, error, initialLoading, loading } = eventDetailSlice.actions;


// Public Selectors
export const selectEventDetailData = (state) => state.eventDetail.data;
export const selectEventDetailStatus = (state) => state.eventDetail.status;
export const selectEventDetailError = (state) => state.eventDetail.error;

export const initializeAsync = (props) => async (dispatch) => {
    try {
        dispatch(initialLoading(true))
        let resultOpportunity = {}
        let sectionResult = {}
        let stepResult = {}
        if(props.shared) {
            resultOpportunity = await publicQuery('opportunity', 'getRecordById', { recordId: props.recordId, queryFields: 'getDetailQueryFields' });
            sectionResult = await publicQuery('opportunity', 'getEventDetail', { recordId: props.recordId });
        } else {
            resultOpportunity = await query('opportunity', 'recordById', { recordId: props.recordId, queryFields: 'getDetailQueryFields' });
            sectionResult = await apex('GET', '/WSBEventApi/getByOpportunityId?id=' + props.recordId);
            stepResult = await apex('GET', '/WSBEventApi/getRecordSteps?id=' + props.recordId);
            // console.log(stepResult);


            let playDate = parseDateISOString(resultOpportunity.data[0]?.startPlayDate);
            let endDate = parseDateISOString(resultOpportunity.data[0]?.endPlayDate);

            let formattedDate = '';
            console.log('Play Date:', playDate, 'End Date', endDate);
            if(playDate !== endDate) {
                formattedDate = `${format(playDate, 'EEE, MMMM d, yyyy')} to ${format(endDate, 'EEE, MMMM d, yyyy')}`
            } else {
                formattedDate = `${format(playDate, 'EEE, MMMM d, yyyy')}`;
            }
            console.log(formattedDate);
            resultOpportunity = await query('opportunity', 'recordById', { recordId: props.recordId, queryFields: 'getDetailQueryFields' });
            sectionResult = await apex('GET', '/WSBEventApi/getByOpportunityId?id=' + props.recordId);
            stepResult = await apex('GET', '/WSBEventApi/getRecordSteps?id=' + props.recordId);
            // console.log(stepResult);
            resultOpportunity.data[0].formattedDate = formattedDate;
        }
        let weatherForecast = {};
        if(resultOpportunity.data[0]?.venueZip && resultOpportunity.data[0]?.startPlayDate) {

            let playDate = new Date(resultOpportunity.data[0]?.startPlayDate);
            let playDateString = resultOpportunity.data[0]?.startPlayDate;
            let today = new Date();
            let todayDateString = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${today.getDate()}`;

            console.log('Parsing dates', todayDateString, playDateString, (playDate.getTime() - Date.now()) / (1000 * 3600 * 24))
            if((playDate.getTime() - Date.now()) / (1000 * 3600 * 24) >= -1) {
                let zipCode = resultOpportunity.data[0]?.venueZip;
                let endpoint = '';
                if(todayDateString == playDateString) {
                    console.log('Same Day')
                    endpoint = `https://api.weatherapi.com/v1/current.json?key=6885b49bdac2446bafa141016231503&q=${zipCode}&aqi=no`;
                } else if((playDate.getTime() - Date.now()) / (1000 * 3600 * 24) < 9) {
                    console.log('Under 10 days');
                    endpoint = `https://api.weatherapi.com/v1/forecast.json?key=6885b49bdac2446bafa141016231503&q=${zipCode}&days=10&aqi=no&alerts=no`;
                }

                await fetch(endpoint)
                    .then((response) =>
                        response.json()
                    )
                    .then((data) => {
                        console.log(data);
                        if(data?.current) {
                            weatherForecast.current = {};
                            weatherForecast.current.feelsLikeF = data.current.feelslike_f;
                            weatherForecast.current.feelsLikeC = data.current.feelslike_f;
                            weatherForecast.current.condition = data.current.condition.text;
                            weatherForecast.current.conditionIcon = data.current.condition.icon;
                        }
                        if(data?.forecast?.forecastday) {
                            weatherForecast.playDateForecast = {};
                            let playDateForecast = data?.forecast?.forecastday.filter((forecast) => forecast.date === resultOpportunity.data[0]?.startPlayDate)
                            console.log(playDateForecast);
                            if(playDateForecast.length > 0) {
                                weatherForecast.playDateForecast.highC = playDateForecast[0].day.maxtemp_c;
                                weatherForecast.playDateForecast.highF = Math.round(playDateForecast[0].day.maxtemp_f);
                                weatherForecast.playDateForecast.lowC = playDateForecast[0].day.mintemp_c;
                                weatherForecast.playDateForecast.lowF = Math.round(playDateForecast[0].day.mintemp_f);
                                weatherForecast.playDateForecast.condition = playDateForecast[0].day.condition.text;
                                weatherForecast.playDateForecast.conditionIcon = playDateForecast[0].day.condition.icon;
                            }
                            console.log('The forecast for the playDate', weatherForecast)
                        }
                        console.log(JSON.stringify(weatherForecast));
                    }).catch((error) => {

                        console.log(error);
                    });
            }


        }


        if(sectionResult.isSuccess && resultOpportunity.isSuccess && resultOpportunity.data?.length > 0) {

            let currentStep;
            if(stepResult?.data && stepResult.data.length > 0) {
                let currentSteps = stepResult.data.filter((step) => {
                    if(step.isCurrent === true) return step;
                })
                if(currentSteps.length > 0) currentStep = currentSteps[0];
            }


            let payload = {}
            payload[props.recordId] = { opportunity: resultOpportunity.data[0], sections: sectionResult.data, steps: stepResult.data, currentStep: currentStep, weatherForecast: weatherForecast }
            dispatch(initialize(payload));
        }
        else {
            dispatch(error(resultOpportunity.errorMessage))
        }
    }
    catch(error) {
        console.log(error)
        dispatch(error(error))
    }

}

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

function parseDateISOString(s) {
    let ds = s.split(/\D/).map(s => parseInt(s));
    ds[1] = ds[1] - 1; // adjust month
    return new Date(...ds);
}