import React, { useEffect, useState, useRef } from "react";
import { Navigate, useOutlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const RequiredLoginLayout = () => {
    const { user, isAuthenticated, getUserDetailsNotAsync } = useAuth();
    const outlet = useOutlet();
    const userData = getUserDetailsNotAsync();
    const location = useLocation();

    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            setLoaded(false);
            try {
                let value = await isAuthenticated();
                setAuthenticated(value);
            } catch {
                setAuthenticated(false);
            } finally {
                setLoaded(true);
            }
        };
        checkAuth();
    }, [location.pathname]);

    if (!loaded) {
        return null; // You can render a loading spinner or message here
    }

    if (user && authenticated) {
        return <div>{outlet}</div>;
    }

    return <Navigate to="/signout" state={{ path: location.pathname + location.search + location.hash }} />;
};
